import React, { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

const InputForm = ({
	formData,
	setFormData,
	setShowPreview,
	setIsInvalid,
	qrType,
}) => {
	useEffect(() => {
		if (!formData?.title) setIsInvalid(true)
		else setIsInvalid(false)
	}, [formData])
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)
	// State to handle form inputs, initialized with formData if provided
	const [globalTradeItemNumber, setGlobalTradeItemNumber] = useState(
		formData?.globalTradeItemNumber || ''
	)
	const [destinationUrl, setDestinationUrl] = useState(
		formData?.destinationUrl || ''
	)

	// Handler to capture form submission
	const handleSubmit = (e) => {
		e.preventDefault()
		// Handle form submission logic here
		console.log('Global Trade Item Number (01):', globalTradeItemNumber)
		console.log('Destination URL:', destinationUrl)
	}

	// Handler to update formData and local state
	const handleInputChange = (e) => {
		const { id, value } = e.target
		if (id === 'gtin') {
			setGlobalTradeItemNumber(value)
		} else if (id === 'destinationUrl') {
			setDestinationUrl(value)
		}
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}))
	}

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	return (
		<Form onSubmit={handleSubmit}>
			{/* Global Trade Item Number Field */}
			<FormGroup>
				<Label className="fs-5 mb-6 fw-bold" for="gtin">
					Global Trade Item Number (01):
				</Label>
				<Input
					type="text"
					id="gtin"
					value={globalTradeItemNumber}
					onChange={handleInputChange}
					placeholder="Enter GTIN"
				/>
			</FormGroup>

			{/* Destination URL Field */}
			<FormGroup className="position-relative">
				<Label className="fs-5 mb-6 fw-bold" for="destinationUrl">
					Destination URL:
				</Label>
				<Input
					type="url"
					id="destinationUrl"
					value={destinationUrl}
					onChange={handleInputChange}
					placeholder="Enter Destination URL"
				/>
				{formData?.destinationUrl && (
					<i
						className={`fa ${!formData?.title ? 'fa-times' : 'fa-check'}`}
						style={{
							position: 'absolute',
							right: '10px',
							top: '50%',
							transform: 'translateY(-50%)',
							fontSize: '16px',
							color: !formData?.title ? 'red' : 'green',
						}}
					></i>
				)}
			</FormGroup>
			<div className="form-group">
				<h3 className="mb-4">Welcome Screen</h3>
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="form-group mb-3">
							<label className="form-label">Image</label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<input
									type="file"
									className="form-control"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>

						<AnimationSettings
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			</div>
			<FormGroup></FormGroup>

			{/* Password Section */}
			<FormGroup>
				<PasswordSection setFormData={setFormData} />
			</FormGroup>
		</Form>
	)
}

export default InputForm
