// listoflinks.js
import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const ListofLinksPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	// Helper function to obtain a URL from a file object or return the URL directly.
	const getImageURL = (fileOrUrl) => {
		if (!fileOrUrl) return null
		return typeof fileOrUrl === 'string'
			? fileOrUrl
			: URL.createObjectURL(fileOrUrl)
	}

	// Extract basic info
	const logoURL = getImageURL(formData.basicLogo)
	const navbarColor = formData.navbarColor || '#ffffff'
	const backgroundColor = formData.backgroundColor || '#f8f9fa'

	// Extract arrays from formData
	const links = formData.links || []
	const separators = formData.separators || []
	const contentImages = formData.contentImages || []

	// Welcome screen values
	const welcomeImageURL = getImageURL(formData.contentWelcomeImage)
	const welcomeTime = formData.contentWelcomeTime || 2.5

	// Social networks section
	const socialTitle = formData.contentSocialTitle
	const selectedSocialNetworks = formData.selectedSocialNetworks || []

	// Appearance settings
	const linkTextColor = formData.linkTextColor || '#000000'
	const backgroundLinkColor = formData.backgroundLinkColor || '#ffffff'

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div style={{ backgroundColor, minHeight: '100vh', padding: '20px' }}>
			{/* Navbar / Header */}
			<nav
				style={{
					backgroundColor: navbarColor,
					padding: '10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{logoURL && (
					<img
						src={logoURL}
						alt="Logo"
						style={{
							width: '50px',
							height: '50px',
							marginRight: '10px',
							objectFit: 'cover',
						}}
					/>
				)}
				<div>
					<h1 style={{ color: formData.titleColor || '#000', margin: 0 }}>
						{formData.basicTitle || 'Your Title Here'}
					</h1>
					{formData.basicDescription && (
						<p style={{ margin: 0, color: formData.textsColor || '#000' }}>
							{formData.basicDescription}
						</p>
					)}
				</div>
			</nav>

			{/* Links Section */}
			<section style={{ marginTop: '20px' }}>
				<h2 style={{ color: formData.titleColor || '#000' }}>Links</h2>
				<div>
					{links.map((link, index) => {
						const linkImageURL = getImageURL(link.linkImage)
						return (
							<div
								key={index}
								style={{
									marginBottom: '15px',
									padding: '10px',
									backgroundColor: backgroundLinkColor,
									borderRadius: '5px',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								{linkImageURL && (
									<img
										src={linkImageURL}
										alt={`Link ${index + 1}`}
										style={{
											width: '40px',
											height: '40px',
											marginRight: '10px',
											objectFit: 'cover',
											borderRadius: '4px',
										}}
									/>
								)}
								<a
									href={link.linkUrl}
									style={{
										color: linkTextColor,
										textDecoration: 'none',
										fontSize: formData.linksSize || '16px',
									}}
								>
									{link.linkText || 'Link Text'}
								</a>
							</div>
						)
					})}
				</div>
			</section>

			{/* Separators Section */}
			{separators.length > 0 && (
				<section style={{ marginTop: '20px' }}>
					<h2 style={{ color: formData.titleColor || '#000' }}>Separators</h2>
					{separators.map((sep, index) => (
						<div
							key={index}
							style={{
								marginBottom: '15px',
								paddingBottom: '10px',
								borderBottom: `2px solid ${formData.separatorColor || '#000'}`,
							}}
						>
							<h3
								style={{
									fontSize: formData.separatorSize || '18px',
									color: formData.titleColor || '#000',
								}}
							>
								{sep.separatorName || `Separator ${index + 1}`}
							</h3>
							<p
								style={{
									color: formData.textsColor || '#000',
									fontSize: formData.descriptionSize || '14px',
								}}
							>
								{sep.separatorText || 'Separator text'}
							</p>
						</div>
					))}
				</section>
			)}

			{/* Content Images Gallery (Updated Section) */}
			<section style={{ marginTop: '20px' }}>
				<h2 style={{ color: formData.titleColor || '#000' }}>Gallery</h2>
				<div
					style={{
						border: '2px dashed #ccc',
						borderRadius: '8px',
						padding: '20px',
						textAlign: 'center',
						position: 'relative',
						minHeight: '120px',
					}}
				>
					{contentImages.length > 0 ? (
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: '10px',
								justifyContent: 'center',
							}}
						>
							{contentImages.map((img, index) => {
								const imgURL = getImageURL(img)
								return (
									<img
										key={index}
										src={imgURL}
										alt={`Content ${index + 1}`}
										style={{
											width: '100px',
											height: '100px',
											objectFit: 'cover',
											borderRadius: '5px',
										}}
									/>
								)
							})}
						</div>
					) : (
						<p className="text-muted">No images uploaded</p>
					)}
				</div>
			</section>

			{/* Social Networks Section */}
			{selectedSocialNetworks.length > 0 && (
				<section style={{ marginTop: '20px' }}>
					{socialTitle && (
						<h2 style={{ color: formData.titleColor || '#000' }}>
							{socialTitle}
						</h2>
					)}
					<div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
						{selectedSocialNetworks.map((network, index) => (
							<div
								key={index}
								style={{
									width: '40px',
									height: '40px',
									borderRadius: '50%',
									backgroundColor: '#f2f2f2',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontWeight: 'bold',
									color: '#333',
								}}
							>
								{network.charAt(0).toUpperCase()}
							</div>
						))}
					</div>
				</section>
			)}

			{/* Welcome Screen Section */}
			{(welcomeImageURL || formData.contentWelcomeTime) && (
				<section style={{ marginTop: '20px' }}>
					<h2 style={{ color: formData.titleColor || '#000' }}>
						Welcome Screen
					</h2>
					{welcomeImageURL && (
						<img
							src={welcomeImageURL}
							alt="Welcome"
							style={{
								width: '100%',
								maxWidth: '300px',
								borderRadius: '5px',
								marginBottom: '10px',
							}}
						/>
					)}
					<p style={{ color: formData.textsColor || '#000' }}>
						Display time: {welcomeTime} sec
					</p>
				</section>
			)}

			{/* Password Protection Notice */}
			{formData.activatePassword && (
				<section style={{ marginTop: '20px' }}>
					<h2 style={{ color: formData.titleColor || '#000' }}>
						Password Protected
					</h2>
					<p style={{ color: formData.textsColor || '#000' }}>
						This QR code is protected by a password.
					</p>
				</section>
			)}
		</div>
	)
}

export default ListofLinksPreview
