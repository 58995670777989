import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Spinner,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap'
import { urlBuilder } from '../links/form'
import LinkInBioApi from '../../../services/http/linkInBio'
import useCustomLinks from '../../../hooks/useCustomLinks'
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import { AuthContxt } from '../../../store/authContxt'
import { PLAN, SLASH_TAG_REGEX } from '../../../utils/enum'

const BASE_URL_BIO = process.env.REACT_APP_BIO_BASE_URL
const BASE_URL = process.env.REACT_APP_QR_BASE_URL

const DEFAULT_VALUES = {
	pageLink: '',
	pageDomain: BASE_URL_BIO,
}

const CreatePageModal = ({
	toggleHandler,
	valuesHandler,
	edittedData = null,
}) => {
	const { quota } = useContext(AuthContxt)
	const isPlanStarter = quota?.plan === PLAN.STARTER
	const customLinks = useCustomLinks()
	const isEdit = edittedData ? true : false
	const [loading, setLoading] = useState(false)

	const [message, setMessage] = useState('')
	const messageHandler = (msg) => {
		setMessage(msg)
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const createLinkValidation = yup.object().shape({
		pageLink: yup
			.string()
			.matches(SLASH_TAG_REGEX, {
				message:
					'The characters `~,<>;:\'"/\\[\\]^{}()=+!*@&$?%#|` are not allowed',
				excludeEmptyString: true,
			})
			.matches(/^\S*$/, {
				message: 'Space is not allowed in slash tag',
				excludeEmptyString: true,
			})
			.required('Page link is required!')
			.max(50, "Page link can't be longer than 50 characters"),
	})

	const form = useForm({
		resolver: yupResolver(createLinkValidation),
		defaultValues: { ...DEFAULT_VALUES, ...edittedData },
	})

	const {
		control,
		handleSubmit,
		setError,
		formState: { errors },
		trigger,
	} = form

	const onSubmit = async (data) => {
		try {
			setLoading(true)
			const auth_token = localStorage.getItem('auth_token')
			const response = edittedData?.id
				? await LinkInBioApi.updateLinkInBioPageUrl(
						auth_token,
						edittedData?.id,
						{
							pageDomain: data?.pageDomain,
							pageLink: convertToURLFriendly(data?.pageLink),
						}
					)
				: await LinkInBioApi.validLinkInBio(auth_token, {
						pageDomain: data?.pageDomain,
						pageLink: convertToURLFriendly(data?.pageLink),
					})
			if (!response?.data?.success) {
				setError('pageLink', {
					type: 'custom',
					message: 'This link already exist.',
				})
				setLoading(false)
				return
			}
			setLoading(false)
			valuesHandler(data)
		} catch (e) {
			messageHandler(e?.response?.data?.data?.message || 'Something went wrong')
			console.log('Error while creating bio page: ', e)
			setLoading(false)
		}
	}

	return (
		<Modal
			isOpen={true}
			className="add_new_link_body mobile-modal-view modal-dialog-centered mw-1000px rounded-2"
			size="xl"
			toggle={toggleHandler}
		>
			<ModalHeader className="justify-content-end" closeButton>
				<i
					onClick={toggleHandler}
					className="fa-light fa-times fs-4 cursor-pointer"
				></i>
			</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="modal-body pt-0">
						<div className="mb-lg-10 mb-0 mt-lg-5 mt-3">
							<h3 className="pb-lg-4 text-dark tp-section-title fs-2x text-lg-center">
								Name your Link-in-bio page
							</h3>
							<p className="text-gray-600 fw-semibold fs-5 w-lg-50 text-lg-center w-100 mx-auto">
								Choose a unique and memorable name for your Link-in-bio page to
								make it easy for others to recognize and share.
							</p>
						</div>
						<div className="mx-auto px-lg-20 py-8 py-lg-10 mb-10">
							<div className="d-flex mb-2 fv-row">
								<div className="w-200px me-4">
									<label className="fs-6 fw-semibold">
										<span className="fs-5 fw-bold">Branded Domain</span>
										<span
											className="ms-1"
											data-bs-toggle="tooltip"
											data-bs-original-title="Select domain for your link"
										>
											<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
												<span className="path1"></span>
												<span className="path2"></span>
												<span className="path3"></span>
											</i>
										</span>
									</label>
								</div>
								<div className="ms--2">
									<label className="fs-6 fw-semibold">
										<span className="fs-5 fw-bold">Text</span>
									</label>
								</div>
							</div>
							<div className="input-group justify-content-between mb-3 h-50px">
								<Controller
									name="pageDomain"
									control={control}
									render={({ field, fieldState }) => {
										return (
											<div>
												<UncontrolledDropdown
													className="custom-dropdown form-control p-0 mw-200px"
													style={{ borderRadius: '5px 0px 0px 5px' }}
													direction="down"
												>
													<DropdownToggle
														disabled={true}
														caret
														className="dropdown-header fs-5 my-auto bg-none brandeddomainforlinkinbio"
													>
														{field.value || BASE_URL_BIO}
													</DropdownToggle>
												</UncontrolledDropdown>
											</div>
										)
									}}
								/>
								<span className="input-group-text">/</span>
								<Controller
									name="pageLink"
									control={control}
									render={({ field }) => (
										<>
											<Input
												{...field}
												onChange={(e) => {
													field.onChange(e)
													trigger('pageLink')
												}}
												className="form-control"
												style={{ borderRadius: '0px 5px 5px 0px' }}
												placeholder="Example: project-link"
											/>
										</>
									)}
								/>
							</div>
							<span className="mb-10 text-gray-400">
								Example (`https://{BASE_URL_BIO}/social-links`)
							</span>
							{errors?.pageLink ? (
								<div
									className="error-text text-danger"
									style={{
										textAlign: 'right',
									}}
								>
									{errors?.pageLink?.message}
								</div>
							) : null}
							<div className="d-flex justify-content-end mb-3">
								{message ? (
									<span className="error-text text-danger">{message}</span>
								) : null}
							</div>
							<button
								type="submit"
								className="btn btn-info h-50px mt-10 w-100 d-block rounded-1"
							>
								{loading ? (
									<Spinner />
								) : isEdit ? (
									'Update Your Page'
								) : (
									'Create Your Page'
								)}
							</button>
						</div>
					</div>
				</form>
			</ModalBody>
		</Modal>
	)
}

export default CreatePageModal
