import Offcanvas from 'react-bootstrap/Offcanvas'
import { FormGroup, Label, Input, Col, Row } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form'
import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DrawerWrapper from '../../wrapper/drawerWrapper'
import formatAndValidateUrl from '../../../utils/formatAndValidateUrl'

const AddNewUTM = ({
	isURLParamBuilderOpen,
	setIsURLParamBuilderOpen,
	form,
}) => {
	const { getValues, setValue: setFormValue } = form
	const [previewUrl, setPreviewUrl] = useState('') // State for the badge URL preview

	const { url } = getValues()

	const createUtmValidation = yup.object().shape({
		utm_source: yup.string().required('Source is required'),
		utm_medium: yup.string().required('Medium is required'),
		utm_campaign: yup.string().required('Name is required'),
	})

	const utmForm = useForm({
		resolver: yupResolver(createUtmValidation),
		defaultValues: {
			utm_source: '',
			utm_medium: '',
			utm_campaign: '',
			utm_id: '',
			utm_term: '',
			utm_content: '',
		},
	})

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		watch,
	} = utmForm
	// Watch specific UTM fields for dynamic URL updates
	const utmFields = watch([
		'utm_source',
		'utm_medium',
		'utm_campaign',
		'utm_id',
		'utm_term',
		'utm_content',
	])

	// Parse URL and prefill UTM fields
	useEffect(() => {
		if (!url) return // Exit if there's no destination URL

		// Parse the existing UTM parameters from the destination URL
		const newUrl = new URL(formatAndValidateUrl(url))
		const urlParams = new URLSearchParams(newUrl.search)

		if (!urlParams.size > 0) return

		setFormValue('destinationUrl', newUrl.href)

		// Prefill UTM fields based on existing URL parameters
		setValue('utm_source', urlParams.get('utm_source') || '')
		setValue('utm_medium', urlParams.get('utm_medium') || '')
		setValue('utm_campaign', urlParams.get('utm_campaign') || '')
		setValue('utm_id', urlParams.get('utm_id') || '')
		setValue('utm_term', urlParams.get('utm_term') || '')
		setValue('utm_content', urlParams.get('utm_content') || '')
	}, [url]) // Run effect when the URL changes

	useEffect(() => {
		const { destinationUrl } = getValues() // Get the destination URL from form values
		if (!destinationUrl) return // Exit if there's no destination URL

		const params = new URLSearchParams()

		// Map UTM fields to their respective names and add to URLSearchParams
		if (utmFields[0]) {
			params.set('utm_source', utmFields[0])
			setFormValue('utm_source', utmFields[0])
		}
		if (utmFields[1]) {
			params.set('utm_medium', utmFields[1])
			setFormValue('utm_medium', utmFields[1])
		}
		if (utmFields[2]) {
			params.set('utm_campaign', utmFields[2])
			setFormValue('utm_campaign', utmFields[2])
		}

		if (utmFields[3]) {
			params.set('utm_id', utmFields[3])
			setFormValue('utm_id', utmFields[3])
		}
		if (utmFields[4]) {
			params.set('utm_term', utmFields[4])
			setFormValue('utm_term', utmFields[4])
		}
		if (utmFields[5]) {
			params.set('utm_content', utmFields[5])
			setFormValue('utm_content', utmFields[5])
		}

		const preview = `${destinationUrl}?${params.toString()}`
		setPreviewUrl(preview) // Update the badge preview URL
	}, [utmFields, getValues]) // Run effect when utmFields or destinationUrl changes

	const submitHandler = (data) => {
		const { destinationUrl } = getValues()

		const url = new URL(formatAndValidateUrl(destinationUrl))
		const urlParams = new URLSearchParams(url.search)

		// Append UTM parameters to the destination URL
		Object.keys(data).forEach((key) => {
			if (data[key]) urlParams.set(key, data[key])
		})

		url.search = urlParams.toString()
		setFormValue('url', url.toString()) // Update the form's URL with UTM parameters
		setIsURLParamBuilderOpen(false) // Close the slideout
	}

	const header = (
		<Offcanvas.Header className="px-lg-20 border-bottom" closeButton>
			<h2 className="fw-bold my-2 fs-2x">Campaign URL Builder</h2>
		</Offcanvas.Header>
	)

	return (
		<DrawerWrapper
			header={header}
			open={isURLParamBuilderOpen}
			toggle={() => setIsURLParamBuilderOpen(false)}
		>
			<form onSubmit={handleSubmit(submitHandler)}>
				<Row>
					<p className="mb-7 mt-5 px-5">
						This tool allows you to easily add campaign parameters to
						destination URL so you can measure in Google Analytics.
					</p>
					<div className="mb-7 px-5">
						<h5 className="fs-5 fw-bold mb-2 text-gray-800">Constructed URL</h5>
						<div className="w-100 bg-light-info border border-dashed border-opacity-50 border-info rounded-1 mb-4 p-5">
							<p
								className="fs-2 mb-0 text-info fw-bold"
								style={{ lineBreak: 'anywhere' }}
							>
								{previewUrl || 'URL preview will appear here...'}
							</p>
						</div>
					</div>
					<Col md={6} className="px-5">
						<FormGroup>
							<Label className="fs-5 fw-bold mb-2">Campaign ID</Label>
							<div className="mb-10">
								<Controller
									name={'utm_id'}
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<Input
											id="campaign_id"
											value={value}
											type="text"
											className="h-50px"
											placeholder="e.g: abc.123"
											onChange={(e) => {
												onChange(e)
												setValue('utm_id', e.target.value)
											}}
											onBlur={onBlur}
										/>
									)}
								/>
								<p className="my-2 text-gray-600">
									Used to identify specific ad campaigns
								</p>
							</div>
						</FormGroup>
					</Col>
					<Col md={6} className="px-5">
						<FormGroup>
							<Label className="required fs-5 fw-bold mb-2">
								Campaign Source
							</Label>
							<div className="mb-10">
								<Controller
									name={'utm_source'}
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<Input
											id="campaign_source"
											value={value}
											className="h-50px"
											type="text"
											placeholder="e.g: Instagram, Facebook"
											onChange={(e) => {
												onChange(e)
												setValue('utm_source', e.target.value)
											}}
											onBlur={onBlur}
										/>
									)}
								/>
								<p className="my-2 text-gray-600">
									Used to identify a source of delivery
								</p>
								{errors.utm_source ? (
									<span className="error-text text-danger">
										{errors.utm_source.message}
									</span>
								) : null}
							</div>
						</FormGroup>
					</Col>
					<Col md={6} className="px-5">
						<FormGroup>
							<Label className="required fs-5 fw-bold mb-2">
								Campaign Medium
							</Label>
							<div className="mb-10">
								<Controller
									name={'utm_medium'}
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<Input
											id="campaign_medium"
											value={value}
											type="text"
											className="h-50px"
											placeholder="e.g: cpc, banner, email"
											onChange={(e) => {
												onChange(e)
												setValue('utm_medium', e.target.value)
											}}
											onBlur={onBlur}
										/>
									)}
								/>
								<p className="my-2 text-gray-600">
									Used to identify a medium of delivery
								</p>
								{errors.utm_medium ? (
									<span className="error-text text-danger">
										{errors.utm_medium.message}
									</span>
								) : null}
							</div>
						</FormGroup>
					</Col>
					<Col md={6} className="px-5">
						<FormGroup>
							<Label className="required fs-5 fw-bold mb-2">
								Campaign Name
							</Label>
							<div className="mb-10">
								<Controller
									name={'utm_campaign'}
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<Input
											id="campaign_name"
											value={value}
											type="text"
											className="h-50px"
											placeholder="e.g: summer_sale"
											onChange={(e) => {
												onChange(e)
												setValue('utm_campaign', e.target.value)
											}}
											onBlur={onBlur}
										/>
									)}
								/>
								<p className="my-2 text-gray-600">
									Used to identify the promotional or strategic campaign
								</p>
								{errors.utm_campaign ? (
									<span className="error-text text-danger">
										{errors.utm_campaign.message}
									</span>
								) : null}
							</div>
						</FormGroup>
					</Col>
					<Col md={6} className="px-5">
						<FormGroup>
							<Label className="fs-5 fw-bold mb-2">Campaign Term</Label>
							<div className="mb-10">
								<Controller
									name={'utm_term'}
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<Input
											id="campaign_term"
											value={value}
											type="text"
											className="h-50px"
											placeholder="eg: paid keywords"
											onChange={(e) => {
												onChange(e)
												setValue('utm_term', e.target.value)
											}}
											onBlur={onBlur}
										/>
									)}
								/>
								<p className="my-2 text-gray-600">
									Used for paid search to note the keywords for an ad
								</p>
							</div>
						</FormGroup>
					</Col>
					<Col md={6} className="px-5">
						<FormGroup>
							<Label className="fs-5 fw-bold mb-2">Campaign Content</Label>
							<div className="mb-10">
								<Controller
									name={'utm_content'}
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<Input
											id="campaign_content"
											value={value}
											type="text"
											className="h-50px"
											placeholder="e.g: AD Name"
											onChange={(e) => {
												onChange(e)
												setValue('utm_content', e.target.value)
											}}
											onBlur={onBlur}
										/>
									)}
								/>
								<p className="my-2 text-gray-600">
									Used to differentiate ads or links that point to the same URL
								</p>
							</div>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button
							id="utm_campaign_btn"
							color="primary"
							type="submit"
							className="btn btn-info mt-10 w-100"
							style={{ height: '55px', borderRadius: '5px' }}
						>
							Submit
						</Button>
					</Col>
				</Row>
			</form>
		</DrawerWrapper>
	)
}

export default AddNewUTM
