import ShorlinkEmptyState from '../../../assets/media/empty-state-link-list.png'
import QrEmptyState from '../../../assets/media/empty-state/empty-state-qr-code.png'
import SmsCampaignAPI from '../../../services/http/smsCampaign'

const NotSearchFound = ({ type }) => {
	const typesMapper = {
		qr: {
			h2: 'No QR Found',
			p: 'We couldn’t find any QR codes matching your search. Try refining your search or creating a new QR code.',
			img: QrEmptyState,
		},
		shortlink: {
			h2: 'No Link Found',
			p: 'No shortlinks match your search criteria. Adjust your search or create a new shortlink.',
			img: ShorlinkEmptyState,
		},
		emailCampaign: {
			h2: 'No Email Campaign Found',
			p: 'No email campaigns were found based on your search. Try searching again or create a new campaign.',
			img: ShorlinkEmptyState,
		},
		SmsCampaignAPICampaign: {
			h2: 'No Sms Campaign Found',
			p: 'We couldn’t find any SMS campaigns matching your search. Refine your search or start a new one.',
			img: ShorlinkEmptyState,
		},
		whatsappCampaign: {
			h2: 'No Whatsapp Campaign Found',
			p: 'No WhatsApp campaigns match your search. Try searching differently or create a new campaign.',
			img: ShorlinkEmptyState,
		},
		myTemplates: {
			h2: 'No templates found in the Gallery',
			p: 'No templates match your search criteria. Try again or create a new template.',
			img: ShorlinkEmptyState,
		},
	}
	const { h2, p, img } = typesMapper[type]

	return (
		<div className="mobile-hide mt-20">
			<img
				className="opacity-50 img-fluid d-block mx-auto"
				src={img}
				alt={h2}
			/>
			<div className="text-center mt-lg-10">
				<h2 className="fs-2x pt-5 pb-5 fw-bold">{h2}</h2>
				<p className="w-75 mx-auto fw-semibold fs-5 text-gray-500 mb-8">{p}</p>
			</div>
		</div>
	)
}

export default NotSearchFound
