import React, { useContext, useEffect, useRef, useState } from 'react'
import { Table, Spinner } from 'reactstrap'
import EditShortLink from '../edit-short-link'
import ShortLink from './shortLink'
import BulkActions from './bulkActions'
import NotSearchFound from '../../common/emptyScreens/notSearchFound'
import NotFound from '../../common/emptyScreens/notFound'
import { ShortLinkContxt } from '../shortLinkContxt'
import { AuthContxt } from '../../../store/authContxt'
import AddNewShortLink from '../addNew'

const ShortLinksTable = ({ shortLinks, setIsAddNewLinkOpen }) => {
	const { quota } = useContext(AuthContxt)
	const isLimitReached = quota?.shortlink <= 0
	const { notLinksScreen } = useContext(ShortLinkContxt)
	const checkbox = useRef(null)
	const [checked, setChecked] = useState(false)
	const [selectedLinks, setSelectedLinks] = useState([])
	const [indeterminate, setIndeterminate] = useState(false)

	// Update Short link
	const [editShortLink, setEditShortLink] = useState(false)
	const [edittedData, setEdittedData] = useState({})
	const [columnSelected, setColumnSelected] = useState('')

	const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false)

	const editHandler = (data, columnName) => {
		setEditShortLink(true)
		setEdittedData(data)
		setColumnSelected(columnName)
	}

	const toggleAll = () => {
		setSelectedLinks(checked || indeterminate ? [] : shortLinks)
		setChecked(!checked && !indeterminate)
		setIndeterminate(false)
	}

	useEffect(() => {
		const isIndeterminate =
			selectedLinks?.length > 0 && selectedLinks?.length < shortLinks?.length
		setChecked(selectedLinks?.length === shortLinks?.length)
		setIndeterminate(isIndeterminate)
		if (checkbox?.current) {
			checkbox.current.indeterminate = isIndeterminate
		}
	}, [selectedLinks, shortLinks])

	const notLinksScreenMapper = {
		searchFound: <NotSearchFound type={'shortlink'} />,
		notFound: (
			<NotFound
				isLimitReached={isLimitReached}
				handler={() => setIsAddNewLinkOpen(true)}
				type={'shortlink'}
			/>
		),
	}

	return (
		<>
			<div className="w-100 shortlinktableouterdiv">
				{shortLinks?.length > 0 ? (
					<Table className="table align-middle fs-6 gy-5 w-100 tableMinWidth">
						<thead className="table-border">
							<tr className="fs-6 fw-semibold gs-0 table-style text-gray-600 text-start">
								<th
									className="w-10px ps-5 pe-2"
									style={{
										borderRadius: '5px 0px 0px 5px !important',
										borderLeft: ' 1px solid #e9e6f1',
									}}
								>
									<div className="form-check form-check-sm form-check-custom form-check-info form-check-solid not-checked-white rounded-1">
										<input
											type="checkbox"
											className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
											ref={checkbox}
											checked={checked}
											onChange={toggleAll}
										/>
									</div>
								</th>
								<th className="ps-3">Link Name</th>
								<th className="ps-2 min-w-200px">Short Link</th>
								<th className="ps-2 text-center">Clicks</th>
								<th className="min-w-20px ps-2 text-center">Enable</th>
								<th className="min-w-100px ps-2">Date</th>
								<th className="min-w-100px ps-2">Expiry Date</th>
								<th
									className="ps-2 d-flex border-top-0 border-bottom-0"
									style={{
										borderRadius: '0px 5px 5px 0px !important',
										borderRight: ' 1px solid #e9e6f1',
									}}
								>
									<span>Actions</span>
									<span
										style={{
											marginLeft: '10px',
										}}
									>
										{selectedLinks.length > 0 ? (
											<BulkActions
												bulkLinks={selectedLinks}
												bulkLinksHandler={setSelectedLinks}
												bulkDeleteLoading={bulkDeleteLoading}
												setBulkDeleteLoading={setBulkDeleteLoading}
											/>
										) : null}
									</span>
								</th>
							</tr>
						</thead>
						<tbody
							className={`fw-semibold text-gray-600 ${
								bulkDeleteLoading ? 'pe-none' : ''
							}`}
						>
							{shortLinks?.map((list, index) => {
								return (
									<ShortLink
										key={index}
										list={list}
										shortLinks={shortLinks}
										selectedLinks={selectedLinks}
										setSelectedLinks={setSelectedLinks}
										editHandler={editHandler}
									/>
								)
							})}
						</tbody>
					</Table>
				) : (
					<div>{notLinksScreenMapper[notLinksScreen]}</div>
				)}
			</div>
			{bulkDeleteLoading && (
				<div className="d-flex justify-content-center position-fixed top-50 start-50 translate-middle">
					<Spinner style={{ width: '4rem', height: '4rem' }} />
				</div>
			)}

			{/* <EditShortLink
				edittedDataHandler={setEdittedData}
				editShortLink={editShortLink}
				setEditShortLink={setEditShortLink}
				edittedData={edittedData}
			/> */}

			<AddNewShortLink
				isAddNewLinkOpen={editShortLink}
				setIsAddNewLinkOpen={setEditShortLink}
				edittedData={edittedData}
				setColumnSelected={setColumnSelected}
				columnSelected={columnSelected}
			/>
		</>
	)
}

export default ShortLinksTable
