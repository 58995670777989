import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import socialicon from '../../../../assets/qr-icons/social-media.png'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

const ListofLinksForm = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setShowPreview,
}) => {
	const [open, setOpen] = useState('0')
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)

	const toggle = (id) => {
		open === id ? setOpen('') : setOpen(id)
	}

	// Handle input changes
	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}))
	}

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	// ========================
	// LINKS + SEPARATORS
	// ========================
	const handleAddLink = () => {
		setFormData((prevData) => ({
			...prevData,
			links: [
				...(prevData.links || []),
				{ linkImage: null, linkText: '', linkUrl: '' },
			],
		}))
	}

	const handleRemoveLink = (index) => {
		setFormData((prevData) => {
			const newLinks = [...(prevData.links || [])]
			newLinks.splice(index, 1)
			return { ...prevData, links: newLinks }
		})
	}

	const handleLinkChange = (index, field, value) => {
		setFormData((prevData) => {
			const newLinks = [...(prevData.links || [])]
			newLinks[index] = {
				...newLinks[index],
				[field]: value,
			}
			return { ...prevData, links: newLinks }
		})
	}

	const handleLinkImageChange = (index, file) => {
		setFormData((prevData) => {
			const newLinks = [...(prevData.links || [])]
			newLinks[index] = {
				...newLinks[index],
				linkImage: file,
			}
			return { ...prevData, links: newLinks }
		})
	}

	const handleAddSeparator = () => {
		setFormData((prevData) => ({
			...prevData,
			separators: [
				...(prevData.separators || []),
				{ separatorName: 'SEPARATOR NAME', separatorText: '' },
			],
		}))
	}

	const handleRemoveSeparator = (index) => {
		setFormData((prevData) => {
			const newSeparators = [...(prevData.separators || [])]
			newSeparators.splice(index, 1)
			return { ...prevData, separators: newSeparators }
		})
	}

	const handleSeparatorChange = (index, field, value) => {
		setFormData((prevData) => {
			const newSeparators = [...(prevData.separators || [])]
			newSeparators[index] = {
				...newSeparators[index],
				[field]: value,
			}
			return { ...prevData, separators: newSeparators }
		})
	}

	// ========================
	// IMAGES
	// ========================
	const handleImagesChange = (e) => {
		const files = e.target.files
		console.log('Selected files:', files)
		if (!files || files.length === 0) return
		setFormData((prevData) => ({
			...prevData,
			contentImages: Array.from(files), // store as array
		}))
	}

	// ========================
	// SOCIAL NETWORKS
	// ========================
	const handleSocialNetworkClick = (network) => {
		setFormData((prevData) => {
			const selected = new Set(prevData.selectedSocialNetworks || [])
			if (selected.has(network)) {
				selected.delete(network)
			} else {
				selected.add(network)
			}
			return {
				...prevData,
				selectedSocialNetworks: Array.from(selected),
			}
		})
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center flex-wrap">
					{/* Primary Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>

					{/* Button Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '1',
			title: 'Basic Information',
			icon: designicon,
			content: (
				<>
					<Form>
						<h5 className="fw-bold">Link List Information</h5>
						{/* Logo */}
						<div className="mb-3">
							<Label className="fw-bold">Logo</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '24px',
										color: '#999',
										pointerEvents: 'none',
									}}
								>
									+
								</div>
								<Input
									type="file"
									id="basicLogo"
									style={{
										opacity: 0,
										width: '100%',
										height: '100%',
										cursor: 'pointer',
									}}
									// onChange={handleFileChange}
								/>
							</div>
						</div>

						{/* Title (required) */}
						<div className="mb-3">
							<Label for="basicTitle" className="fw-bold">
								Title <span className="text-danger">*</span>
							</Label>
							<Input
								type="text"
								id="basicTitle"
								placeholder="Enter title"
								value={formData.basicTitle || ''}
								onChange={handleInputChange}
								required
							/>
							<small className="text-danger">Required field</small>
						</div>

						{/* Description */}
						<div className="mb-3">
							<Label for="basicDescription" className="fw-bold">
								Description
							</Label>
							<Input
								type="text"
								id="basicDescription"
								placeholder="Enter description"
								value={formData.basicDescription || ''}
								onChange={handleInputChange}
							/>
						</div>
					</Form>
				</>
			),
		},
		{
			id: '4',
			icon: infoicon,
			title: 'Content',
			content: (
				<>
					<h5 className="fw-bold mb-4">All the details about your QR.</h5>

					{/* LINKS */}
					<div className="mb-5">
						<h6 className="fw-bold">Links</h6>
						<div className="d-flex gap-3 mb-3">
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={handleAddLink}
							>
								+ Add link
							</button>
							<button
								type="button"
								className="btn btn-outline-secondary"
								onClick={handleAddSeparator}
							>
								+ Add separator
							</button>
						</div>

						{/* Render Links */}
						{(formData.links || []).map((link, index) => (
							<div
								key={`link-${index}`}
								className="p-3 mb-3"
								style={{ border: '1px solid #eee', borderRadius: '5px' }}
							>
								<div className="d-flex justify-content-between align-items-center mb-2">
									<strong>LINK {index + 1}</strong>
									<div>
										<button
											type="button"
											className="btn btn-link text-danger"
											onClick={() => handleRemoveLink(index)}
										>
											<i className="bi bi-trash"></i> Delete
										</button>
									</div>
								</div>
								{/* Image upload */}
								<div
									style={{
										width: '60px',
										height: '60px',
										border: '1px dashed #ccc',
										borderRadius: '5px',
										position: 'relative',
										marginBottom: '10px',
									}}
								>
									<div
										style={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											fontSize: '20px',
											color: '#999',
											pointerEvents: 'none',
										}}
									>
										+
									</div>
									<Input
										type="file"
										style={{
											opacity: 0,
											width: '100%',
											height: '100%',
											cursor: 'pointer',
										}}
										onChange={(e) => {
											if (e.target.files && e.target.files.length > 0) {
												handleLinkImageChange(index, e.target.files[0])
											}
										}}
									/>
								</div>
								{/* Link text */}
								<FormGroup>
									<Label className="fw-bold">
										Link text <span className="text-danger">*</span>
									</Label>
									<Input
										type="text"
										placeholder="Write here the link text..."
										value={link.linkText}
										onChange={(e) =>
											handleLinkChange(index, 'linkText', e.target.value)
										}
									/>
									<small className="text-danger">Required field</small>
								</FormGroup>
								{/* URL */}
								<FormGroup>
									<Label className="fw-bold">
										URL <span className="text-danger">*</span>
									</Label>
									<Input
										type="text"
										placeholder="http://..."
										value={link.linkUrl}
										onChange={(e) =>
											handleLinkChange(index, 'linkUrl', e.target.value)
										}
									/>
									<small className="text-danger">Required field</small>
								</FormGroup>
							</div>
						))}

						{/* Render Separators */}
						{(formData.separators || []).map((sep, index) => (
							<div
								key={`separator-${index}`}
								className="p-3 mb-3"
								style={{ border: '1px solid #eee', borderRadius: '5px' }}
							>
								<div className="d-flex justify-content-between align-items-center mb-2">
									<strong>
										{sep.separatorName || `SEPARATOR ${index + 1}`}
									</strong>
									<div>
										<button
											type="button"
											className="btn btn-link text-danger"
											onClick={() => handleRemoveSeparator(index)}
										>
											<i className="bi bi-trash"></i> Delete
										</button>
									</div>
								</div>
								<FormGroup>
									<Label className="fw-bold">
										Separator text <span className="text-danger">*</span>
									</Label>
									<Input
										type="text"
										placeholder="Write here the separator text..."
										value={sep.separatorText}
										onChange={(e) =>
											handleSeparatorChange(
												index,
												'separatorText',
												e.target.value
											)
										}
									/>
									<small className="text-danger">Required field</small>
								</FormGroup>
							</div>
						))}
					</div>

					{/* IMAGES */}
					<div className="mb-5">
						<h6 className="fw-bold">Images</h6>
						<div
							style={{
								border: '2px dashed #ccc',
								borderRadius: '8px',
								padding: '40px 20px',
								textAlign: 'center',
								position: 'relative',
							}}
						>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() =>
									document.getElementById('imageUploadInput').click()
								}
								style={{ fontSize: '16px', padding: '10px 20px' }}
							>
								Upload Images
							</button>
							<p className="text-muted mt-2">Maximum size: 15MB</p>
							<Input
								id="imageUploadInput"
								type="file"
								multiple
								onChange={handleImagesChange}
								style={{ display: 'none' }}
							/>
						</div>
					</div>

					{/* SOCIAL NETWORKS */}
					<div className="mb-5">
						<h6 className="fw-bold">Social Networks</h6>
						<FormGroup>
							<Label>Title</Label>
							<Input
								type="text"
								id="contentSocialTitle"
								placeholder="Enter a title for social networks"
								value={formData.contentSocialTitle || ''}
								onChange={handleInputChange}
							/>
						</FormGroup>
						<div className="d-flex flex-wrap gap-3 mt-3">
							{[
								'facebook',
								'instagram',
								'twitter',
								'youtube',
								'linkedin',
								'pinterest',
								'tiktok',
								'snapchat',
								'spotify',
								'discord',
							].map((network) => {
								const isSelected =
									(formData.selectedSocialNetworks || []).indexOf(network) !==
									-1
								return (
									<div
										key={network}
										onClick={() => handleSocialNetworkClick(network)}
										style={{
											width: '40px',
											height: '40px',
											borderRadius: '8px',
											background: isSelected ? '#d1e7ff' : '#f2f2f2',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
									>
										{network[0].toUpperCase()}
									</div>
								)
							})}
						</div>
					</div>
				</>
			),
		},
		{
			id: '6',
			icon: infoicon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							// setShowPreview={setShowPreview}
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '5',
			icon: socialicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<>
			<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
				{accordionItems.map((item) => (
					<AccordionItem key={item.id}>
						<AccordionHeader targetId={item.id}>
							<img src={item.icon} alt={item.title} className="w-30px me-4" />
							{item.title}
						</AccordionHeader>
						<AccordionBody accordionId={item.id}>
							{item.content}
							{item.fields && (
								<Form>
									<div className="row">
										{item.fields.map((field) => (
											<div
												key={field.id}
												className={`col-md-${field.colSize || 12} mb-3`}
											>
												<FormGroup>
													<Label className="fs-5 fw-bold" for={field.id}>
														{field.label}
													</Label>
													<Input
														type={field.type}
														className={field.className}
														maxLength={field.maxLength}
														id={field.id}
														placeholder={field.placeholder}
														value={
															(field.type !== 'file' && formData[field?.id]) ||
															''
														}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
										))}
									</div>
								</Form>
							)}
						</AccordionBody>
					</AccordionItem>
				))}
			</Accordion>
		</>
	)
}

export default ListofLinksForm
