// landingpagepreview.js
import React, { useState, useEffect } from 'react'
import '../../../../styles/animations.css' // Import the animations CSS
import { getAnimationStyle } from '../../../../utils/animationStyles'

const LandingpagePreview = ({
	formData,
	showPreview,
	animationDirection,
	contentWelcomeTime,
}) => {
	// console.log('animationDirection::: ', animationDirection);
	// console.log('contentWelcomeTime::: ', contentWelcomeTime);
	// console.log('showPreview::: ', showPreview);
	const [showWelcome, setShowWelcome] = useState(false)

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => {
					setShowWelcome(false)
				},
				(contentWelcomeTime || 3) * 1000
			)

			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	if (showWelcome && formData.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime || 3),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div className="position-relative onhvr-scroll-y">
			<div
				className="qr-p-v-card mx-auto bg-light pb-14 p-5 scroll-content"
				style={{
					height: '575px',
					maxWidth: ' 900px',
				}}
			>
				<div
					style={{
						backgroundColor: formData.primaryColor || '#fff',
						backgroundImage: formData.backgroundImage
							? `url(${formData.backgroundImage})`
							: 'none',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						padding: '20px',
						minHeight: '100vh',
						width: '100%',
					}}
				>
					<header>
						<h1 style={{ color: formData.ctaColor || '#000' }}>
							{formData.title || 'Landing Page Title'}
						</h1>
					</header>
					<section>
						<div
							dangerouslySetInnerHTML={{
								__html: formData.description || 'Your description goes here...',
							}}
						/>
					</section>
				</div>
			</div>
		</div>
	)
}

export default LandingpagePreview
