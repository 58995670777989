import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import EmptyState from '../../assets/media/empty-state-link-list.png'
import { Spinner } from 'reactstrap'

const ContactTable = (props) => {
	const {
		selectAll,
		handleSelectAllChange,

		allContacts,
		isContactSelected,
		handleCheckboxChange,

		handleContactUpdate,

		setContactForDelete,
		toggleSingleDeleteModal,

		openCreateForm,

		search,
		tags,
		isLoading,
		infinitePageLoading,
	} = props

	const [contacts, setContacts] = useState([])

	useEffect(() => {
		console.log('table component select all', selectAll)
		setContacts(allContacts)
	}, [allContacts])

	return (
		<>
			{contacts.length > 0 ? (
				<Table
					striped
					className="table align-middle fs-6 gy-5 w-100 tableMinWidth overflow-x-auto table"
				>
					{/* Table Header */}
					<thead className="table-border">
						<tr className="fs-6 fw-semibold gs-0 table-style text-gray-600 text-start">
							<th
								className="w-10px ps-5 pe-2"
								style={{ borderLeft: '1px solid rgb(233, 230, 241)' }}
							>
								<input
									type="checkbox"
									checked={selectAll}
									onChange={handleSelectAllChange}
									className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
								/>
							</th>
							<th className="min-w-200px ps-2">First Name</th>
							<th className="min-w-150 ps-2">Last Name</th>
							<th className="min-w-100 ps-2">Email</th>
							<th className="min-w-100 ps-2">Mobile</th>
							<th className="min-w-100 ps-2">Whatsapp</th>
							<th className="min-w-100 ps-2">Job Title</th>
							<th className="min-w-100 ps-2">Department</th>
							<th className="min-w-125 ps-2">Location</th>
							<th
								className="ps-2 d-flex border-top-0 border-bottom-0"
								style={{ borderRight: '1px solid rgb(233, 230, 241)' }}
							>
								Actions
							</th>
						</tr>
					</thead>

					{/* Table Body */}
					<tbody className="fw-semibold text-gray-600 w-100">
						{contacts.map((contact, index) => (
							<tr
								key={index}
								className="link-row border border-right-0 py-3 border-top-0 border-left-0"
							>
								<td className="ps-6">
									<input
										type="checkbox"
										checked={selectAll ? true : isContactSelected(contact)}
										onChange={() => handleCheckboxChange(contact)}
										className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
									/>
								</td>
								<td className="py-5 text-capitalize">{contact.firstName}</td>
								<td className="text-start">{contact?.lastName || '-'}</td>
								<td className="text-start">{contact?.email || '-'}</td>
								<td className="text-start">{contact?.mobile || '-'}</td>
								<td className="text-start">{contact?.whatsapp || '-'}</td>
								<td className="text-start">{contact?.job || '-'}</td>
								<td className="text-start">{contact?.department || '-'}</td>
								<td className="text-start">
									{contact?.state ? `${contact.state}, ` : ''}
									{contact?.country || '-'}
								</td>
								<td className="text-end">
									<div className="w-fit-content d-flex align-items-center">
										<button
											type="button"
											className="btn px-0 py-0"
											onClick={() => handleContactUpdate(contact)}
										>
											<i className="fa-edit fs-2 cursor-pointer fa-regular ms-5"></i>
										</button>
										<button
											type="button"
											className="btn px-0 py-0"
											onClick={() => {
												setContactForDelete(contact)
												toggleSingleDeleteModal()
											}}
										>
											<i
												className="fa-regular fa-trash-can fs-2 ms-5 text-danger cursor-pointer"
												title="Delete"
												style={{ marginTop: '-4px' }}
											></i>
										</button>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			) : (
				<div className="d-flex flex-column justify-content-center align-items-center mt-15">
					{isLoading ? (
						<Spinner />
					) : (
						<>
							<img
								className="opacity-50 img-fluid"
								src={EmptyState}
								alt="No Contacts"
							/>
							<div className="text-center mt-lg-10">
								<h2 className="fs-2x w-100 pt-5 mx-auto fw-bold">
									Currently, there are no Contacts to display.
								</h2>
								<p className="w-100 mx-auto fw-semibold fs-5 text-gray-500 mb-8">
									You currently have no contacts in your list. Add a new contact
									to keep your list up to date.
								</p>
								{search.trim() === '' && tags.length === 0 && (
									<button
										type="button"
										className="btn btn-info w-300px fs-2 rounded-1"
										onClick={openCreateForm}
									>
										Add New Contact
									</button>
								)}
							</div>
						</>
					)}
				</div>
			)}

			{infinitePageLoading && (
				<div className="d-flex flex-column justify-content-center align-items-center mt-2">
					<Spinner />
				</div>
			)}
		</>
	)
}

export default ContactTable
