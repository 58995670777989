import React, { useRef, useEffect, useState } from 'react'

const RichTextEditor = ({ value, onChange }) => {
	const editorRef = useRef(null)
	const contentRef = useRef(value)
	const fileInputRef = useRef(null)
	const [selectedImage, setSelectedImage] = useState(null)
	const [showImageControls, setShowImageControls] = useState(false)

	useEffect(() => {
		if (editorRef.current && value !== contentRef.current) {
			editorRef.current.innerHTML = value
			contentRef.current = value
		}
	}, [value])

	/**
	 * Generic handler to execute a document command.
	 * The 'arg' can be color codes, font sizes, etc.
	 */
	const handleCommand = (command, arg = null) => {
		document.execCommand(command, false, arg)
		handleInput()
	}

	/**
	 * Triggered whenever the editable content changes.
	 */
	const handleInput = () => {
		if (editorRef.current) {
			const newContent = editorRef.current.innerHTML
			contentRef.current = newContent
			onChange(newContent)
		}
	}

	const handleImageInsert = (e) => {
		const file = e.target.files?.[0]
		if (file) {
			if (file.type.startsWith('image/') && file.size < 5 * 1024 * 1024) {
				const reader = new FileReader()
				reader.onload = () => {
					// Focus the editor first
					editorRef.current?.focus()

					// Create and insert the image with a resizable wrapper
					const imgHtml = `
						<div class="image-wrapper" contenteditable="false" style="
							display: inline-block;
							resize: both;
							overflow: hidden;
							min-width: 100px;
							min-height: 100px;
							max-width: 100%;
							border: 1px solid transparent;
							padding: 5px;
						">
							<img 
								src="${reader.result}" 
								alt="Inserted image" 
								style="
									width: 100%;
									height: 100%;
									object-fit: contain;
									pointer-events: none;
								"
							/>
						</div>
					`

					// Insert at cursor position
					document.execCommand('insertHTML', false, imgHtml)
					handleInput()
				}
				reader.readAsDataURL(file)
			} else {
				alert('Please select an image file under 5MB')
			}
		}
		// Reset file input
		if (fileInputRef.current) {
			fileInputRef.current.value = ''
		}
	}

	const handleImageClick = (imgElement) => {
		setSelectedImage(imgElement)
		setShowImageControls(true)
	}

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!e.target.closest('.image-controls') && !e.target.matches('img')) {
				setShowImageControls(false)
				setSelectedImage(null)
			}
		}

		document.addEventListener('click', handleClickOutside)
		return () => document.removeEventListener('click', handleClickOutside)
	}, [])

	const handleImageResize = (width) => {
		if (selectedImage) {
			selectedImage.style.width = width + 'px'
			handleInput()
		}
	}

	const handleImageAlign = (alignment) => {
		if (selectedImage) {
			selectedImage.style.display = 'block'
			selectedImage.style.margin =
				alignment === 'center'
					? '0 auto'
					: alignment === 'left'
						? '0 auto 0 0'
						: '0 0 0 auto'
			handleInput()
		}
	}

	// Add CSS for resizable images
	useEffect(() => {
		const style = document.createElement('style')
		style.textContent = `
			.image-wrapper {
				position: relative;
				display: inline-block;
				resize: both !important;
				overflow: hidden !important;
				min-width: 100px !important;
				min-height: 100px !important;
			}
			.image-wrapper:hover {
				border: 1px dashed #ccc !important;
			}
			.image-wrapper:active {
				border: 1px solid #007bff !important;
			}
			.image-wrapper::after {
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 10px;
				height: 10px;
				cursor: se-resize;
				background: linear-gradient(135deg, transparent 50%, #ccc 50%);
			}
		`
		document.head.appendChild(style)
		return () => document.head.removeChild(style)
	}, [])

	return (
		<div className="rich-text-editor" dir="ltr">
			{/* Toolbar */}
			<div className="toolbar mb-2 d-flex align-items-center">
				{/* Font Family */}
				<select
					className="form-select form-select-sm me-2"
					style={{ width: 'auto' }}
					onChange={(e) => handleCommand('fontName', e.target.value)}
				>
					<option value="Arial">Arial</option>
					<option value="Times New Roman">Times New Roman</option>
					<option value="Courier New">Courier New</option>
					<option value="Georgia">Georgia</option>
				</select>

				{/* Font Size */}
				<select
					className="form-select form-select-sm me-2"
					style={{ width: 'auto' }}
					onChange={(e) => handleCommand('fontSize', e.target.value)}
				>
					{/* Note: 1..7 are mapped to browser's built-in sizes */}
					<option value="1">10px</option>
					<option value="2">13px</option>
					<option value="3">16px</option>
					<option value="4">18px</option>
					<option value="5">24px</option>
					<option value="6">32px</option>
					<option value="7">48px</option>
				</select>

				{/* Bold */}
				<button
					type="button"
					className="btn btn-sm btn-light me-1"
					onClick={() => handleCommand('bold')}
				>
					<strong>B</strong>
				</button>

				{/* Italic */}
				<button
					type="button"
					className="btn btn-sm btn-light me-1"
					onClick={() => handleCommand('italic')}
				>
					<em>I</em>
				</button>

				{/* Underline */}
				<button
					type="button"
					className="btn btn-sm btn-light me-1"
					onClick={() => handleCommand('underline')}
				>
					<u>U</u>
				</button>

				{/* Strikethrough */}
				<button
					type="button"
					className="btn btn-sm btn-light me-1"
					onClick={() => handleCommand('strikeThrough')}
				>
					<s>S</s>
				</button>

				{/* Text Color */}
				<input
					type="color"
					className="form-control form-control-color form-control-sm me-2"
					style={{ width: '40px' }}
					onChange={(e) => handleCommand('foreColor', e.target.value)}
				/>

				{/* Background Color */}
				<input
					type="color"
					className="form-control form-control-color form-control-sm me-2"
					style={{ width: '40px' }}
					onChange={(e) => handleCommand('backColor', e.target.value)}
				/>

				{/* Alignment */}
				<div className="btn-group me-2">
					<button
						type="button"
						className="btn btn-sm btn-outline-dark"
						onClick={() => handleCommand('justifyLeft')}
						title="Align Left"
					>
						&#8676;
					</button>
					<button
						type="button"
						className="btn btn-sm btn-outline-dark"
						onClick={() => handleCommand('justifyCenter')}
						title="Align Center"
					>
						&#8633;
					</button>
					<button
						type="button"
						className="btn btn-sm btn-outline-dark"
						onClick={() => handleCommand('justifyRight')}
						title="Align Right"
					>
						&#8677;
					</button>
					<button
						type="button"
						className="btn btn-sm btn-outline-dark"
						onClick={() => handleCommand('justifyFull')}
						title="Justify"
					>
						&#8801;
					</button>
				</div>

				{/* Image Insert Button */}
				<input
					ref={fileInputRef}
					type="file"
					accept="image/*"
					style={{ display: 'none' }}
					onChange={handleImageInsert}
				/>
				<button
					type="button"
					className="btn btn-sm btn-secondary me-1"
					onClick={() => fileInputRef.current?.click()}
					title="Insert Image"
				>
					<i className="fas fa-image"></i> Image
				</button>

				{/* Text Insert Button */}
				{/* <button
					type="button"
					className="btn btn-sm btn-secondary"
					onClick={() => handleCommand('insertText', 'Inserted Text')}
				>
					+ Insert
				</button> */}
			</div>

			{/* Image Controls Popup */}
			{showImageControls && selectedImage && (
				<div
					className="image-controls p-2 mb-2 border rounded bg-light"
					style={{
						position: 'relative',
						zIndex: 1000,
					}}
				>
					<div className="d-flex align-items-center gap-2">
						<label className="me-2">Width:</label>
						<input
							type="range"
							min="50"
							max="100"
							defaultValue="100"
							onChange={(e) => handleImageResize(e.target.value + '%')}
							className="form-range"
							style={{ width: '100px' }}
						/>

						<div className="btn-group ms-2">
							<button
								className="btn btn-sm btn-outline-secondary"
								onClick={() => handleImageAlign('left')}
								title="Align Left"
							>
								<i className="fas fa-align-left"></i>
							</button>
							<button
								className="btn btn-sm btn-outline-secondary"
								onClick={() => handleImageAlign('center')}
								title="Align Center"
							>
								<i className="fas fa-align-center"></i>
							</button>
							<button
								className="btn btn-sm btn-outline-secondary"
								onClick={() => handleImageAlign('right')}
								title="Align Right"
							>
								<i className="fas fa-align-right"></i>
							</button>
						</div>

						<button
							className="btn btn-sm btn-danger ms-2"
							onClick={() => {
								selectedImage.remove()
								setShowImageControls(false)
								setSelectedImage(null)
								handleInput()
							}}
							title="Remove Image"
						>
							<i className="fas fa-trash"></i>
						</button>
					</div>
				</div>
			)}

			{/* Editable Area */}
			<div
				ref={editorRef}
				className="editor border p-2 rounded"
				contentEditable
				onInput={handleInput}
				style={{ minHeight: '150px' }}
				dir="ltr"
			/>
		</div>
	)
}

export default RichTextEditor
