import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios'
import divslyLogo from '../../assets/media/divsly.svg'
import divslyIcon from '../../assets/media/divsly-icon-w.svg'
import * as yup from 'yup'
import { ONBOARDING_STATUS, PHONE_REGEX } from '../../utils/enum'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input, Label } from 'reactstrap'
import { AuthContxt } from '../../store/authContxt'
import { useNavigate } from 'react-router-dom'
import withOnboarding from '../../components/wrapper/withOnboarding'
import { debounce } from 'lodash'
import SignupPageWrapper from '../../components/wrapper/signupPageWrapper'
import { ERROR_MESSAGES } from '../../config/constants'

const AcceptTerms = () => {
	const { profile, updateProfile } = useContext(AuthContxt)
	const navigate = useNavigate()
	const [phoneRegex, setPhoneRegex] = useState('')
	const [countryCode, setCountryCode] = useState('')
	const [userName, setUserName] = useState('')
	const [loading, setLoading] = useState(false)
	const [termsError, setTermsError] = useState('')

	const updateProfileValidation = yup.object().shape({
		useracceptedterms: yup.bool().oneOf([true], 'Please accept the terms'),
		mobile: yup
			.string()
			.required()
			.matches(phoneRegex, `Please enter valid phone no.`),
	})

	const form = useForm({
		resolver: yupResolver(updateProfileValidation),
		defaultValues: {
			mobile: '',
			useracceptedterms: false,
		},
	})

	const {
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
		control,
	} = form

	const { mobile } = watch()

	const fetchCountryCode = useCallback(async () => {
		const storedCountryCode = localStorage.getItem('user_country_code')
		if (storedCountryCode) {
			setCountryCode(storedCountryCode)
			return
		}

		try {
			const response = await axios.get('https://ipapi.co/json/')
			const newCountryCode = response.data.country_code.toLowerCase()
			setCountryCode(newCountryCode)
			localStorage.setItem('user_country_code', newCountryCode)
		} catch (error) {
			console.error('Error fetching country code:', error)
		}
	}, [])

	const debouncedFetchCountryCode = useCallback(
		debounce(fetchCountryCode, 1000),
		[fetchCountryCode]
	)

	useEffect(() => {
		debouncedFetchCountryCode()

		if (profile && profile.name) {
			setUserName(profile.name)
		}

		if (profile?.useracceptedterms) navigate('/dashboard')
		return () => {
			debouncedFetchCountryCode.cancel()
		}
	}, [debouncedFetchCountryCode, profile, profile?.useracceptedterms])

	const getSafeValue = (value) => (value == null ? '' : value) // Returns empty string if value is null or undefined

	const submitHandler = async (formData) => {
		try {
			setLoading(true)
			setTermsError('')

			// Log the current profile state
			console.log('Current Profile Data:', profile)

			const updatedPayload = {
				...profile,
				...formData,
				mobile: formData?.mobile + '',
				lastName: getSafeValue(profile.lastName), // Ensures lastName is an empty string if null
				onboardingStatus: ONBOARDING_STATUS.TERMS_ACCEPTED,
			}

			// Log the updated payload before the API call
			console.log('Updated Payload:', updatedPayload)

			await updateProfile(updatedPayload)
			setLoading(false)
			navigate('/complete-profile')
		} catch (e) {
			if (
				e.response.data.message === ERROR_MESSAGES.MOBILE_ALREADY_REGISTERED
			) {
				console.log('setting', ERROR_MESSAGES.MOBILE_ALREADY_REGISTERED)
				setTermsError(e.response.data.message)
			}

			console.error('Error while updating profile: ', e)
			setLoading(false)
		}
	}

	return (
		<SignupPageWrapper>
			<div className="mx-auto w-75">
				<img src={divslyLogo} alt="divsly-logo" className="mb-5 pb-5" />
				<h2 className="mb-5 mt-5 fs-1 mb-20 text-black pt-5 d-flex flex-column gap-5 fw-bold pb-5">
					<span className="fs-2x fw-bolder">
						Hi{' '}
						<span className=" text-capitalize text-info">
							{profile.firstName}
						</span>
						,
					</span>
					<span className="fw-bolder fs-2hx">
						You are just about to finish!
					</span>
				</h2>
				<p className="mb-5 pb-5 fs-3">
					You must agree to the terms and conditions before continuing.
				</p>
				<form
					id="kt_account_profile_details_form"
					className="form"
					onSubmit={handleSubmit(submitHandler)}
				>
					<Form.Group controlId="phoneNumber" className="mb-5">
						<Form.Label className="fs-3">
							Mobile Number<span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<PhoneInput
							country={countryCode}
							value={mobile}
							name="mobile"
							onChange={(phone, data, event, formattedValue) => {
								const counrtyCode = data?.countryCode?.toUpperCase()
								const countryPhoneRegex = PHONE_REGEX[counrtyCode]
								setPhoneRegex(countryPhoneRegex)
								setValue('mobile', phone)
								setValue('country', counrtyCode)
							}}
						/>
						{errors.mobile ? (
							<div style={{ textAlign: 'start' }}>
								<span className="error-text text-danger">
									{errors.mobile.message}
								</span>
							</div>
						) : null}
						{termsError && (
							<div style={{ textAlign: 'start', marginTop: '5px' }}>
								<span className="error-text text-danger">{termsError}</span>
							</div>
						)}
					</Form.Group>

					{/* <Form.Group
                controlId="acceptTerms"
                className="w-75 my-5 p-4 border rounded"
              >
                <Form.Check
                  type="checkbox"
                  label="I agree with terms of service, Privacy Policy application use and data processing aggrement"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  required
                />
              </Form.Group> */}
					<Label className="w-100 mt-10 p-0 d-flex border-0 fst-italic form-check-label">
						<Controller
							name="useracceptedterms"
							control={control}
							render={({ field }) => (
								<div className="form-check form-check-sm form-check-custom form-check-info form-check-solid not-checked-white me-4 rounded-1">
									<Input
										{...field}
										className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
										type="checkbox"
										value={field.value}
										onChange={async (e) => {
											field.onChange(e)
										}}
									/>
								</div>
							)}
						/>

						<span className="text-dark">
							I agree with{' '}
							<a
								className="text-info"
								target="_blank"
								href="https://www.divsly.com/applegal/terms-of-service"
							>
								Terms of Service
							</a>
							&nbsp;&{' '}
							<a
								className="text-info"
								target="_blank"
								href="https://www.divsly.com/applegal/privacy-policy"
							>
								Privacy Policy
							</a>
						</span>
					</Label>

					{errors.useracceptedterms ? (
						<div style={{ textAlign: 'start' }}>
							<span className="error-text text-danger">
								{errors.useracceptedterms.message}
							</span>
						</div>
					) : null}

					<Button
						variant="primary"
						type="submit"
						className="d-flex btn btn-info mt-5 fs-2 h-65px w-100 rounded-1 align-items-center justify-content-between"
						style={{ width: '100%', maxWidth: '425px' }}
					>
						{loading ? (
							<Spinner />
						) : (
							<>
								Continue Process
								<img src={divslyIcon} alt="signup" />
							</>
						)}
					</Button>
				</form>
			</div>
		</SignupPageWrapper>
	)
}

export default AcceptTerms
