import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	label,
	Form,
} from 'reactstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

// Replace these imports with your actual asset imports
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import socialicon from '../../../../assets/qr-icons/social-media.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import configicon from '../../../../assets/qr-icons/config.png'

// Custom components
import QrLink from '../QrLink'
import FileUpload from './components/fileUpload'
import FeedbackPreview from '../qr-code-previews/feedbackpreview'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

// -----------------------------------------------------------------------------
// 1. BASIC INFORMATION
// -----------------------------------------------------------------------------
const BasicInformation = ({ formData, setFormData }) => {
	const [categories, setCategories] = useState(
		formData.categories || [
			{
				id: 'cat1',
				icon: 'restaurant',
				name: 'Restaurant',
				subcategories: [
					{ id: 'sub1', name: 'Food' },
					{ id: 'sub2', name: 'Beverages' },
				],
				description: 'e.g. Please select a subcategory to evaluate it.',
			},
			{
				id: 'cat2',
				icon: 'room',
				name: 'Room',
				subcategories: [
					{ id: 'sub3', name: 'Bed' },
					{ id: 'sub4', name: 'Cleaning' },
					{ id: 'sub5', name: 'Services' },
				],
				description: 'e.g. Please select a subcategory to evaluate it.',
			},
			{
				id: 'cat3',
				icon: 'reception',
				name: 'Reception',
				subcategories: [],
				description: 'e.g. Please select a subcategory to evaluate it.',
			},
		]
	)

	const handleCategoryChange = (index, field, value) => {
		const updatedCategories = [...categories]
		updatedCategories[index][field] = value
		setCategories(updatedCategories)
		setFormData((prev) => ({ ...prev, categories: updatedCategories }))
	}

	const handleSubcategoryChange = (catIndex, subIndex, value) => {
		const updatedCategories = [...categories]
		updatedCategories[catIndex].subcategories[subIndex].name = value
		setCategories(updatedCategories)
		setFormData((prev) => ({ ...prev, categories: updatedCategories }))
	}

	const addCategory = () => {
		const newCategory = {
			id: `cat${categories.length + 1}`,
			icon: '',
			name: '',
			subcategories: [],
			description: 'e.g. Please select a subcategory to evaluate it.',
		}
		const updatedCategories = [...categories, newCategory]
		setCategories(updatedCategories)
		setFormData((prev) => ({ ...prev, categories: updatedCategories }))
	}

	const addSubcategory = (catIndex) => {
		const updatedCategories = [...categories]
		updatedCategories[catIndex].subcategories.push({
			id: `sub${updatedCategories[catIndex].subcategories.length + 1}`,
			name: '',
		})
		setCategories(updatedCategories)
		setFormData((prev) => ({ ...prev, categories: updatedCategories }))
	}

	return (
		<div className="w-full max-w-lg">
			{/* Name */}
			<label className="fw-bold fs-5 form-label">Name *</label>
			<input
				type="text"
				placeholder="Hotel Opera"
				value={formData.name || ''}
				onChange={(e) =>
					setFormData((prev) => ({ ...prev, name: e.target.value }))
				}
				className="h-50px form-control"
			/>

			{/* Title */}
			<div className="mt-4">
				<label className="fw-bold fs-5 form-label">Title</label>
				<input
					type="text"
					placeholder="e.g. Leave us your feedback"
					value={formData.title || ''}
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, title: e.target.value }))
					}
					className="h-50px form-control"
				/>
			</div>

			{/* Description */}
			<div className="mt-4">
				<label className="fw-bold fs-5 form-label">Description</label>
				<input
					type="text"
					placeholder="e.g. Please select a category to rate it."
					value={formData.description || ''}
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, description: e.target.value }))
					}
					className="h-50px form-control"
				/>
			</div>

			{/* Assessment Options */}
			<div className="mt-4">
				<label className="fw-bold fs-5 form-label">Assessment</label>
				<FormGroup check>
					<label check>
						<Input
							type="radio"
							name="assessment"
							value="rating"
							checked={formData.assessment === 'rating'}
							onChange={(e) =>
								setFormData((prev) => ({ ...prev, assessment: e.target.value }))
							}
						/>
						Rating
					</label>
				</FormGroup>
				<FormGroup check>
					<label check>
						<Input
							type="radio"
							name="assessment"
							value="yesno"
							checked={formData.assessment === 'yesno'}
							onChange={(e) =>
								setFormData((prev) => ({ ...prev, assessment: e.target.value }))
							}
						/>
						Answer Yes / No
					</label>
				</FormGroup>
			</div>

			{/* Comments Toggle */}
			<div className="mt-4">
				<label check>
					<Input
						type="checkbox"
						checked={formData.enableComments || false}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								enableComments: e.target.checked,
							}))
						}
					/>
					Comments
				</label>
			</div>

			{/* Categories and Subcategories */}
			<div className="mt-4">
				<h4 className="text-sm font-semibold mb-2">Categories</h4>
				{categories.map((category, catIndex) => (
					<div key={category.id} className="mb-4">
						<h5>Category {catIndex + 1}</h5>
						<div className="d-flex gap-3 align-items-center">
							<FormGroup>
								<label className="text-sm text-gray-600 block mb-2">Icon</label>
								<Input
									type="select"
									value={category.icon}
									onChange={(e) =>
										handleCategoryChange(catIndex, 'icon', e.target.value)
									}
								>
									<option value="">Select Icon</option>
									<option value="restaurant">Restaurant</option>
									<option value="room">Room</option>
									<option value="reception">Reception</option>
								</Input>
							</FormGroup>
							<FormGroup className="flex-grow-1">
								<label className="fw-bold fs-5 form-label">Name *</label>
								<Input
									type="text"
									placeholder="e.g. Restaurant"
									value={category.name}
									onChange={(e) =>
										handleCategoryChange(catIndex, 'name', e.target.value)
									}
									className="h-50px form-control"
								/>
							</FormGroup>
						</div>

						{/* Subcategories */}
						<div className="mt-3">
							<h6>Subcategories</h6>
							<label className="fw-bold fs-5 form-label">Description</label>
							<Input
								type="text"
								placeholder="e.g. Please select a subcategory to evaluate it."
								value={category.description}
								onChange={(e) =>
									handleCategoryChange(catIndex, 'description', e.target.value)
								}
								className="h-50px form-control"
							/>
							{category.subcategories.map((subcategory, subIndex) => (
								<div key={subcategory.id} className="mt-2">
									<label className="fw-bold fs-5 form-label">Name *</label>
									<Input
										type="text"
										placeholder="e.g. Food"
										value={subcategory.name}
										onChange={(e) =>
											handleSubcategoryChange(
												catIndex,
												subIndex,
												e.target.value
											)
										}
										className="h-50px form-control"
									/>
								</div>
							))}
							<button
								onClick={() => addSubcategory(catIndex)}
								className="mt-2 btn btn-outline-primary"
							>
								+ Add subcategory
							</button>
						</div>
					</div>
				))}
				<button onClick={addCategory} className="mt-2 btn btn-outline-primary">
					+ Add category
				</button>
			</div>
		</div>
	)
}

// -----------------------------------------------------------------------------
// 2. CONTENT (Submission Form Settings)
// -----------------------------------------------------------------------------
const Content = ({ formData, setFormData }) => {
	return (
		<div className="w-full max-w-lg">
			<p className="text-sm text-gray-600 mb-3">
				All the details about your QR.
			</p>

			<h4 className="text-sm font-semibold mb-2">Submission Form</h4>
			<p className="text-sm text-gray-600 mb-3">
				Choose the fields that will be mandatory or optional for the user when
				sending the feedback.
			</p>

			{/* Mandatory Email */}
			<div className="mb-3">
				<label check>
					<Input
						type="checkbox"
						checked={formData.mandatoryEmail || false}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								mandatoryEmail: e.target.checked,
							}))
						}
					/>
					Mandatory email
				</label>
			</div>

			{/* Mandatory Phone */}
			<div className="mb-3">
				<label check>
					<Input
						type="checkbox"
						checked={formData.mandatoryPhone || false}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								mandatoryPhone: e.target.checked,
							}))
						}
					/>
					Mandatory phone
				</label>
			</div>

			{/* Contact Information */}
			<h4 className="text-sm font-semibold mb-2 mt-4">Contact Information</h4>
			<div className="mb-3">
				<label check>
					<Input
						type="checkbox"
						checked={formData.enableEmailReviews || false}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								enableEmailReviews: e.target.checked,
							}))
						}
					/>
					Enable sending reviews by email
				</label>
			</div>

			{formData.enableEmailReviews && (
				<>
					<label className="fw-bold fs-5 form-label">Email *</label>
					<Input
						type="email"
						placeholder="E.g. name@email.com"
						value={formData.reviewEmail || ''}
						onChange={(e) =>
							setFormData((prev) => ({ ...prev, reviewEmail: e.target.value }))
						}
						className="h-50px form-control"
					/>
				</>
			)}

			<label className="fw-bold fs-5 form-label">Website</label>
			<Input
				type="text"
				placeholder="https://qrfy.com/"
				value={formData.website || ''}
				onChange={(e) =>
					setFormData((prev) => ({ ...prev, website: e.target.value }))
				}
				className="h-50px form-control"
			/>
		</div>
	)
}

// -----------------------------------------------------------------------------
// 3. MAIN FORM COMPONENT (Accordion Structure)
// -----------------------------------------------------------------------------
const FeedbackForm = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setShowPreview,
}) => {
	const [open, setOpen] = useState('0')
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)

	const toggle = (id) => {
		setOpen(open === id ? '' : id)
	}

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center естественно-wrap">
					{/* Primary Color Picker */}
					<FormGroup>
						<label className="fs-4 fw-semibold mb-2">Primary Color</label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>

					{/* Button Color Picker */}
					<FormGroup>
						<label className="fs-4 fw-semibold mb-2">Button Color</label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '2',
			icon: infoicon,
			title: 'Basic Information',
			content: (
				<BasicInformation formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '3',
			icon: contenticon,
			title: 'Content',
			content: <Content formData={formData} setFormData={setFormData} />,
		},
		{
			id: '6',
			icon: contenticon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<label>Image</label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							// setShowPreview={setShowPreview}
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '4',
			icon: configicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
			{accordionItems.map((item) => (
				<AccordionItem key={item.id}>
					<AccordionHeader targetId={item.id}>
						<img src={item.icon} alt={item.title} className="w-30px me-4" />
						{item.title}
					</AccordionHeader>
					<AccordionBody accordionId={item.id}>{item.content}</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

// -----------------------------------------------------------------------------
// 4. WRAPPER COMPONENT
// -----------------------------------------------------------------------------
const Feedback = ({ isEdit = false, qrType = 'feedback' }) => {
	const [formData, setFormData] = useState({
		// Basic Info
		name: '',
		title: '',
		description: '',
		assessment: 'rating', // Default to rating
		enableComments: false,
		categories: [
			{
				id: 'cat1',
				icon: 'restaurant',
				name: 'Restaurant',
				subcategories: [
					{ id: 'sub1', name: 'Food' },
					{ id: 'sub2', name: 'Beverages' },
				],
				description: 'e.g. Please select a subcategory to evaluate it.',
			},
			{
				id: 'cat2',
				icon: 'room',
				name: 'Room',
				subcategories: [
					{ id: 'sub3', name: 'Bed' },
					{ id: 'sub4', name: 'Cleaning' },
					{ id: 'sub5', name: 'Services' },
				],
				description: 'e.g. Please select a subcategory to evaluate it.',
			},
			{
				id: 'cat3',
				icon: 'reception',
				name: 'Reception',
				subcategories: [],
				description: 'e.g. Please select a subcategory to evaluate it.',
			},
		],
		// Content (Submission Settings)
		mandatoryEmail: false,
		mandatoryPhone: false,
		enableEmailReviews: false,
		reviewEmail: '',
		website: '',
		// Design
		primaryColor: '#ffffff',
		ctaColor: '#348ce5',
		// Password Protection (for PasswordSection compatibility)
		passwordProtectionEnabled: false,
		password: '',
	})

	return (
		<div style={{ display: 'flex', gap: '20px' }}>
			{/* Form Section */}
			<div style={{ flex: 1 }}>
				<FeedbackForm
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			</div>
			{/* Preview Section */}
			<div style={{ flex: 1, border: '1px solid #ccc', padding: '10px' }}>
				<FeedbackPreview formData={formData} />
			</div>
		</div>
	)
}

export default FeedbackForm
