import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const FacebookPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Debugging: Log formData to verify the structure
	console.log('formData in FacebookPreview:', formData)

	// Styling
	const backgroundColor = formData.primaryColor || '#fff'
	const textColor = formData.ctaColor || '#348ce5' // Using ctaColor for the text color

	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div
			style={{
				backgroundColor: backgroundColor,
				color: textColor, // Text color for all elements
				padding: '20px',
				minHeight: '100vh',
			}}
		>
			{/* Header: Facebook Configuration */}
			{/* <header style={{ marginBottom: '20px', text-align: 'center' }}> */}
			<h1 style={{ margin: 0 }}>Facebook Configuration</h1>
			{formData.facebookUrl && (
				<div style={{ marginTop: '10px' }}>
					<p style={{ margin: 0, fontSize: '16px', wordBreak: 'break-all' }}>
						Facebook URL: {formData.facebookUrl}
					</p>
					{formData.hideLikes && (
						<p style={{ margin: 0, fontSize: '14px' }}>
							Likes count hidden on Like button
						</p>
					)}
					{formData.name && (
						<p style={{ margin: 0, fontSize: '14px' }}>Name: {formData.name}</p>
					)}
					{formData.title && (
						<p style={{ margin: 0, fontSize: '14px' }}>
							Title: {formData.title}
						</p>
					)}
					{formData.website && (
						<p style={{ margin: 0, fontSize: '14px' }}>
							Website: {formData.website}
						</p>
					)}
				</div>
			)}
			{/* </header> */}
			//{' '}
		</div>
	)
}

export default FacebookPreview
