import React, { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import qrpdfpreview from '../../../../assets/qr-preview/qr-pdf-preview.png'
import { isColorDark } from './color-checker/isColorDark'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const PdfQrPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [pageNumber, setPageNumber] = useState(1)
	const [numPages, setNumPages] = useState(null)
	const [showWelcome, setShowWelcome] = useState(false)
	const [containerWidth, setContainerWidth] = useState(0)
	const previewRef = useRef(null)

	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
	}

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			if (previewRef.current) {
				setContainerWidth(previewRef.current.offsetWidth)
			}
		})

		if (previewRef.current) {
			resizeObserver.observe(previewRef.current)
			setContainerWidth(previewRef.current.offsetWidth)
		}

		return () => {
			if (previewRef.current) {
				resizeObserver.unobserve(previewRef.current)
			}
		}
	}, [])

	const nextPage = () => {
		if (pageNumber < numPages) {
			setPageNumber(pageNumber + 1)
		}
	}

	const prevPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1)
		}
	}

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div className="pdf-qr-preview-container h-100">
			<div>
				<div className="pdf-qr-head">
					<p className="fs-5 mb-1 text-gray-200">
						{formData?.company || 'Divsly'}
					</p>
					<h1 className="fw-bold fs-1">{formData?.title || 'Important PDF'}</h1>
					<p className="fs-5 text-gray-200">
						{formData?.description || 'Here is description for pdf.'}
					</p>
				</div>
			</div>
			<div className="pdf-qr-body">
				<div
					className="pdf-qr-backdrop-clr"
					style={{
						background: formData?.gradientColor
							? `linear-gradient(to top, ${formData?.primaryColor}, ${formData?.gradientColor})`
							: formData?.primaryColor,
					}}
				></div>
			</div>
			<p className="text-center pt-5">{formData?.website || ''}</p>

			{formData?.pdf ? (
				<div
					className="pdf-qr-preview"
					ref={previewRef}
					style={{
						width: '90%',
						maxWidth: '400px',
						height: 'auto',
						border: '1px solid #ccc',
						overflow: 'hidden',
					}}
				>
					<Document
						file={{ url: formData?.pdf, password: formData?.pdfPassword }}
						onLoadError={(error) =>
							console.error('Error while loading document', error)
						}
						onLoadSuccess={onDocumentLoadSuccess}
						onPassword={() => {}}
					>
						<Page pageNumber={1} width={containerWidth || 275} />
					</Document>
					<div className="pdf-navigation d-none">
						<button onClick={prevPage} disabled={pageNumber <= 1}>
							Previous
						</button>
						<p>
							Page {pageNumber} of {numPages}
						</p>
						<button onClick={nextPage} disabled={pageNumber >= numPages}>
							Next
						</button>
					</div>
				</div>
			) : (
				<img className="pdf-qr-preview" src={qrpdfpreview} alt="QR Preview" />
			)}
			<button
				className={`preview-pdf-sticky display-block btn rounded-0 text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'}`}
				style={{ background: formData?.ctaColor }}
				onClick={() => {
					if (formData?.pdf) {
						window.open(formData?.pdf, '_blank')
					} else {
						console.log('PDF URL is missing')
					}
				}}
			>
				<i
					className={`fa-solid fa-up-right-from-square text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'}`}
				></i>
				Preview PDF
			</button>
		</div>
	)
}

export default PdfQrPreview
