import React, { useState, useEffect } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const InstagramPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])
	// Debugging: Log formData to verify the structure
	console.log('formData in InstagramPreview:', formData)

	// Styling
	const backgroundColor = formData.primaryColor || '#fff'
	const textColor = formData.ctaColor || '#348ce5' // Using ctaColor for the text color

	// Generate Instagram profile URL
	const generateInstagramUrl = () => {
		const username = formData.username || ''
		return `https://www.instagram.com/${username}`
	}

	const instagramUrl = generateInstagramUrl()

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div
			style={{
				backgroundColor: backgroundColor,
				color: textColor, // Text color for all elements
				padding: '20px',
				minHeight: '100vh',
			}}
		>
			{/* Header: Instagram Configuration */}
			{/* <header style={{ margin-bottom: '20px', text-align: 'center' }}>
                <h1 style={{ margin: 0 }}>Instagram Configuration</h1>
                {formData.username && (
                    <div style={{ margin-top: '10px' }}>
                        <p style={{ margin: 0, font-size: '16px', word-break: 'break-all' }}>
                            Instagram URL: {instagramUrl}
                        </p>
                    </div>
                )}
            </header> */}
		</div>
	)
}

export default InstagramPreview
