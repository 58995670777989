import { createContext, useContext, useEffect, useState } from 'react'
import UserSettingsApi from '../../services/http/userSettings'
import { AuthContxt } from '../../store/authContxt'
import { USER_TYPE } from '../../utils/enum'
import { useLocation } from 'react-router-dom'

export const UserSettingsContxt = createContext(null)

const UserSettingsContxtProvider = (props) => {
	const [tab, setTab] = useState(0)
	const [cards, setCards] = useState([])
	const [billingAddresses, setBillingAddresses] = useState([])
	const { fetchProfile, profile } = useContext(AuthContxt)
	const [selectedAddress, setSelectedAddress] = useState(null)
	const [edit, setEdit] = useState(false)
	const [apiSettings, setApiSettings] = useState([])
	const [oauthApps, setOauthApps] = useState([])

	const location = useLocation()

	const tabHandler = (tab) => {
		if (profile.userType !== USER_TYPE.USER) {
			if (profile.userType === USER_TYPE.ADMIN) {
				setTab('2')
			} else {
				setTab(tab)
			}
		}
	}

	const cardHandler = (action, data) => {
		if (action === 'delete') {
			setCards((preState) => {
				return preState?.filter((item) => item?.id !== data?.id)
			})
		}
		if (action === 'add') {
			setCards((preState) => [...preState, data])
		}
	}

	const billingAddressHandler = (action, data) => {
		if (action === 'delete') {
			if (data?.primary) {
				fetchBillingAddresses()
			} else {
				setBillingAddresses((preState) => {
					return preState?.filter((item) => item?.id !== data?.id)
				})
			}
		}
		if (action === 'add') {
			setBillingAddresses((preState) => [...preState, data])
		}
		if (action === 'update') {
			setBillingAddresses((preState) => {
				return preState?.map((item) =>
					item?.id === data?.id ? { ...preState, ...data } : item
				)
			})
		}
		if (action === 'updatePrimary') {
			setBillingAddresses((preState) => {
				return preState?.map((item) => ({
					...item,
					primary: item?.id === data?.id,
				}))
			})
		}
	}

	const apiSettingsHandler = (action, data) => {
		if (action === 'add') {
			setApiSettings((prevState) => [...prevState, data])
		}
		if (action === 'delete') {
			setApiSettings((prevState) =>
				prevState.filter((item) => item.id !== data.id)
			)
		}
		if (action === 'update') {
			setApiSettings((prevState) =>
				prevState.map((item) =>
					item.id === data.id ? { ...item, ...data } : item
				)
			)
		}
	}

	const oauthAppsHandler = (action, data) => {
		if (action === 'add') {
			setOauthApps((prevState) => [...prevState, data])
		}
		if (action === 'delete') {
			setOauthApps((prevState) =>
				prevState.filter((item) => item.id !== data.id)
			)
		}
		if (action === 'update') {
			setOauthApps((prevState) =>
				prevState.map((item) =>
					item.id === data.id ? { ...item, ...data } : item
				)
			)
		}
	}

	const fetchPaymentMethods = async () => {
		try {
			const response = await UserSettingsApi.paymentCards()
			const data = response?.data?.data
			if (data?.length > 0) {
				setCards(data)
			} else {
				setCards([])
			}
		} catch (e) {
			console.log('Error while fetching cards: ', e)
		}
	}

	const fetchBillingAddresses = async () => {
		try {
			const response = await UserSettingsApi.billingAddresses()
			const data = response?.data?.data
			if (data?.length > 0) {
				setBillingAddresses(data)
				setSelectedAddress(data[0]?.id)
			} else {
				setBillingAddresses([])
			}
		} catch (e) {
			console.log('Error while fetching billing addresses: ', e)
		}
	}

	useEffect(() => {
		fetchPaymentMethods()
		fetchBillingAddresses()
		fetchProfile()
	}, [location])

	useEffect(() => {
		if (profile?.userType !== USER_TYPE.USER) {
			if (profile?.userType === USER_TYPE.ADMIN) {
				setTab(2)
			} else {
				setTab(0)
			}
		}
	}, [profile?.userType])

	return (
		<UserSettingsContxt.Provider
			value={{
				tab,
				tabHandler,

				cards,
				cardHandler,

				billingAddresses,
				billingAddressHandler,

				selectedAddress,
				setSelectedAddress,

				edit,
				setEdit,

				apiSettings,
				apiSettingsHandler,

				oauthApps,
				oauthAppsHandler,
			}}
		>
			{props.children}
		</UserSettingsContxt.Provider>
	)
}

export default UserSettingsContxtProvider
