import React, { useState, useEffect, useRef } from 'react'

const WebPreview = ({ formData }) => {
	const [hasError, setHasError] = useState(false)
	const [showWelcome, setShowWelcome] = useState(true)
	const ref = useRef(null)

	useEffect(() => {
		// Reset error and welcome screen when the URL changes
		setHasError(false)
		setShowWelcome(true)

		if (formData?.contentWelcomeImage) {
			const timer = setTimeout(
				() => {
					setShowWelcome(false)
				},
				(formData?.contentWelcomeTime || 2.5) * 1000
			)

			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [
		formData?.destinationUrl,
		formData?.contentWelcomeImage,
		formData?.contentWelcomeTime,
	])

	const handleIframeLoad = () => {}

	const handleIframeError = () => {
		setHasError(true)
	}

	const getAnimationStyle = () => {
		const duration = `${formData?.contentWelcomeTime || 2.5}s`
		switch (formData?.animationDirection || 'bottom-to-top') {
			case 'top-to-bottom':
				return { animation: `slideInTop ${duration} forwards` }
			case 'left-to-right':
				return { animation: `slideInLeft ${duration} forwards` }
			case 'right-to-left':
				return { animation: `slideInRight ${duration} forwards` }
			case 'fade':
				return { animation: `fadeIn ${duration} forwards` }
			case 'bottom-to-top':
			default:
				return { animation: `slideInBottom ${duration} forwards` }
		}
	}

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(),
					width: '100%',
					height: '486px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div className="w-100" style={{ height: '486px' }}>
			<div className="web-qr-input-box bg-gray-300 p-5">
				<input
					className="w-100 form-control"
					placeholder={formData?.destinationUrl || 'enter website URL'}
					type="text"
					value={formData?.destinationUrl || ''}
					readOnly
				/>
			</div>
			<iframe
				ref={ref}
				id="web-view"
				title="Website Preview"
				className="h-100 w-100"
				src={formData?.destinationUrl || 'https://divsly.com'}
				onLoad={handleIframeLoad}
				onError={handleIframeError}
				sandbox="allow-scripts allow-same-origin"
			></iframe>
		</div>
	)
}

// Add CSS animations
const styles = `
@keyframes slideInBottom {
  from { transform: translateY(100%); }
  to { transform: translateY(-20%); }
}
@keyframes slideInTop {
  from { transform: translateY(-100%); }
  to { transform: translateY(-20%); }
}
@keyframes slideInLeft {
  from { transform: translate(-100%, -20%); }
  to { transform: translate(0%, -20%); }
}
@keyframes slideInRight {
  from { transform: translate(100%, -20%); }
  to { transform: translate(0%, -20%); }
}
@keyframes fadeIn {
  from { opacity: 0; transform: translate(0%, 0%); }
  to { opacity: 1; transform: translate(0%, -20%); }
}
`

// Inject styles into the document
const styleSheet = document.createElement('style')
styleSheet.type = 'text/css'
styleSheet.innerText = styles
document.head.appendChild(styleSheet)

export default WebPreview
