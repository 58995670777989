import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { AuthContxt } from './store/authContxt'
import { useContext } from 'react'
import AcceptTerms from './pages/profile-acceptance/acceptTerms'

const AuthLayout = () => {
	const { profile } = useContext(AuthContxt)
	const navigate = useNavigate()

	if (profile) {
		if (profile.useracceptedterms) return <Outlet />
		navigate('/accept-terms', { replace: true })
		return (
			<Routes>
				<Route path="/accept-terms" element={<AcceptTerms />} />
			</Routes>
		)
	}
	return navigate('/', { replace: true })
}
export default AuthLayout
