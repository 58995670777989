import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import configicon from '../../../../assets/qr-icons/config.png'
import QrLink from '../QrLink'
import CouponPreview from '../qr-code-previews/couponpreview'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

// BasicInformation component
const BasicInformation = ({ formData, setFormData }) => {
	const [openOfferInfo, setOpenOfferInfo] = useState('offer-info')
	const [openCouponInfo, setOpenCouponInfo] = useState('')

	const toggleOfferInfo = (id) => {
		setOpenOfferInfo((prev) => (prev === id ? '' : id))
	}

	const toggleCouponInfo = (id) => {
		setOpenCouponInfo((prev) => (prev === id ? '' : id))
	}

	const handleCoverImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prev) => ({ ...prev, coverImage: reader.result }))
			}
			reader.readAsDataURL(file)
		}
	}

	const handleBarcodeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prev) => ({ ...prev, barcodeImage: reader.result }))
			}
			reader.readAsDataURL(file)
		}
	}

	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prev) => ({ ...prev, [id]: value }))
	}

	const handleCheckboxChange = (e) => {
		const { id, checked } = e.target
		setFormData((prev) => ({ ...prev, [id]: checked }))
		if (!checked) {
			setFormData((prev) => ({ ...prev, barcodeImage: null }))
		}
	}

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<Accordion open={openOfferInfo} toggle={toggleOfferInfo} className="mb-4">
				<AccordionItem>
					<AccordionHeader targetId="offer-info">
						Offer Information
					</AccordionHeader>
					<AccordionBody accordionId="offer-info">
						<FormGroup className="mt-4">
							<Label className="text-sm text-gray-600 mb-2">Cover Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.coverImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={formData?.coverImage || '/path/to/default-logo.png'}
									alt="Cover"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.coverImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: 0,
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleCoverImageChange}
									accept="image/*"
								/>
							</div>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Company</Label>
							<Input
								id="company"
								type="text"
								placeholder="E.g. Company LLC"
								value={formData?.company || ''}
								onChange={handleInputChange}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label className="fw-bold fs-5 form-label">Title *</Label>
							<Input
								id="title"
								type="text"
								placeholder="E.g."
								value={formData?.title || ''}
								onChange={handleInputChange}
								invalid={!formData?.title}
							/>
							{!formData?.title && (
								<div className="text-danger">Required field</div>
							)}
						</FormGroup>

						<FormGroup className="mb-3">
							<Label className="fw-bold fs-5 form-label">Description</Label>
							<Input
								id="description"
								type="textarea"
								rows="4"
								placeholder="Enter description"
								value={formData?.description || ''}
								onChange={handleInputChange}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label className="fw-bold fs-5 form-label">Sales badge</Label>
							<Input
								id="salesBadge"
								type="text"
								placeholder="Sales badge"
								value={formData?.salesBadge || ''}
								onChange={handleInputChange}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label className="fw-bold fs-5 form-label">
								Button to see the code
							</Label>
							<Input
								id="buttonText"
								type="text"
								placeholder="Get coupon"
								value={formData?.buttonText || ''}
								onChange={handleInputChange}
							/>
						</FormGroup>
					</AccordionBody>
				</AccordionItem>
			</Accordion>

			<Accordion
				open={openCouponInfo}
				toggle={toggleCouponInfo}
				className="mt-3"
			>
				<AccordionItem>
					<AccordionHeader targetId="coupon-info">
						Coupon Information
					</AccordionHeader>
					<AccordionBody accordionId="coupon-info">
						<FormGroup className="mb-3">
							<Label check>
								<Input
									type="checkbox"
									id="useBarcode"
									checked={formData?.useBarcode || false}
									onChange={handleCheckboxChange}
								/>{' '}
								Use barcode?
							</Label>
						</FormGroup>
						{formData?.useBarcode && (
							<FormGroup className="mb-3">
								<Label className="fw-bold fs-5 form-label">
									Barcode (640 x 360px)
								</Label>
								<div
									style={{
										width: '200px',
										height: '100px',
										border: '1px dashed #ccc',
										borderRadius: '5px',
										position: 'relative',
										overflow: 'hidden',
									}}
								>
									<div
										style={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											fontSize: '20px',
											color: '#999',
											pointerEvents: 'none',
											display: formData?.barcodeImage ? 'none' : 'block',
										}}
									>
										+
									</div>
									<img
										src={formData?.barcodeImage || '/path/to/default-logo.png'}
										alt="Barcode"
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover',
											display: formData?.barcodeImage ? 'block' : 'none',
										}}
									/>
									<Input
										type="file"
										style={{
											opacity: 0,
											width: '100%',
											height: '100%',
											cursor: 'pointer',
											position: 'absolute',
											top: 0,
											left: 0,
										}}
										onChange={handleBarcodeImageChange}
										accept="image/*"
									/>
								</div>
							</FormGroup>
						)}
						<FormGroup className="mb-3">
							<Label className="fw-bold fs-5 form-label">Coupon code *</Label>
							<Input
								id="couponCode"
								type="text"
								placeholder="E.g. SALE75G"
								value={formData?.couponCode || ''}
								onChange={handleInputChange}
								invalid={!formData?.couponCode}
							/>
							{!formData?.couponCode && (
								<div className="text-danger">Required field</div>
							)}
						</FormGroup>

						<FormGroup className="mb-3">
							<Label className="fw-bold fs-5 form-label">Valid until</Label>
							<Input
								id="validUntil"
								type="date"
								value={formData?.validUntil || ''}
								onChange={handleInputChange}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label className="fw-bold fs-5 form-label">
								Terms and Conditions
							</Label>
							<Input
								id="termsAndConditions"
								type="textarea"
								rows="4"
								placeholder="Provide the terms and conditions of your coupon"
								value={formData?.termsAndConditions || ''}
								onChange={handleInputChange}
							/>
						</FormGroup>
					</AccordionBody>
				</AccordionItem>
			</Accordion>
		</div>
	)
}
// const handleInputChange = (e) => {
// 	const { value, id } = e.target
// 	setFormData((prevData) => ({ ...prevData, [id]: value }))
// }

// Content component
// const Content = ({ formData, setFormData }) => {
// 	const handleWelcomeImageChange = (e) => {
// 		if (e.target.files && e.target.files.length > 0) {
// 			const file = e.target.files[0]
// 			const reader = new FileReader()
// 			reader.onloadend = () => {
// 				setFormData((prevData) => ({
// 					...prevData,
// 					contentWelcomeImage: reader.result,
// 				}))
// 			}
// 			reader.readAsDataURL(file)
// 		}
// 	}

// }

// return (
// 	<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
// 		<div className="mb-5">
// 			<h6 className="fw-bold">Welcome screen</h6>
// 			<div className="mb-3">
// 				<Label>Image</Label>
// 				<div
// 					style={{
// 						width: '100px',
// 						height: '100px',
// 						border: '1px dashed #ccc',
// 						borderRadius: '5px',
// 						position: 'relative',
// 						overflow: 'hidden',
// 					}}
// 				>
// 					<div
// 						style={{
// 							position: 'absolute',
// 							top: '50%',
// 							left: '50%',
// 							transform: 'translate(-50%, -50%)',
// 							fontSize: '20px',
// 							color: '#999',
// 							pointerEvents: 'none',
// 							display: formData?.contentWelcomeImage ? 'none' : 'block',
// 						}}
// 					>
// 						+
// 					</div>
// 					<img
// 						src={formData?.contentWelcomeImage || '/path/to/default-logo.png'}
// 						alt="Welcome"
// 						style={{
// 							width: '100%',
// 							height: '100%',
// 							objectFit: 'cover',
// 							display: formData?.contentWelcomeImage ? 'block' : 'none',
// 						}}
// 					/>
// 					<Input
// 						type="file"
// 						style={{
// 							opacity: 0,
// 							width: '100%',
// 							height: '100%',
// 							cursor: 'pointer',
// 							position: 'absolute',
// 							top: 0,
// 							left: 0,
// 						}}
// 						onChange={handleWelcomeImageChange}
// 						accept="image/*"
// 					/>
// 				</div>
// 			</div>
// 			<FormGroup>
// 				<Label for="contentWelcomeTime">Time (seconds)</Label>
// 				<Input
// 					type="range"
// 					id="contentWelcomeTime"
// 					min="2.5"
// 					max="10"
// 					step="0.5"
// 					value={formData.contentWelcomeTime || 2.5}
// 					onChange={handleInputChange}
// 				/>
// 				<div>
// 					{formData.contentWelcomeTime
// 						? `${formData.contentWelcomeTime} sec`
// 						: '2.5 sec'}
// 				</div>
// 			</FormGroup>
// 		</div>
// 	</div>
// )

// Main CouponQrForm component
const CouponQrForm = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setShowPreview,
}) => {
	const [open, setOpen] = useState('0')
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)

	const toggle = (id) => {
		setOpen((prev) => (prev === id ? '' : id))
	}

	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prevData) => ({ ...prevData, [id]: value }))
	}

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center">
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.primaryColor || '#ffffff'}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
							/>
							<Input
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '2',
			icon: infoicon,
			title: 'Basic Information',
			content: (
				<BasicInformation formData={formData} setFormData={setFormData} />
			),
		},
		// {
		// 	id: '3',
		// 	icon: contenticon,
		// 	title: 'Content',
		// 	content: <Content formData={formData} setFormData={setFormData} />,
		// },
		{
			id: '6',
			icon: contenticon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							// setShowPreview={setShowPreview}
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '4',
			icon: configicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
			{accordionItems.map((item) => (
				<AccordionItem key={item.id}>
					<AccordionHeader targetId={item.id}>
						<img src={item.icon} alt={item.title} className="w-30px me-4" />
						{item.title}
					</AccordionHeader>
					<AccordionBody accordionId={item.id}>
						{item.content}
						{item.fields && (
							<Form>
								<div className="row">
									{item.fields.map((field) => (
										<div
											key={field.id}
											className={`col-md-${field.colSize || 12} mb-3`}
										>
											<FormGroup>
												<Label className="fs-5 fw-bold" for={field.id}>
													{field.label}
												</Label>
												<Input
													type={field.type}
													className={field.className}
													maxLength={field.maxLength}
													id={field.id}
													placeholder={field.placeholder}
													value={
														(field.type !== 'file' && formData[field?.id]) || ''
													}
													onChange={handleInputChange}
												/>
											</FormGroup>
										</div>
									))}
								</div>
							</Form>
						)}
					</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

// // Main CouponQr component
// const CouponQr = ({ isEdit = false, qrType = 'coupon',showPreview , setShowPreview}) => {
// 	const [formData, setFormData] = useState({
// 		title: '',
// 		coverImage: null,
// 		company: '',
// 		description: '',
// 		salesBadge: '',
// 		buttonText: '',
// 		primaryColor: '#ffffff',
// 		ctaColor: '#348ce5',
// 		contentWelcomeImage: null,
// 		contentWelcomeTime: 2.5,
// 		useBarcode: false,
// 		couponCode: '',
// 		validUntil: '',
// 		termsAndConditions: '',
// 		barcodeImage: null,
// 		passwordProtectionEnabled: false, // Added for PasswordSection compatibility
// 		password: '', // Added for PasswordSection compatibility
// 	})

// 	return (
// 		<div style={{ display: 'flex', gap: '20px' }}>
// 			<div style={{ flex: 1 }}>
// 				<CouponQrForm
// 					formData={formData}
// 					setFormData={setFormData}
// 					isEdit={isEdit}
// 					qrType={qrType}
// 					showPreview={showPreview}
// 					setShowPreview={setShowPreview}
// 				/>
// 			</div>
// 		</div>
// 	)
// }

export default CouponQrForm
