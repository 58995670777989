import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import FloatingBtn from './floatingbutton/floatingBtn'
import '../../../../styles/animations.css'
import { getAnimationStyle } from '../../../../utils/animationStyles'

const BcardPreview = ({
	formData,
	showPreview,
	animationDirection,
	contentWelcomeTime,
}) => {
	const {
		firstName,
		lastName,
		profilePic,
		job,
		bio,
		mobile,
		phone,
		fax,
		email,
		company,
		website,
		summary,
		businessType,
		address,
		facebook,
		image,
		linkedin,
		google,
		instagram,
		contentWelcomeImage,
	} = formData

	const [showWelcome, setShowWelcome] = useState(false)

	// Handle welcome screen visibility
	useEffect(() => {
		if (showPreview && contentWelcomeImage) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => {
					setShowWelcome(false)
				},
				(contentWelcomeTime || 2.5) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeImage, contentWelcomeTime])

	const vCardItems = useMemo(
		() => [
			{
				text: 'Save to My Phone',
				icon: 'fa-solid fa-floppy-disk',
				onClick: () => {
					const blob = new Blob([formData?.vcard], { type: 'text/vcard' })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = `${formData?.firstName || 'contact'}.vcf`
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				},
			},
		],
		[formData]
	)

	return (
		<div className="position-relative onhvr-scroll-y">
			{/* Welcome Screen Overlay, constrained to preview container */}
			{showWelcome && contentWelcomeImage && (
				<div
					style={{
						...getAnimationStyle(animationDirection, contentWelcomeTime || 2.5),
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						overflow: 'hidden',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 10,
					}}
				>
					<img
						src={contentWelcomeImage}
						alt="Welcome"
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'contain', // Ensures the image fits within the container
						}}
					/>
				</div>
			)}

			{/* Main vCard Content */}
			<div
				className="qr-p-v-card mx-auto bg-light pb-14 p-5 scroll-content"
				style={{
					height: '575px',
					maxWidth: '900px',
					position: 'relative', // Ensure the welcome screen is positioned relative to this container
				}}
			>
				<div
					style={{
						background: formData?.gradientColor
							? `linear-gradient(to top, ${formData?.primaryColor}, ${formData?.gradientColor})`
							: formData?.primaryColor,
						paddingTop: '50px',
						marginTop: '70px',
					}}
					className="d-flex justify-content-center align-items-center pb-4 px-4 rounded-3 flex-column position-relative"
				>
					<div className="px-10 position-absolute" style={{ top: '-84px' }}>
						<img
							className="w-100px h-100px mt-10 border border-3 border-white rounded-circle"
							src={
								image ||
								'https://divsly.com/app/assets/media/avatars/300-23.jpg'
							}
							alt="Avatar"
						/>
					</div>
					<div className="d-flex justify-content-center align-items-center px-10 flex-column text-center">
						<div
							className="fs-1 mb-1 mt-2 fw-bold text-white"
							style={{ textShadow: '1px 1px 1px #000' }}
						>
							{((firstName || 'Joy') + ' ' + (lastName || 'Doe')).trim()}
						</div>
						<p className="fs-5 text-gray-100">{job || 'Designer'}</p>
					</div>
					<div>
						<p className="fs-6 text-center text-white mb-0 py-5">
							{summary ||
								"Creative Software Developer with 5 years of experience in building responsive and engaging websites. Let's work together!"}
						</p>
					</div>
					<div className="btn-group row px-0 w-100 mt-5">
						<a
							href={`tel:${mobile}`}
							style={{
								color: formData?.primaryColor,
								borderRight: '1px solid',
								marginRight: '1px',
							}}
							className="col-4 btn btn-light d-flex justify-content-center align-items-center"
						>
							<i
								className="fas fa-phone"
								style={{ color: formData?.primaryColor }}
							/>
							<span>Call</span>
						</a>
						<a
							href={`mailto:${email}`}
							style={{ color: formData?.primaryColor, marginRight: '1px' }}
							className="col-4 btn btn-light d-flex justify-content-center align-items-center"
						>
							<i
								className="fas fa-envelope"
								style={{ color: formData?.primaryColor }}
							/>
							<span>Mail</span>
						</a>
						<a
							href={formData?.location}
							target="_blank"
							rel="noreferrer"
							style={{ color: formData?.primaryColor, borderLeft: '1px solid' }}
							className="col-4 btn btn-light d-flex justify-content-center align-items-center"
						>
							<i
								className="fas fa-location-dot"
								style={{ color: formData?.primaryColor }}
							/>
							<span>Maps</span>
						</a>
					</div>
				</div>
				<div className="bg-light position-relative mt-5">
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-phone fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Mobile</p>
							<span>{mobile || '11111111'}</span>
						</div>
					</div>
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-phone fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Phone</p>
							<span>{phone || '0000000'}</span>
						</div>
					</div>
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-envelope fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Email</p>
							<span>{email || 'Email@email.com'}</span>
						</div>
					</div>
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-briefcase fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Company</p>
							<span>{company || 'Company Name'}</span>
						</div>
					</div>
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-map-marker-alt fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Address</p>
							<span>{address || '123, street, city'}</span>
						</div>
					</div>
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-fax fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Fax</p>
							<span>{fax || 'fax'}</span>
						</div>
					</div>
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-globe fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Website</p>
							<span>{website || 'website'}</span>
						</div>
					</div>
					<div className="d-flex py-5 px-5 gap-5 align-items-center fs-6 p-3 mb-3">
						<span className="p-4 rounded-circle bg-white d-flex justify-content-center align-items-center">
							<i
								style={{ color: formData?.primaryColor }}
								className="fas fa-fax fs-3"
							></i>
						</span>
						<div>
							<p className="text-gray-600 mb-0 mt-1">Business Type</p>
							<span>{businessType || 'businessType'}</span>
						</div>
					</div>
					{/* Social Media */}
					<div className="d-flex gap-6 mb-3 py-4 justify-content-center align-items-center">
						{facebook && (
							<span
								className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
								style={{ backgroundColor: '#3F51B5' }}
							>
								<Link to={facebook || '#profile'} className="d-flex">
									<i className="fa-brands fs-2 fa-facebook-f text-white"></i>
								</Link>
							</span>
						)}
						{instagram && (
							<span
								className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
								style={{ backgroundColor: '#e84d40' }}
							>
								<Link to={instagram || '#profile'} className="d-flex">
									<i className="fa-brands fs-2 fa-instagram text-white"></i>
								</Link>
							</span>
						)}
						{google && (
							<span
								className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
								style={{ backgroundColor: '#e84d40' }}
							>
								<Link to={google || '#profile'} className="d-flex">
									<i className="fa-brands fs-2 fa-google text-white"></i>
								</Link>
							</span>
						)}
						{linkedin && (
							<span
								className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
								style={{ backgroundColor: '#0078D4' }}
							>
								<Link to={linkedin || '#profile'} className="d-flex">
									<i className="fa-brands fs-2 fa-linkedin-in text-white"></i>
								</Link>
							</span>
						)}
					</div>
				</div>
			</div>
			<FloatingBtn type={'vCard'} items={vCardItems} />
		</div>
	)
}

export default BcardPreview
