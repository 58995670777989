import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
	Button,
} from 'reactstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

// Replace these imports with your actual asset imports
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import socialicon from '../../../../assets/qr-icons/social-media.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import configicon from '../../../../assets/qr-icons/config.png'

// Custom components
import QrLink from '../QrLink'
import FileUpload from './components/fileUpload'
import SocialmediaPreview from '../qr-code-previews/socialmediapreview'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

// -----------------------------------------------------------------------------
// 1. BASIC INFORMATION
// -----------------------------------------------------------------------------
const BasicInformation = ({ formData, setFormData }) => {
	const handleFileUpload = (e) => {
		const file = e.target.files[0]
		if (file) {
			setFormData((prev) => ({ ...prev, logo: file }))
		}
	}

	const handleDeleteLogo = () => {
		setFormData((prev) => ({ ...prev, logo: null }))
	}

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<h3 className="text-md font-semibold mb-2">Basic Information</h3>
			<p className="text-sm text-gray-600 mb-3">Add essential information.</p>

			{/* Logo */}
			<div className="mb-4">
				<label className="text-sm text-gray-600 block mb-2">Logo</label>
				<div className="d-flex align-items-center gap-2">
					{formData.logo && (
						<img
							src={
								typeof formData.logo === 'string'
									? formData.logo
									: URL.createObjectURL(formData.logo)
							}
							alt="Logo"
							style={{
								width: '60px',
								height: '60px',
								objectFit: 'contain',
								borderRadius: '5px',
							}}
						/>
					)}
					<input
						type="file"
						accept="image/*"
						onChange={handleFileUpload}
						className="form-control"
					/>
					{formData.logo && (
						<Button color="primary" onClick={handleDeleteLogo}>
							Delete
						</Button>
					)}
				</div>
			</div>

			{/* Title */}
			<div className="mt-4">
				<label className="text-sm text-gray-600 block mb-2">Title *</label>
				<Input
					type="text"
					placeholder="Isabel Martinez"
					value={formData.title || ''}
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, title: e.target.value }))
					}
					className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
				/>
			</div>

			{/* Description */}
			<div className="mt-4">
				<label className="text-sm text-gray-600 block mb-2">Description</label>
				<Input
					type="text"
					placeholder="Writer"
					value={formData.description || ''}
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, description: e.target.value }))
					}
					className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
				/>
			</div>
		</div>
	)
}

// -----------------------------------------------------------------------------
// 2. CONTENT (Social Networks Settings)
// -----------------------------------------------------------------------------
const Content = ({ formData, setFormData }) => {
	const [socialNetworks, setSocialNetworks] = useState(
		formData.socialNetworks || [
			{ id: 'sn1', platform: 'instagram', url: '', text: '' },
			{ id: 'sn2', platform: 'facebook', url: '', text: '' },
			{ id: 'sn3', platform: 'twitter', url: '', text: '' },
			{ id: 'sn4', platform: 'linkedin', url: '', text: '' },
		]
	)

	const handleSocialChange = (index, field, value) => {
		const updatedSocialNetworks = [...socialNetworks]
		updatedSocialNetworks[index][field] = value
		setSocialNetworks(updatedSocialNetworks)
		setFormData((prev) => ({ ...prev, socialNetworks: updatedSocialNetworks }))
	}

	const addSocialNetwork = () => {
		const newSocialNetwork = {
			id: `sn${socialNetworks.length + 1}`,
			platform: '',
			url: '',
			text: '',
		}
		const updatedSocialNetworks = [...socialNetworks, newSocialNetwork]
		setSocialNetworks(updatedSocialNetworks)
		setFormData((prev) => ({ ...prev, socialNetworks: updatedSocialNetworks }))
	}

	const removeSocialNetwork = (index) => {
		const updatedSocialNetworks = socialNetworks.filter((_, i) => i !== index)
		setSocialNetworks(updatedSocialNetworks)
		setFormData((prev) => ({ ...prev, socialNetworks: updatedSocialNetworks }))
	}

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<h3 className="text-md font-semibold mb-2">Content</h3>
			<p className="text-sm text-gray-600 mb-3">
				All the details about your QR.
			</p>

			<h4 className="text-sm font-semibold mb-2">Social Networks</h4>
			{socialNetworks.map((social, index) => (
				<div key={social.id} className="mb-4">
					<div className="d-flex align-items-center gap-2">
						<FormGroup>
							<Label className="text-sm text-gray-600">Platform</Label>
							<Input
								type="select"
								value={social.platform}
								onChange={(e) =>
									handleSocialChange(index, 'platform', e.target.value)
								}
							>
								<option value="">Select Platform</option>
								<option value="instagram">Instagram</option>
								<option value="facebook">Facebook</option>
								<option value="twitter">Twitter</option>
								<option value="linkedin">LinkedIn</option>
								{/* Add more platforms as needed */}
							</Input>
						</FormGroup>
						<FormGroup className="flex-grow-1">
							<Label className="text-sm text-gray-600">URL / @ *</Label>
							<Input
								type="text"
								placeholder="https://instagram.com/"
								value={social.url}
								onChange={(e) =>
									handleSocialChange(index, 'url', e.target.value)
								}
								className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						</FormGroup>
						<Button color="danger" onClick={() => removeSocialNetwork(index)}>
							×
						</Button>
					</div>
					<FormGroup className="mt-2">
						<Label className="text-sm text-gray-600">Text</Label>
						<Input
							type="text"
							placeholder="Text"
							value={social.text}
							onChange={(e) =>
								handleSocialChange(index, 'text', e.target.value)
							}
							className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</FormGroup>
				</div>
			))}
			<Button color="primary" onClick={addSocialNetwork}>
				+ Add
			</Button>
		</div>
	)
}

// -----------------------------------------------------------------------------
// 3. LOCATION SECTION
// -----------------------------------------------------------------------------
const LocationSection = ({ formData, setFormData }) => {
	const [showManualEntry, setShowManualEntry] = useState(false)
	const [address, setAddress] = useState({
		street: '',
		number: '',
		postalCode: '',
		city: '',
		stateProvince: '',
		country: '',
	})

	const handleManualEntry = () => {
		setShowManualEntry(true)
	}

	const handleDeleteAddress = () => {
		setAddress({
			street: '',
			number: '',
			postalCode: '',
			city: '',
			stateProvince: '',
			country: '',
		})
		setFormData((prev) => ({ ...prev, location: null }))
	}

	const handleAddressChange = (field, value) => {
		const updatedAddress = { ...address, [field]: value }
		setAddress(updatedAddress)
		setFormData((prev) => ({ ...prev, location: updatedAddress }))
	}

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg mt-4">
			<h3 className="text-md font-semibold mb-2">Location</h3>
			<div className="d-flex gap-2 mb-3">
				<Button
					color="primary"
					active={formData.locationType === 'complete'}
					onClick={() =>
						setFormData((prev) => ({ ...prev, locationType: 'complete' }))
					}
				>
					Complete
				</Button>
				<Button
					color="secondary"
					active={formData.locationType === 'url'}
					onClick={() =>
						setFormData((prev) => ({ ...prev, locationType: 'url' }))
					}
				>
					Url
				</Button>
				<Button
					color="secondary"
					active={formData.locationType === 'coordinates'}
					onClick={() =>
						setFormData((prev) => ({ ...prev, locationType: 'coordinates' }))
					}
				>
					Coordinates
				</Button>
			</div>
			<FormGroup>
				<Label className="text-sm text-gray-600">Search address</Label>
				<div className="d-flex gap-2">
					<Input
						type="text"
						placeholder="Search address"
						value={formData.location?.search || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								location: { ...prev.location, search: e.target.value },
							}))
						}
						className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
					/>
					<Button color="primary" onClick={handleManualEntry}>
						Manual entry
					</Button>
				</div>
			</FormGroup>
			{showManualEntry && (
				<div>
					<FormGroup check className="mb-2">
						<Label check>
							<Input
								type="checkbox"
								checked={formData.streetNumberFirst || false}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										streetNumberFirst: e.target.checked,
									}))
								}
							/>
							Street number first
						</Label>
					</FormGroup>
					<div className="d-flex gap-2 mb-2">
						<FormGroup className="flex-grow-1">
							<Label className="text-sm text-gray-600">Street</Label>
							<Input
								type="text"
								value={address.street}
								onChange={(e) => handleAddressChange('street', e.target.value)}
								className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						</FormGroup>
						<FormGroup className="flex-grow-1">
							<Label className="text-sm text-gray-600">Number</Label>
							<Input
								type="text"
								value={address.number}
								onChange={(e) => handleAddressChange('number', e.target.value)}
								className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						</FormGroup>
						<FormGroup className="flex-grow-1">
							<Label className="text-sm text-gray-600">Postal Code</Label>
							<Input
								type="text"
								value={address.postalCode}
								onChange={(e) =>
									handleAddressChange('postalCode', e.target.value)
								}
								className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						</FormGroup>
					</div>
					<div className="d-flex gap-2 mb-2">
						<FormGroup className="flex-grow-1">
							<Label className="text-sm text-gray-600">City</Label>
							<Input
								type="text"
								value={address.city}
								onChange={(e) => handleAddressChange('city', e.target.value)}
								className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						</FormGroup>
						<FormGroup className="flex-grow-1">
							<Label className="text-sm text-gray-600">State / Province</Label>
							<Input
								type="text"
								value={address.stateProvince}
								onChange={(e) =>
									handleAddressChange('stateProvince', e.target.value)
								}
								className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						</FormGroup>
					</div>
					<FormGroup>
						<Label className="text-sm text-gray-600">Country</Label>
						<Input
							type="text"
							value={address.country}
							onChange={(e) => handleAddressChange('country', e.target.value)}
							className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</FormGroup>
					<Button color="danger" onClick={handleDeleteAddress} className="mt-2">
						Delete address
					</Button>
				</div>
			)}
		</div>
	)
}

// -----------------------------------------------------------------------------
// 4. IMAGES SECTION
// -----------------------------------------------------------------------------
const ImagesSection = ({ formData, setFormData }) => {
	const handleImageUpload = (e) => {
		const files = Array.from(e.target.files)
		setFormData((prev) => ({ ...prev, images: files }))
	}

	const handleViewTypeChange = (e) => {
		setFormData((prev) => ({ ...prev, viewType: e.target.value }))
	}

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg mt-4">
			<h3 className="text-md font-semibold mb-2">Images</h3>
			<div className="d-flex align-items-center gap-2 mb-3">
				<input
					type="file"
					accept="image/*"
					multiple
					onChange={handleImageUpload}
					className="form-control d-none"
					id="imageUpload"
				/>
				<label htmlFor="imageUpload" className="btn btn-primary">
					Upload Images
				</label>
				<span>Maximum size: 15MB</span>
			</div>
			<FormGroup>
				<Label className="text-sm text-gray-600">View Type</Label>
				<div className="d-flex gap-2">
					<label>
						<Input
							type="radio"
							name="viewType"
							value="horizontal"
							checked={formData.viewType === 'horizontal'}
							onChange={handleViewTypeChange}
						/>
						Horizontal
					</label>
					<label>
						<Input
							type="radio"
							name="viewType"
							value="vertical"
							checked={formData.viewType === 'vertical'}
							onChange={handleViewTypeChange}
						/>
						Vertical
					</label>
					<label>
						<Input
							type="radio"
							name="viewType"
							value="grid1"
							checked={formData.viewType === 'grid1'}
							onChange={handleViewTypeChange}
						/>
						Grid 1
					</label>
					<label>
						<Input
							type="radio"
							name="viewType"
							value="grid2"
							checked={formData.viewType === 'grid2'}
							onChange={handleViewTypeChange}
						/>
						Grid 2
					</label>
					<label>
						<Input
							type="radio"
							name="viewType"
							value="grid3"
							checked={formData.viewType === 'grid3'}
							onChange={handleViewTypeChange}
						/>
						Grid 3
					</label>
					<label>
						<Input
							type="radio"
							name="viewType"
							value="grid4"
							checked={formData.viewType === 'grid4'}
							onChange={handleViewTypeChange}
						/>
						Grid 4
					</label>
					<label>
						<Input
							type="radio"
							name="viewType"
							value="grid5"
							checked={formData.viewType === 'grid5'}
							onChange={handleViewTypeChange}
						/>
						Grid 5
					</label>
				</div>
			</FormGroup>
		</div>
	)
}

// -----------------------------------------------------------------------------
// 5. CONTACT INFO SECTION
// -----------------------------------------------------------------------------
const ContactInfoSection = ({ formData, setFormData }) => {
	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg mt-4">
			<h3 className="text-md font-semibold mb-2">Contact Info</h3>
			<FormGroup>
				<Label className="text-sm text-gray-600">Telephone</Label>
				<Input
					type="text"
					placeholder=""
					value={formData.telephone || ''}
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, telephone: e.target.value }))
					}
					className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
				/>
			</FormGroup>
			<FormGroup>
				<Label className="text-sm text-gray-600">Email</Label>
				<Input
					type="email"
					placeholder="isabel@myemail.com"
					value={formData.email || ''}
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, email: e.target.value }))
					}
					className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
				/>
			</FormGroup>
			<FormGroup>
				<Label className="text-sm text-gray-600">Website</Label>
				<Input
					type="text"
					placeholder="https://qrfy.com/"
					value={formData.website || ''}
					onChange={(e) =>
						setFormData((prev) => ({ ...prev, website: e.target.value }))
					}
					className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
				/>
			</FormGroup>
		</div>
	)
}

// -----------------------------------------------------------------------------
// 6. MAIN FORM COMPONENT (Accordion Structure)
// -----------------------------------------------------------------------------
const SocialmediaForm = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setShowPreview,
}) => {
	const [open, setOpen] = useState('0')
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)
	const toggle = (id) => {
		setOpen(open === id ? '' : id)
	}

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center flex-wrap">
					{/* Primary Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>

					{/* Button Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '2',
			icon: infoicon,
			title: 'Basic Information',
			content: (
				<BasicInformation formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '3',
			icon: contenticon,
			title: 'Content',
			content: <Content formData={formData} setFormData={setFormData} />,
		},
		{
			id: '4',
			icon: contenticon,
			title: 'Location',
			content: (
				<LocationSection formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '5',
			icon: contenticon,
			title: 'Images',
			content: <ImagesSection formData={formData} setFormData={setFormData} />,
		},
		{
			id: '6',
			icon: contenticon,
			title: 'Contact Info',
			content: (
				<ContactInfoSection formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '8',
			icon: contenticon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							// setShowPreview={setShowPreview}
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '7',
			icon: configicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
			{accordionItems.map((item) => (
				<AccordionItem key={item.id}>
					<AccordionHeader targetId={item.id}>
						<img src={item.icon} alt={item.title} className="w-30px me-4" />
						{item.title}
					</AccordionHeader>
					<AccordionBody accordionId={item.id}>{item.content}</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

// -----------------------------------------------------------------------------
// 7. WRAPPER COMPONENT
// -----------------------------------------------------------------------------
const Socialmedia = ({ isEdit = false, qrType = 'socialmedia' }) => {
	const [formData, setFormData] = useState({
		// Basic Info
		logo: null,
		title: '',
		description: '',
		// Content (Social Networks)
		socialNetworks: [
			{ id: 'sn1', platform: 'instagram', url: '', text: '' },
			{ id: 'sn2', platform: 'facebook', url: '', text: '' },
			{ id: 'sn3', platform: 'twitter', url: '', text: '' },
			{ id: 'sn4', platform: 'linkedin', url: '', text: '' },
		],
		// Location
		locationType: 'complete',
		location: { search: '' },
		streetNumberFirst: false,
		// Images
		images: [],
		viewType: 'horizontal',
		// Contact Info
		telephone: '',
		email: '',
		website: '',
		// Design
		primaryColor: '#ffffff',
		ctaColor: '#348ce5',
		// Password Protection (for PasswordSection compatibility)
		passwordProtectionEnabled: false,
		password: '',
	})

	return (
		<div style={{ display: 'flex', gap: '20px' }}>
			{/* Form Section */}
			<div style={{ flex: 1 }}>
				<SocialmediaForm
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			</div>
			{/* Preview Section */}
			<div style={{ flex: 1, border: '1px solid #ccc', padding: '10px' }}>
				<SocialmediaPreview formData={formData} />
			</div>
		</div>
	)
}

export default SocialmediaForm
