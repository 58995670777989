import React, { useState, useEffect } from 'react'
import {
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	Card,
	CardBody,
	CardHeader,
	ListGroup,
	ListGroupItem,
} from 'reactstrap'

const OAuthAppRegistration = ({ onClose = () => {}, onSuccess = () => {} }) => {
	const [formData, setFormData] = useState({
		applicationName: '',
		applicationLink: '',
		redirectURIs: [],
		applicationDescription: '',
	})
	const [registeredApps, setRegisteredApps] = useState([])
	const [newRedirectURI, setNewRedirectURI] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [isSubmitting, setIsSubmitting] = useState(false)

	const fetchRegisteredApps = async () => {
		try {
			setIsLoading(true)
			const token = localStorage?.getItem('auth_token')
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/app-registrations`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			const data = await response.json()
			setRegisteredApps(data?.registrations)
		} catch (error) {
			console.error('Error fetching registered apps:', error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		const token = localStorage?.getItem('auth_token')
		if (token) {
			fetchRegisteredApps()
		}
	}, [])

	const handleChange = (e) => {
		const { id, value } = e.target
		setFormData((prev) => ({ ...prev, [id]: value }))
	}

	const handleRedirectURIChange = (e) => {
		setNewRedirectURI(e.target.value)
	}

	const addRedirectURI = () => {
		if (
			newRedirectURI.trim() !== '' &&
			!formData.redirectURIs.includes(newRedirectURI)
		) {
			setFormData((prev) => ({
				...prev,
				redirectURIs: [...prev.redirectURIs, newRedirectURI.trim()],
			}))
			setNewRedirectURI('') // Clear input after adding
		}
	}

	const removeRedirectURI = (uri) => {
		setFormData((prev) => ({
			...prev,
			redirectURIs: prev.redirectURIs.filter((item) => item !== uri),
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setIsSubmitting(true)

		try {
			const token = localStorage?.getItem('auth_token')
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/register-app`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						...formData,
						redirectURIs: formData.redirectURIs,
					}),
				}
			)

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			const data = await response.json()
			console.log('Success:', data)

			// Call the success callback
			await onSuccess()

			// Close the modal
			onClose()

			// Reset the form
			handleCancel()
		} catch (error) {
			console.error('Error submitting form:', error)
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleCancel = () => {
		setFormData({
			applicationName: '',
			applicationLink: '',
			redirectURIs: [],
			applicationDescription: '',
		})
		setNewRedirectURI('')
	}

	return (
		<>
			{isSubmitting && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 9999,
					}}
				>
					<div className="text-center text-white">
						<i className="fas fa-spinner fa-spin fa-3x mb-3"></i>
						<p className="mt-2">Registering application...</p>
					</div>
				</div>
			)}
			<Container fluid className="p-4">
				<Row className="g-4">
					<Col md={12} className="d-flex">
						<Card className="w-100">
							<CardHeader>
								<h2 className="mb-0">Register OAuth Application</h2>
							</CardHeader>
							<CardBody className="overflow-auto">
								<p>
									For documentation on using Divsly OAuth, visit the{' '}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://dev.bitly.com/docs/getting-started/authentication"
										className="text-primary"
									>
										API Documentation
									</a>
									.
								</p>

								<Form onSubmit={handleSubmit}>
									{/* Application Name */}
									<FormGroup>
										<Label for="applicationName">
											Application Name (required)
										</Label>
										<Input
											id="applicationName"
											type="text"
											value={formData.applicationName}
											onChange={handleChange}
										/>
										<small className="text-muted">
											Please provide a unique name for your application. This
											value will be displayed to users when they authenticate.
										</small>
									</FormGroup>

									{/* Application Link */}
									<FormGroup>
										<Label for="applicationLink">
											Application Link (required)
										</Label>
										<Input
											id="applicationLink"
											type="text"
											value={formData.applicationLink}
											onChange={handleChange}
										/>
										<small className="text-muted">
											The URL of your application. If it's a root domain or
											directory, ensure it ends with a forward slash.
											<br />
											Example: <code>http://your-app.com/</code>
										</small>
									</FormGroup>

									{/* Application Description */}
									<FormGroup>
										<Label for="applicationDescription">
											Application Description
										</Label>
										<Input
											id="applicationDescription"
											type="textarea"
											rows="3"
											placeholder="300 character max"
											value={formData.applicationDescription}
											onChange={handleChange}
										/>
									</FormGroup>

									{/* Buttons */}
									<Row className="mt-4">
										<Col>
											<Button
												color="secondary"
												type="button"
												onClick={handleCancel}
											>
												Cancel
											</Button>
										</Col>
										<Col className="text-end">
											<Button
												color="primary"
												type="submit"
												disabled={isSubmitting}
											>
												{isSubmitting ? (
													<>
														<i className="fas fa-spinner fa-spin me-2"></i>
														Saving...
													</>
												) : (
													'Save'
												)}
											</Button>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default OAuthAppRegistration
