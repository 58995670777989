import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
	Button,
} from 'reactstrap'

// Replace these imports with your actual asset imports
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import configicon from '../../../../assets/qr-icons/config.png'
import linkicon from '../../../../assets/qr-icons/link.png'

// Custom components
import QrLink from '../QrLink'
import EmailPreview from '../qr-code-previews/emailpreview'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

// -----------------------------------------------------------------------------
// 1. BASIC INFORMATION
// -----------------------------------------------------------------------------
const BasicInformation = ({ formData, setFormData }) => {
	const handleEmailChange = (e) => {
		setFormData((prev) => ({ ...prev, email: e.target.value }))
	}

	const handleSubjectChange = (e) => {
		setFormData((prev) => ({ ...prev, subject: e.target.value }))
	}

	const handleMessageChange = (e) => {
		setFormData((prev) => ({ ...prev, message: e.target.value }))
	}

	return (
		<div className="w-full max-w-lg">
			{/* Email Information */}
			<div className="mb-4">
				<p className="text-sm text-gray-600 mb-2">Email information</p>
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Email *</Label>
					<Input
						type="email"
						placeholder="E.g. example@email.com"
						value={formData.email || ''}
						onChange={handleEmailChange}
						className="form-control"
						required
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Subject *</Label>
					<Input
						type="text"
						placeholder="E.g. Hello from QR Code"
						value={formData.subject || ''}
						onChange={handleSubjectChange}
						className="h-50px form-control"
						required
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Message *</Label>
					<Input
						type="textarea"
						placeholder="E.g. This is an email message"
						value={formData.message || ''}
						onChange={handleMessageChange}
						className="form-control"
						rows="4"
						required
					/>
				</FormGroup>
			</div>
		</div>
	)
}

// -----------------------------------------------------------------------------
// 2. MAIN FORM COMPONENT (Accordion Structure)
// -----------------------------------------------------------------------------
const EmailForm = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setShowPreview,
}) => {
	const [open, setOpen] = useState('0')
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)

	const toggle = (id) => {
		setOpen(open === id ? '' : id)
	}

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center flex-wrap">
					{/* Primary Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>

					{/* Button Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										ctaColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '2',
			icon: infoicon,
			title: 'Basic Information',
			content: (
				<BasicInformation formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '6',
			icon: contenticon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							// setShowPreview={setShowPreview}
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '11',
			icon: configicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
			{accordionItems.map((item) => (
				<AccordionItem key={item.id}>
					<AccordionHeader targetId={item.id}>
						<img src={item.icon} alt={item.title} className="w-30px me-4" />
						{item.title}
					</AccordionHeader>
					<AccordionBody accordionId={item.id}>{item.content}</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

// -----------------------------------------------------------------------------
// 3. WRAPPER COMPONENT
// -----------------------------------------------------------------------------
const EmailQr = ({ isEdit = false, qrType = 'email' }) => {
	const [formData, setFormData] = useState({
		// Basic Info
		email: '',
		subject: '',
		message: '',
		// Design
		primaryColor: '#ffffff',
		ctaColor: '#348ce5',
		// Optional: Configuration (Password Protection)
		enablePassword: false,
		password: '',
	})

	console.log('formData in EmailQr:', formData)

	return (
		<div style={{ display: 'flex', gap: '20px' }}>
			{/* Form Section */}
			<div style={{ flex: 1 }}>
				<EmailForm
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			</div>
			{/* Preview Section */}
			<div style={{ flex: 1, border: '1px solid #ccc', padding: '10px' }}>
				<EmailPreview formData={formData} />
			</div>
		</div>
	)
}

export default EmailForm
