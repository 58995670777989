import React from 'react'

// Reusable helper to handle File objects or string URLs
const getImageURL = (fileOrUrl) => {
	if (!fileOrUrl) return null
	return typeof fileOrUrl === 'string'
		? fileOrUrl
		: URL.createObjectURL(fileOrUrl)
}

const ProductPreview = ({ formData }) => {
	// DESIGN KEYS (now top-level)
	const {
		primaryColor = '#ffffff',
		ctaColor = '#348ce5', // used for buttons
	} = formData

	// Provide defaults for additional styling if needed
	const textColor = '#000000'
	const titleColor = '#000000'
	const titleFont = 'sans-serif'
	const textFont = 'sans-serif'
	const navbarColor = '#000000'

	const containerStyle = {
		backgroundColor: primaryColor,
		color: textColor,
		fontFamily: textFont,
		minHeight: '100vh',
		padding: '20px',
	}

	const navStyle = {
		backgroundColor: navbarColor,
		color: '#fff',
		padding: '10px',
		marginBottom: '20px',
		textAlign: 'center',
		fontFamily: titleFont,
	}

	/**
	 * 2. BASIC INFORMATION
	 */
	const {
		image,
		title,
		description,
		header,
		typeOfWine,
		varieties,
		add,
		region,
		countryOfOrigin,
		store,
		geographicalIndication,
		alcohol,
		amount,
		categories = [],
	} = formData.basicInfo || {}

	const productImageURL = getImageURL(image)

	/**
	 * 3. CONTENT
	 */
	const {
		ingredients = [],
		nutritionalInfo = {},
		moreInformation = {},
		welcomeScreen = {},
	} = formData.content || {}

	const { qualification = '', nutrients = [] } = nutritionalInfo
	const {
		additionalDetails = '',
		allergens = [],
		certificates = [],
		organic = [],
		recyclingStamps = [],
	} = moreInformation

	const { image: welcomeImageFile, time: welcomeTime = 2.5 } = welcomeScreen
	const welcomeImageURL = getImageURL(welcomeImageFile)

	/**
	 * 4. CONFIGURATION
	 */
	const { passwordActive = false, password = '' } = formData.configuration || {}

	return (
		<div style={containerStyle}>
			{/* NAVBAR (Optional) */}
			<div style={navStyle}>
				<h2 style={{ margin: 0 }}>My Product QR Preview</h2>
			</div>

			{/* BASIC INFO */}
			<section>
				<h1
					style={{
						color: titleColor,
						fontFamily: titleFont,
						marginBottom: '10px',
					}}
				>
					{title || 'Product Title'}
				</h1>
				{productImageURL && (
					<img
						src={productImageURL}
						alt="Product"
						style={{
							maxWidth: '200px',
							display: 'block',
							marginBottom: '10px',
						}}
					/>
				)}
				{header && <h3>{header}</h3>}
				{description && <p>{description}</p>}
				<p>
					<strong>Type of Wine:</strong> {typeOfWine}
				</p>
				<p>
					<strong>Varieties:</strong> {varieties}
				</p>
				<p>
					<strong>Add:</strong> {add}
				</p>
				<p>
					<strong>Region:</strong> {region}
				</p>
				<p>
					<strong>Country of Origin:</strong> {countryOfOrigin}
				</p>
				<p>
					<strong>Store:</strong> {store}
				</p>
				<p>
					<strong>Geographical Indication:</strong> {geographicalIndication}
				</p>
				<p>
					<strong>Alcohol:</strong> {alcohol}
				</p>
				<p>
					<strong>Amount:</strong> {amount}
				</p>

				{categories.length > 0 && (
					<div style={{ marginTop: '10px' }}>
						<h4>Categories:</h4>
						<ul>
							{categories.map((cat, idx) => (
								<li key={idx}>
									<strong>{cat.category}:</strong> {cat.categoryValue}
								</li>
							))}
						</ul>
					</div>
				)}
			</section>

			{/* CONTENT */}
			<section style={{ marginTop: '30px' }}>
				<h2>Content</h2>

				{/* Ingredients */}
				<div style={{ marginTop: '15px' }}>
					<h4>Ingredients</h4>
					{ingredients.length === 0 ? (
						<p>No ingredients specified.</p>
					) : (
						<ul>
							{ingredients.map((ing, idx) => (
								<li key={idx}>{ing.name || '(Unnamed ingredient)'}</li>
							))}
						</ul>
					)}
				</div>

				{/* Nutritional Info */}
				<div style={{ marginTop: '15px' }}>
					<h4>Nutritional Information</h4>
					{qualification && <p>Qualification: {qualification}</p>}
					{nutrients.length === 0 ? (
						<p>No nutritional info specified.</p>
					) : (
						<ul>
							{nutrients.map((nut, idx) => (
								<li key={idx}>
									<strong>{nut.nutrient}</strong>: {nut.value}
								</li>
							))}
						</ul>
					)}
				</div>

				{/* More Information */}
				<div style={{ marginTop: '15px' }}>
					<h4>More Information</h4>
					{additionalDetails && <p>{additionalDetails}</p>}
					{/* Allergens */}
					<div style={{ marginTop: '10px' }}>
						<h5>Allergens</h5>
						<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
							{allergens
								.filter((all) => all.selected)
								.map((all) => (
									<div key={all.id} style={{ textAlign: 'center' }}>
										<img
											src={all.icon}
											alt={all.name}
											style={{ width: 40, height: 40 }}
										/>
										<div style={{ fontSize: '0.8rem' }}>{all.name}</div>
									</div>
								))}
							{allergens.every((all) => !all.selected) && (
								<p style={{ margin: 0 }}>No allergens selected.</p>
							)}
						</div>
					</div>

					{/* Certificates */}
					<div style={{ marginTop: '10px' }}>
						<h5>Certificates</h5>
						{certificates.length === 0 ? (
							<p>No certificates added.</p>
						) : (
							<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
								{certificates.map((cert) => (
									<div
										key={cert.id}
										style={{
											border: '1px solid #ccc',
											borderRadius: '4px',
											padding: '4px',
											textAlign: 'center',
										}}
									>
										<img
											src={cert.icon}
											alt="Certificate"
											style={{ width: 40, height: 40 }}
										/>
									</div>
								))}
							</div>
						)}
					</div>

					{/* Organic */}
					<div style={{ marginTop: '10px' }}>
						<h5>Organic</h5>
						{organic.length === 0 ? (
							<p>No organic icons added.</p>
						) : (
							<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
								{organic.map((org) => (
									<div
										key={org.id}
										style={{
											border: '1px solid #ccc',
											borderRadius: '4px',
											padding: '4px',
											textAlign: 'center',
										}}
									>
										<img
											src={org.icon}
											alt="Organic"
											style={{ width: 40, height: 40 }}
										/>
									</div>
								))}
							</div>
						)}
					</div>

					{/* Recycling Stamps */}
					<div style={{ marginTop: '10px' }}>
						<h5>Recycling Stamps</h5>
						{recyclingStamps.length === 0 ? (
							<p>No recycling stamps added.</p>
						) : (
							<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
								{recyclingStamps.map((stamp) => (
									<div
										key={stamp.id}
										style={{
											border: '1px solid #ccc',
											borderRadius: '4px',
											padding: '4px',
											textAlign: 'center',
										}}
									>
										<img
											src={stamp.icon}
											alt="Recycle"
											style={{ width: 40, height: 40 }}
										/>
									</div>
								))}
							</div>
						)}
					</div>
				</div>

				{/* Welcome Screen */}
				<div style={{ marginTop: '15px' }}>
					<h4>Welcome Screen</h4>
					{welcomeImageURL && (
						<img
							src={welcomeImageURL}
							alt="Welcome Screen"
							style={{ maxWidth: '200px', borderRadius: '10px' }}
						/>
					)}
					<p>
						Display Time: <strong>{welcomeTime} sec</strong>
					</p>
				</div>
			</section>

			{/* CONFIGURATION */}
			<section style={{ marginTop: '30px' }}>
				<h2>Configuration</h2>
				{passwordActive ? (
					<p>
						<strong>Password Protected:</strong> Yes
						<br />
						(Password: <em>{password || 'not set'}</em>)
					</p>
				) : (
					<p>Password protection is disabled.</p>
				)}
			</section>
		</div>
	)
}

export default ProductPreview
