// AppPreview.js
import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const AppPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	// Helper function to get a URL for file objects or return string URLs directly
	const getImageURL = (fileOrUrl) => {
		if (!fileOrUrl) return null
		return typeof fileOrUrl === 'string'
			? fileOrUrl
			: URL.createObjectURL(fileOrUrl)
	}

	// Get the URL for the uploaded app logo (if any)
	const appLogoURL = getImageURL(formData.appLogo)

	// For styling
	const backgroundColor = formData.primaryColor || '#fff'
	const textColor = '#000' // or use formData.ctaColor if you want text to match CTA color
	const buttonColor = formData.ctaColor || '#348ce5'

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div
			style={{
				backgroundColor: backgroundColor,
				color: textColor,
				padding: '20px',
				minHeight: '100vh',
			}}
		>
			{/* Header: App Logo + App Name + Developer/Company */}
			<header style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
				{appLogoURL && (
					<img
						src={appLogoURL}
						alt="App Logo"
						style={{
							width: '60px',
							height: '60px',
							objectFit: 'contain',
							borderRadius: '5px',
						}}
					/>
				)}
				<div>
					<h1 style={{ margin: 0 }}>{formData.appName || 'Your App Name'}</h1>
					<p style={{ margin: 0 }}>
						{formData.developerCompany || 'Developer/Company'}
					</p>
				</div>
			</header>

			{/* Description & Website */}
			<section style={{ marginTop: '20px' }}>
				<p>{formData.description || 'Your description goes here...'}</p>
				{formData.website && (
					<p>
						<strong>Website:</strong>{' '}
						<a
							href={formData.website}
							target="_blank"
							rel="noopener noreferrer"
							style={{ color: buttonColor }}
						>
							{formData.website}
						</a>
					</p>
				)}
			</section>

			{/* Store Links */}
			<section style={{ marginTop: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Download Links</h3>
				<div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
					{/* Google Play */}
					{formData.googlePlayLink && (
						<a
							href={formData.googlePlayLink}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								backgroundColor: buttonColor,
								color: '#fff',
								padding: '10px 20px',
								textDecoration: 'none',
								borderRadius: '5px',
							}}
						>
							{formData.googlePlayText || 'Get it on Google Play'}
						</a>
					)}

					{/* Apple Store */}
					{formData.appleStoreLink && (
						<a
							href={formData.appleStoreLink}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								backgroundColor: buttonColor,
								color: '#fff',
								padding: '10px 20px',
								textDecoration: 'none',
								borderRadius: '5px',
							}}
						>
							{formData.appleStoreText || 'Download on the App Store'}
						</a>
					)}

					{/* Amazon Appstore */}
					{formData.amazonLink && (
						<a
							href={formData.amazonLink}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								backgroundColor: buttonColor,
								color: '#fff',
								padding: '10px 20px',
								textDecoration: 'none',
								borderRadius: '5px',
							}}
						>
							{formData.amazonText || 'Available on Amazon'}
						</a>
					)}
				</div>
			</section>
		</div>
	)
}

export default AppPreview
