import React, { useCallback, useEffect, useState } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { Controller } from 'react-hook-form'
import Button from 'react-bootstrap/Button'
import { generateRandomString } from '../../../utils/generateRandomString'
import titleFetcher from '../../../utils/titleFetcher'
import _debounce from 'lodash/debounce'
import createUrlWithParams from '../../../utils/createUrlWithParams'
import formatAndValidateUrl from '../../../utils/formatAndValidateUrl'

const DestinationURL = ({ form, setIsURLParamBuilderOpen }) => {
	const [disable, setDisable] = useState(true)
	const {
		control,
		setValue,
		getValues,
		trigger,
		formState: { errors, defaultValues },
	} = form

	const [destinationValue, setDestinationValue] = useState('')

	const utmParamsHandler = (url) => {
		try {
			// extract UTM parameters from url
			const urlParams = new URLSearchParams(new URL(url).search)
			setValue('utm_source', urlParams.get('utm_source') || '')
			setValue('utm_medium', urlParams.get('utm_medium') || '')
			setValue('utm_campaign', urlParams.get('utm_campaign') || '')
			setValue('utm_id', urlParams.get('utm_id') || '')
			setValue('utm_term', urlParams.get('utm_term') || '')
			setValue('utm_content', urlParams.get('utm_content') || '')
		} catch (e) {}
	}

	const handleTitle = useCallback(
		_debounce(async (url) => {
			try {
				const title = await titleFetcher(url)
				setValue('title', title.title)
				setValue('metaDescription', title.metaDescription)
				trigger('destinationUrl')
				const isDisable = formatAndValidateUrl(url) && title ? false : true
				!isDisable && utmParamsHandler(url)
				setDisable(isDisable)
			} catch (e) {
				console.log('Error while fetching title: ', e.message)
			}
		}, 500),
		[setValue]
	)

	const handleUrlChange = async (e) => {
		const url = e.target.value
		if (!url) {
			setValue('url', '')
			setDisable(true)
			setDestinationValue('')
			return
		}
		setValue('title', '')
		setValue('slashTag', generateRandomString(url))
		handleTitle(url)
		setValue('destinationUrl', url)
		trigger('destinationUrl')
		trigger('slashTag')
	}

	useEffect(() => {
		if (defaultValues.destinationUrl) {
			const url = createUrlWithParams(defaultValues.destinationUrl, {
				utm_source: defaultValues.utm_source,
				utm_medium: defaultValues.utm_medium,
				utm_campaign: defaultValues.utm_campaign,
				utm_id: defaultValues.utm_id,
				utm_term: defaultValues.utm_term,
				utm_content: defaultValues.utm_content,
			})

			setValue('url', url)
			setDisable(false)
			setDestinationValue(url)
		}
	}, [defaultValues])

	return (
		<FormGroup>
			<Label className="required fs-5 fw-bold mb-2">Destination URL</Label>
			<div className="input-group mb-7 w-100">
				<div className="d-flex w-100">
					<Controller
						className="w-100"
						name="url"
						control={control}
						render={({ field }) => {
							return (
								<div className="w-100">
									<div
										style={{
											position: 'relative',
											display: 'inline-block',
											width: '100%',
										}}
									>
										<Input
											{...field}
											type="link"
											style={{
												borderRadius: '5px 0px 0px 5px',
												paddingRight: '30px',
											}}
											placeholder="Enter your destination URL"
											onChange={(e) => {
												field.onChange(e)
												handleUrlChange(e)
												setDestinationValue(e.target.value)
											}}
											className="w-100 h-50px"
										/>
										{destinationValue && (
											<i
												className={`fa ${errors.destinationUrl ? 'fa-times' : 'fa-check'}`}
												style={{
													position: 'absolute',
													right: '10px',
													top: '50%',
													transform: 'translateY(-50%)',
													fontSize: '16px',
													color: errors.destinationUrl ? 'red' : 'green',
												}}
											></i>
										)}
									</div>
									{errors.destinationUrl ? (
										<span className="error-text text-danger">
											{errors.destinationUrl.message}
										</span>
									) : null}
								</div>
							)
						}}
					/>
					<Button
						disabled={disable}
						className="border btn btn-light-info d-flex fw-bolder align-items-center border-gray-300 h-50px border-left-0"
						style={{
							borderRadius: '0px 5px 5px 0px',
						}}
						onClick={() => setIsURLParamBuilderOpen(true)}
					>
						UTM
					</Button>
				</div>
			</div>
		</FormGroup>
	)
}

export default DestinationURL
