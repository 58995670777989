import React, { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	Input,
	FormGroup,
	Label,
	Form,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import socialicon from '../../../../assets/qr-icons/social-media.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import configicon from '../../../../assets/qr-icons/config.png'
import contenticon from '../../../../assets/qr-icons/content.png' // Added for Welcome Screen
import QrLink from '../QrLink'
import getCroppedImg from './cropImage'
import './Crop.css'
import defaultimg from '../../../../assets/qr-logos/default.png'
import { domainPatterns } from '../../SocialMediaPatterns'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings' // Added import
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const Vcard = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setIsInvalid,
	setShowPreview,
}) => {
	const [errors, setErrors] = useState({})
	const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	useEffect(() => {
		if (!formData?.title) setIsInvalid(true)
		else setIsInvalid(false)
	}, [formData])
	const [open, setOpen] = useState('0')
	const [imageSrc, setImageSrc] = useState(null)
	const [cropping, setCropping] = useState(false)
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	// const [showPreview, setShowPreview] = useState(false) // Added for animation preview
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // For AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	) // For AnimationSettings

	const toggle = (id) => {
		open === id ? setOpen('') : setOpen(id)
	}

	// Handle profile image change for cropping
	const handleImageChange = async (e) => {
		const file = e.target.files[0]
		if (file) {
			const imageDataUrl = await readFile(file)
			setImageSrc(imageDataUrl)
			setCropping(true)
			setFormData((prevData) => ({
				...prevData,
				file,
			}))
		}
	}

	// Handle welcome screen image change
	const handleWelcomeImageChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
					animationDirection: animationDirection, // Save animation settings
					contentWelcomeTime: contentWelcomeTime,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const handleSaveCroppedImage = async () => {
		try {
			const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
			setFormData((prevData) => ({
				...prevData,
				image: croppedImage,
			}))
			setCropping(false)
		} catch (error) {
			console.error('Error cropping the image', error)
		}
	}

	const readFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader()
			reader.onload = () => resolve(reader.result)
			reader.readAsDataURL(file)
		})
	}

	const handleInputChange = (e) => {
		const { id, value } = e.target

		// Special handling for phone-related fields
		if (id === 'mobile' || id === 'phone' || id === 'fax') {
			// Allow numbers and plus sign only at start
			const sanitizedValue = value.replace(/[^0-9+]/g, '')
			// Only allow plus sign at the beginning
			const formattedValue = sanitizedValue.replace(/\+/g, (match, offset) =>
				offset === 0 ? match : ''
			)

			setFormData((prevData) => ({
				...prevData,
				[id]: formattedValue,
			}))

			// Only validate if there's a value (since these fields are optional)
			if (formattedValue.length > 0) {
				const phoneNumber = parsePhoneNumberFromString(formattedValue)
				if (!phoneNumber?.isValid()) {
					setErrors((prev) => ({
						...prev,
						[id]: 'Please enter a valid phone number with country code (e.g., +1 for US)',
					}))
				} else {
					setErrors((prev) => {
						const newErrors = { ...prev }
						delete newErrors[id]
						return newErrors
					})
				}
			} else {
				// Remove error if field is empty
				setErrors((prev) => {
					const newErrors = { ...prev }
					delete newErrors[id]

					return newErrors
				})
			}
			return
		}

		// Special handling for email
		if (id === 'email') {
			setFormData((prevData) => ({
				...prevData,
				[id]: value,
			}))

			if (value && !regexEmail.test(value)) {
				setErrors((prev) => ({
					...prev,
					[id]: 'Please enter a valid email address',
				}))
			} else {
				setErrors((prev) => ({
					...prev,
					[id]: '',
				}))
			}
			return
		}

		// Original handling for other fields
		setFormData((prevData) => {
			const updatedData = { ...prevData, [id]: value }

			if (id === 'address') {
				const query = updatedData.address
				const encodedQuery = encodeURIComponent(query.trim())
				const url = `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`
				console.log(url)
				updatedData['location'] = url
			}
			return updatedData
		})

		if (domainPatterns[id] && value && !domainPatterns[id].test(value)) {
			setErrors((prev) => ({
				...prev,
				[id]: `Please enter a valid ${id} URL.`,
			}))
		} else {
			setErrors({ ...errors, [id]: '' })
		}
	}

	useEffect(() => {
		if (Object.keys(errors).length === 0) {
			setIsInvalid(false)
		} else {
			setIsInvalid(true)
		}
	}, [errors])

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Customize Preview',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center">
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.primaryColor}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
					<FormGroup className="mt-10" check>
						<Label check>
							<Input
								type="checkbox"
								checked={formData?.gradient}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										gradient: e.target.checked,
									}))
								}
							/>{' '}
							Enable Gradient
						</Label>
					</FormGroup>
					{formData?.gradient && (
						<FormGroup>
							<Label className="fs-4 fw-semibold mb-4">Gradient Color</Label>
							<div className="color-picker-wrapper">
								<Input
									type="color"
									className="color-picker rounded-0 cursor-pointer"
									value={formData?.gradientColor}
									onChange={(e) =>
										setFormData((prev) => ({
											...prev,
											gradientColor: e.target.value,
										}))
									}
								/>
								<Input
									className="hex-input form-control rounded-0"
									type="text"
									maxLength="7"
									value={formData?.gradientColor}
									onChange={(e) =>
										setFormData((prev) => ({
											...prev,
											gradientColor: e.target.value,
										}))
									}
									placeholder="#000000"
								/>
							</div>
						</FormGroup>
					)}
				</div>
			),
		},
		{
			id: '2',
			icon: infoicon,
			title: 'Your Information',
			content: (
				<>
					<div>
						<p className="fs-5 fw-bold mb-0">Profile</p>
					</div>
					<div className="mt-3 pe-8 mb-6">
						<div
							className="image-input image-input-outline"
							style={{
								backgroundImage: `url(${formData?.image || defaultimg})`,
							}}
						>
							<div
								className="image-input-wrapper w-125px h-125px shadow-none border border-dark border-opacity-10"
								style={{
									backgroundImage: `url(${formData?.image || defaultimg})`,
								}}
							></div>
							<Label
								className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body position-absolute border border-dark border-opacity-10"
								style={{ right: '3px', top: '3px' }}
							>
								<i className="fas fa-pencil fs-6"></i>
								<Input
									className="d-none"
									type="file"
									name="image"
									accept=".png, .jpg, .jpeg"
									onChange={handleImageChange}
								/>
							</Label>
						</div>
					</div>
				</>
			),
			fields: [
				{
					id: 'firstName',
					label: 'First Name',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'lastName',
					label: 'Last Name',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'mobile',
					label: 'Mobile',
					type: 'tel',
					colSize: 6,
					className: 'h-50px',
					placeholder: '+1234567890',
				},
				{
					id: 'phone',
					label: 'Phone',
					type: 'tel',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'fax',
					label: 'Fax',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'email',
					label: 'Email',
					type: 'email',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'company',
					label: 'Company',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'job',
					label: 'Job',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{ id: 'address', label: 'Address', type: 'text', className: 'h-50px' },
				{
					id: 'website',
					label: 'Website',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'businessType',
					label: 'Business Type',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{ id: 'summary', label: 'Summary', type: 'textarea', maxLength: 200 },
			],
		},
		{
			id: '3',
			icon: socialicon,
			title: 'Social Media',
			fields: [
				{
					id: 'facebook',
					label: 'Facebook URL',
					placeholder: 'https://www.facebook.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'instagram',
					label: 'Instagram URL',
					placeholder: 'https://www.instagram.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'google',
					label: 'Google URL',
					placeholder: 'https://www.google.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'linkedin',
					label: 'LinkedIn URL',
					placeholder: 'https://www.linkedin.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
			],
		},
		{
			id: '4', // Positioned just above Password
			title: 'Welcome Screen',
			icon: contenticon,
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings setShowPreview={setShowPreview} />
						<Button
							color="primary"
							className="mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</Button>
					</div>
				</div>
			),
		},
		{
			id: '5', // Updated ID to maintain sequence
			icon: configicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<>
			<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
				{accordionItems.map((item) => (
					<AccordionItem key={item.id}>
						<AccordionHeader targetId={item.id}>
							<img src={item.icon} alt={item.title} className="w-30px me-4" />
							{item.title}
						</AccordionHeader>
						<AccordionBody accordionId={item.id}>
							{item.content}
							{item.fields && (
								<Form>
									<div className="row">
										{item.fields.map((field) => (
											<div
												key={field.id}
												className={`col-md-${field.colSize || 12} mb-3`}
											>
												<FormGroup>
													<Label className="fs-5 fw-bold" for={field.id}>
														{field.label}
													</Label>

													<Input
														type={
															['mobile', 'phone', 'fax'].includes(field.id)
																? 'text'
																: field.type
														}
														className={field.className}
														maxLength={field.maxLength}
														id={field.id}
														placeholder={field.placeholder}
														value={
															(field.type !== 'file' && formData[field?.id]) ||
															''
														}
														onChange={handleInputChange}
														onPaste={(e) => {
															if (
																['mobile', 'phone', 'fax'].includes(field.id)
															) {
																e.preventDefault()
																const pastedText =
																	e.clipboardData.getData('text')
																const numericValue = pastedText
																	.replace(/[^0-9]/g, '')
																	.slice(0, 10)
																setFormData((prev) => ({
																	...prev,
																	[field.id]: numericValue,
																}))
															}
														}}
													/>
												</FormGroup>
												{errors[field.id] && (
													<div className="custom-error">{errors[field.id]}</div>
												)}
											</div>
										))}
									</div>
								</Form>
							)}
						</AccordionBody>
					</AccordionItem>
				))}
			</Accordion>

			<Modal isOpen={cropping} toggle={() => setCropping(!cropping)}>
				<ModalHeader toggle={() => setCropping(!cropping)}>
					Crop your image
				</ModalHeader>
				<ModalBody>
					{imageSrc && (
						<div className="crop-container">
							<Cropper
								image={imageSrc}
								crop={crop}
								zoom={zoom}
								aspect={1}
								onCropChange={setCrop}
								onCropComplete={(_, croppedAreaPixels) =>
									setCroppedAreaPixels(croppedAreaPixels)
								}
								onZoomChange={setZoom}
							/>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={handleSaveCroppedImage}>
						Save Image
					</Button>
					<Button color="secondary" onClick={() => setCropping(false)}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default Vcard
