// PasswordSection.jsx
import React, { useState } from 'react'
import { Input, FormGroup, Label } from 'reactstrap'

const PasswordSection = ({ setFormData }) => {
	const [activatePassword, setActivatePassword] = useState(false)
	const [qrPassword, setQrPassword] = useState('')

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<p>
				Add an access control to display the content and a password will be
				requested each time the QR is scanned.
			</p>
			<div className="mb-3">
				<Label check>
					<Input
						type="checkbox"
						checked={activatePassword}
						onChange={() => {
							setActivatePassword(!activatePassword)
							setFormData((prev) => ({
								...prev,
								passwordProtectionEnabled: !activatePassword,
							}))
						}}
					/>
					Activate password to access the QR code
				</Label>
			</div>
			{activatePassword && (
				<div style={{ marginLeft: '1.5rem' }}>
					<Label>Password</Label>
					<Input
						type="password"
						placeholder="Enter password"
						value={qrPassword}
						onChange={(e) => {
							setQrPassword(e.target.value)
							setFormData((prev) => ({
								...prev,
								password: e.target.value,
							}))
						}}
					/>
				</div>
			)}
		</div>
	)
}

export default PasswordSection
