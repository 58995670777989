import {
	Col,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
} from 'reactstrap'
import Copy from '../../common/copy'
import QrCode from '../../common/qrCode'
import imageDownloadHandler from '../../../utils/imageDownLoadHandler'
import imageCopyHandler from '../../../utils/imageCopyHandler'
import { useState } from 'react'
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import WebPreview from './qr-code-previews/webpreview'
import BcardPreview from './qr-code-previews/bcardpreview'
import BusinessPreview from './qr-code-previews/businesspreview'
import BarcodePreview from './qr-code-previews/barcodepreview'
import VideoPreview from './qr-code-previews/videopreview'
import AudioPreview from './qr-code-previews/audiopreview'
import PdfPreview from './qr-code-previews/pdfpreview'
import ImagesPreview from './qr-code-previews/imagespreview'
import LandingpagePreview from './qr-code-previews/landingpagepreview'
import ListofLinksPreview from './qr-code-previews/listoflinkspreview'
import ProductPreview from './qr-code-previews/productpreview'
import AppPreview from './qr-code-previews/apppreview'
import CouponPreview from './qr-code-previews/couponpreview'
import FeedbackPreview from './qr-code-previews/feedbackpreview'
import SocialmediaPreview from './qr-code-previews/socialmediapreview'
import EventsPreview from './qr-code-previews/eventspreview'
import TextPreview from './qr-code-previews/textpreview'
import UrlPreview from './qr-code-previews/urlpreview'
import WifiPreview from './qr-code-previews/wifipreview'
import SmsPreview from './qr-code-previews/smspreview'
import WhatsappPreview from './qr-code-previews/whatsapppreview'
import EmailPreview from './qr-code-previews/emailpreview'
import FacebookPreview from './qr-code-previews/facebookpreview'
import InstagramPreview from './qr-code-previews/instagrampreview'

export const copyQRHandler = (id, selectedComponent) => {
	const renderPreview = () => {
		switch (selectedComponent) {
			case 'A':
				return <WebPreview />
			case 'B':
				return <BcardPreview />
			case 'C':
				return <BusinessPreview />
			case 'D':
				return <BarcodePreview />
			case 'E':
				return <PdfPreview />
			case 'F':
				return <VideoPreview />
			case 'G':
				return <AudioPreview />
			case 'H':
				return <ImagesPreview />
			case 'I':
				return <LandingpagePreview />
			case 'J':
				return <ListofLinksPreview />
			case 'K':
				return <ProductPreview />
			case 'K':
				return <AppPreview />
			case 'L':
				return <CouponPreview />
			case 'M':
				return <FeedbackPreview />
			case 'O':
				return <SocialmediaPreview />
			case 'P':
				return <EventsPreview />
			case 'Q':
				return <TextPreview />
			case 'R':
				return <UrlPreview />
			case 'S':
				return <WifiPreview />
			case 'T':
				return <SmsPreview />
			case 'U':
				return <WhatsappPreview />
			case 'V':
				return <EmailPreview />
			case 'W':
				return <FacebookPreview />
			case 'X':
				return <InstagramPreview />
			default:
				return <WebPreview />
		}
	}

	try {
		const canvas = document.querySelector(`#${id}`).querySelector('canvas')
		canvas.toBlob((blob) => {
			navigator.clipboard
				.write([new ClipboardItem({ 'image/png': blob })])
				.then(() => {
					console.log('QR code copied to clipboard')
				})
				.catch((error) => {
					console.error('Unable to copy image to clipboard', error)
				})
		}, 'image/png')
	} catch (e) {
		console.log('Error while copying:', e)
	}
}

const WebsiteQr = ({ form, tabsHandler, selectedComponent }) => {
	const { watch, setValue } = form
	const { brandedDomain, slashTag, qr } = watch()
	// const [isCopied, setIsCopied] = useState(false);

	const getShortLink = () => {
		return `${brandedDomain}${
			convertToURLFriendly(slashTag) ? `/${convertToURLFriendly(slashTag)}` : ''
		}`
	}

	// const copyHandler = () => {
	//   setIsCopied(true);
	//   imageCopyHandler(qr);
	//   setTimeout(() => {
	//     setIsCopied(false);
	//   }, 1000);
	// };

	const renderPreview = () => {
		const formViewData = {
			firstName: '',
			lastName: 'Doe',
			bio: 'doe',
			mobile: '+1 99999 88888',
			phone: '55 555 555',
			fax: '00000',
			email: 'joy@joy.com',
			qrType: 'website',
			company: '',
			job: 'Software dev',
			address: '123, street, city',
			website: 'https://divsly.com',
			summary: '',
			businessType: 'It and services',
			facebook: '',
			instagram: '',
			google: '',
			linkedin: '',
			welcomeImage: '',
			profilePic: '',
			title: 'Preview',
			destinationUrl: 'https://divsly.com',
			brandedDomain: process.env.BASE_URL || '',
			preset: '#000000',
			color: '#000000',
			bgColor: '#ffffff',
			isBgTransparent: false,
			pattern: 'classy',
			corner: 'square',
			logo: '',
			edit: 0,
			frame: null,
			gradientColor: '',
			primaryColor: '#3e97ff',
			primary: '',
			secondary: '',
			textColor: '#ffffff',
			text: 'Scan Me',
			about: '',
			aboutName: '',
			aboutPhone: '',
			aboutEmail: '',
			aboutWebsite: '',
			mondayOpen: 'monday',
			tuesdayOpen: 'tuesday',
			wednesdayOpen: 'wednesday',
			thursdayOpen: 'thursday',
			fridayOpen: 'friday',
			saturdayOpen: 'saturday',
			sundayOpen: 'sunday',
			mondayStart: '09:00',
			tuesdayStart: '09:00',
			wednesdayStart: '09:00',
			thursdayStart: '09:00',
			fridayStart: '09:00',
			saturdayStart: '09:00',
			sundayStart: '09:00',
			mondayEnd: '20:00',
			tuesdayEnd: '20:00',
			wednesdayEnd: '20:00',
			thursdayEnd: '20:00',
			fridayEnd: '20:00',
			saturdayEnd: '20:00',
			sundayEnd: '20:00',
		}
		switch (
			selectedComponent // Assuming selectedComponent is part of the form or qr object
		) {
			case 'A':
				return <WebPreview formData={formViewData} />
			case 'B':
				return <BusinessPreview formData={formViewData} />
			case 'C':
				return <BcardPreview formData={formViewData} />
			case 'D':
				return <BarcodePreview formData={formViewData} />
			case 'E':
				return <PdfPreview formData={formViewData} />
			case 'F':
				return <VideoPreview formData={formViewData} />
			case 'G':
				return <AudioPreview formData={formViewData} />
			case 'H':
				return <ImagesPreview formData={formViewData} />
			case 'I':
				return <LandingpagePreview formData={formViewData} />
			case 'J':
				return <ListofLinksPreview formData={formViewData} />
			case 'K':
				return <ProductPreview formData={formViewData} />
			case 'L':
				return <AppPreview formData={formViewData} />
			case 'M':
				return <FeedbackPreview formData={formViewData} />
			case 'O':
				return <SocialmediaPreview formData={formViewData} />
			case 'P':
				return <EventsPreview formData={formViewData} />
			case 'Q':
				return <TextPreview formData={formViewData} />
			case 'R':
				return <UrlPreview formData={formViewData} />
			case 'S':
				return <WifiPreview formData={formViewData} />
			case 'T':
				return <SmsPreview formData={formViewData} />
			case 'U':
				return <WhatsappPreview formData={formViewData} />
			case 'V':
				return <EmailPreview formData={formViewData} />
			case 'W':
				return <FacebookPreview formData={formViewData} />
			case 'X':
				return <InstagramPreview formData={formViewData} />
			default:
				return <WebPreview formData={formViewData} />
		}
	}

	return (
		<Col
			md={5}
			className="mt-6 d-flex flex-column pe-4 ps-10 position-relative mobile-hide-f"
		>
			<div
				className="py-10 rounded-1  position-sticky"
				style={{
					border: '1px solid #e9e6f1',
					top: '30px',
					backgroundColor: '#F7F5FD',
				}}
			>
				<div class="d-flex py-3 w-75 mx-auto justify-content-center border-bottom mb-5">
					<h5 class="fs-2 mb-0 fw-semibold bg-light-info border border-dark border-opacity-10 px-6 py-3 rounded-pill">
						{' '}
						<i className="fas fa-eye fs-4 me-2 text-info"></i> Preview
					</h5>
				</div>

				<div className="mobile-wrapper">
					<span className="volume-button-up"></span>
					<span className="volume-button-down"></span>
					<span className="power-button"></span>
					<div className="mobile-qr pe-0 overflow-hidden scroll-none">
						<div className="backdrop"></div>
						<div className="position-absolute float-qr-demo">
							<div className="bg-white w-175px d-flex flex-column justify-content-center align-items-center rounded-1 p-2 pt-5">
								<img
									className="w-150px"
									src="https://api.qrcode-monkey.com/tmp/95d96659e79fd48868d92ad89d601be3.png"
									alt=""
								/>
								<div className="d-flex mt-5 gap-3 px-3 align-items-center">
									<i className="fas fa-barcode-read fs-2"></i>
									<p className="mb-0 fs-5 ms-3">Scan to see live preview</p>
								</div>
							</div>
						</div>
						{renderPreview()}
					</div>
				</div>
			</div>
		</Col>
	)
}

export default WebsiteQr
