import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import configicon from '../../../../assets/qr-icons/config.png'
import QrLink from '../QrLink'
import RichTextEditor from './components/RichTextEditor'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

const LandingPageQr = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setShowPreview,
	setAnimationDirection,
	setContentWelcomeTime,
	animationDirection,
	contentWelcomeTime,
}) => {
	console.log('props ', formData, setFormData, isEdit, qrType)
	const [open, setOpen] = useState('0')

	const toggle = (id) => {
		open === id ? setOpen('') : setOpen(id)
	}

	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}))
	}

	const handleBackgroundImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					backgroundImage: reader.result,
				}))
			}
			reader.readAsDataURL(file)
		}
	}

	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center">
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.primaryColor}
								placeholder="#ffffff"
							/>
						</div>
					</FormGroup>
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
							/>
							<Input
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '2',
			icon: infoicon,
			title: 'Basic Information',
			description: 'Add essential information',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<FormGroup>
						<Label for="title" className="text-sm text-gray-600 mb-2">
							Title
						</Label>
						<Input
							type="text"
							id="title"
							placeholder="Enter title"
							value={formData?.title || ''}
							onChange={(e) =>
								setFormData((prev) => ({ ...prev, title: e.target.value }))
							}
							className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</FormGroup>
					<FormGroup className="mt-4">
						<Label className="text-sm text-gray-600 mb-2">
							Background Image
						</Label>
						<div
							style={{
								width: '100px',
								height: '100px',
								border: '1px dashed #ccc',
								borderRadius: '5px',
								position: 'relative',
								overflow: 'hidden',
							}}
						>
							<div
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									fontSize: '20px',
									color: '#999',
									pointerEvents: 'none',
									display: formData?.backgroundImage ? 'none' : 'block',
								}}
							>
								+
							</div>
							<img
								src={formData?.backgroundImage || '/path/to/default-logo.png'}
								alt=""
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									display: formData?.backgroundImage ? 'block' : 'none',
								}}
							/>
							<Input
								type="file"
								style={{
									opacity: 0,
									width: '100%',
									height: '100%',
									cursor: 'pointer',
									position: 'absolute',
									top: 0,
									left: 0,
								}}
								onChange={handleBackgroundImageChange}
							/>
						</div>
					</FormGroup>
					<FormGroup className="mt-4">
						<RichTextEditor
							value={formData?.description || ''}
							onChange={(content) =>
								setFormData((prev) => ({ ...prev, description: content }))
							}
						/>
					</FormGroup>
				</div>
			),
		},
		{
			id: '3',
			icon: contenticon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						{/* <h6 className="fw-bold">Welcome screen</h6> */}
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings setShowPreview={setShowPreview} />
						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => {
											setShowPreview(false)
										},
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '4',
			icon: configicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<>
			<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
				{accordionItems.map((item) => (
					<AccordionItem key={item.id}>
						<AccordionHeader targetId={item.id}>
							<img src={item.icon} alt={item.title} className="w-30px me-4" />
							{item.title}
						</AccordionHeader>
						<AccordionBody accordionId={item.id}>
							{item.description && (
								<p className="text-sm text-gray-600 mb-3">{item.description}</p>
							)}
							{item.content}
							{item.fields && (
								<Form>
									<div className="row">
										{item.fields.map((field) => (
											<div
												key={field.id}
												className={`col-md-${field.colSize || 12} mb-3`}
											>
												<FormGroup>
													<Label className="fs-5 fw-bold" for={field.id}>
														{field.label}
													</Label>
													<Input
														type={field.type}
														className={field.className}
														maxLength={field.maxLength}
														id={field.id}
														placeholder={field.placeholder}
														value={
															(field.type !== 'file' && formData[field?.id]) ||
															''
														}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
										))}
									</div>
								</Form>
							)}
						</AccordionBody>
					</AccordionItem>
				))}
			</Accordion>
		</>
	)
}

export default LandingPageQr
