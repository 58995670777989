import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'
import { Link } from 'react-router-dom'
import videoPreview from '../../../../assets/qr-preview/qr-video-preview.png'
import { isColorDark } from './color-checker/isColorDark'

const VideoCard = ({ videoUrl, title, description, youtubeLink }) => {
	return (
		<div>
			<div>
				<iframe
					width="100%"
					height="auto"
					style={{
						borderRadius: '10px',
					}}
					src={videoUrl}
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerPolicy="strict-origin-when-cross-origin"
					allowFullScreen
				></iframe>
			</div>
			<div>
				<h3 className="mt-2">{title}</h3>
				<p
					className="fs-5 mt-2 text-black text-wrap-oneline"
					style={{ WebkitLineClamp: '1' }}
				>
					{description}
				</p>
			</div>
		</div>
	)
}

const VideoSection = ({ formData }) => {
	const {
		title,
		videos,
		facebook,
		instagram,
		google,
		linkedin,
		pageTitle,
		description,
		buttonText,
		buttonLink,
	} = formData

	return (
		<div className="position-relative onhvr-scroll-y">
			<div
				className="w-100 mx-auto pb-10 scroll-content"
				style={{
					height: '575px',
					maxWidth: ' 900px',
				}}
			>
				<div
					className="d-flex justify-content-center align-items-start py-10 flex-column"
					style={{
						backgroundImage: `url(${videoPreview})`,
						backgroundSize: 'contain',
						backgroundPosition: 'center',
						height: '170px',
					}}
				></div>

				<div
					className="d-flex justify-content-start align-items-start flex-column text-start"
					style={{
						backgroundColor: 'white',
						borderRadius: '10px',
						width: '90%',
						padding: '15px',
						margin: '-50px auto 0px auto',
						border: '1px solid #dedede',
					}}
				>
					<div className="fs-1 fw-bold mb-2">
						{formData?.pageTitle || 'Page Title'}
					</div>
					<p className="fs-5 mb-0">
						{formData?.description ||
							'We specialize in delivering top-notch solutions tailored to meet your unique needs. Let us help you succeed in your business goals.'}
					</p>

					<a
						href={formData?.buttonLink}
						className={`text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'} btn rounded-1 mt-2`}
						style={{ background: formData?.ctaColor || '#348ce5' }}
					>
						{formData?.buttonText || 'Learn More'}
					</a>
				</div>

				<div className="d-flex flex-column gap-5 p-5">
					{videos?.length > 0 ? (
						videos.map((video, index) => (
							<VideoCard
								key={index}
								videoUrl={video?.embedLink || ''}
								title={video?.title || ''}
								description={video.description || ''}
								youtubeLink={video?.youtubeLink || ''}
							/>
						))
					) : (
						<VideoCard
							key={0}
							videoUrl="https://www.youtube.com/embed/AMK1VsDLTug"
							title="How to Set Up an Email Campaign"
							description="Learn how to effectively create and manage email campaigns to boost your marketing efforts and engage your audience."
							youtubeLink="https://www.youtube.com/watch?v=AMK1VsDLTug"
						/>
					)}
				</div>

				{/* <div className="p-5">
        <h3 className="mb-8">Social Media</h3>
        <div className="d-flex gap-5">
          <div className="w-50px h-50px rounded-circle d-flex align-items-center justify-content-center" style={{ backgroundColor: '#3b5998' }}>
            <i className="fab fa-facebook-f fs-2 text-white"></i>
          </div>
          <div className="w-50px h-50px rounded-circle d-flex align-items-center justify-content-center" style={{ backgroundColor: '#e1306c' }}>
            <i className="fab fa-instagram fs-2 text-white"></i>
          </div>
        </div>
      </div> */}

				{/* social media */}
				<div className="d-flex gap-6 mb-3 py-4 justify-content-center align-items-center">
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{ backgroundColor: '#3F51B5' }}
					>
						<Link to={facebook || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-facebook-f text-white"></i>
						</Link>
					</span>
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{
							background:
								'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
						}}
					>
						<Link to={instagram || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-instagram text-white"></i>
						</Link>
					</span>
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{ backgroundColor: '#e84d40' }}
					>
						<Link to={google || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-google text-white"></i>
						</Link>
					</span>
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{ backgroundColor: '#0078D4' }}
					>
						<Link to={linkedin || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-linkedin-in text-white"></i>
						</Link>
					</span>
				</div>
			</div>
		</div>
	)
}

// Usage Example
const VideoPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	// const videos = [
	//   {
	//     videoUrl: "https://www.youtube.com/embed/AMK1VsDLTug?si=uPPeZ0cpIaT4C-ph&controls=0",
	//     title: "Tony Robbins",
	//     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, tempora!",
	//     youtubeLink: "https://www.youtube.com/watch?v=AMK1VsDLTug"
	//   },
	//   {
	//     videoUrl: "https://www.youtube.com/embed/AMK1VsDLTug?si=uPPeZ0cpIaT4C-ph&controls=0",
	//     title: "Tony Robbins",
	//     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, tempora!",
	//     youtubeLink: "https://www.youtube.com/watch?v=AMK1VsDLTug"
	//   }
	// ];
	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return <VideoSection formData={formData} />
}

export default VideoPreview
