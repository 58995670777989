import React, { useContext, useEffect, useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Col,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/basic-info.png'
import clockicon from '../../../../assets/qr-icons/clock.png'
import locationicon from '../../../../assets/qr-icons/location.png'
import infocontacticon from '../../../../assets/qr-icons/info-contact.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import configicon from '../../../../assets/qr-icons/config.png'
import contenticon from '../../../../assets/qr-icons/content.png' // Added for Welcome Screen
import QrLink from '../QrLink'
import { AuthContxt } from '../../../../store/authContxt'
import businesspagebanner from '../../../../assets/qr-preview/business-page-banner.jpeg'
import PhoneInput from 'react-phone-input-2'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings' // Added import

const BusinessPageQRForm = ({
	formData,
	setFormData,
	qrType,
	isEdit,
	tab,
	setIsInvalid,
}) => {
	useEffect(() => {
		if (!formData?.title) setIsInvalid(true)
		else setIsInvalid(false)
	}, [formData])
	const [open, setOpen] = useState('0')
	const { errors, setErrors } = useContext(AuthContxt)
	const [showPreview, setShowPreview] = useState(false) // Added for preview functionality
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	) // Added for AnimationSettings

	useEffect(() => {
		tab && setOpen(tab)
	}, [tab])

	const toggleAccordion = (id) => {
		setOpen(open === id ? '' : id)
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		if (name === 'aboutPhone' && isNaN(parseInt(value))) {
			setErrors({ ...errors, [name]: 'Please enter valid phone number' })
		} else {
			setErrors({ ...errors, [name]: '' })
		}
		setFormData((prevData) => {
			const updatedData = { ...prevData, [name]: value }

			if (
				name === 'street' ||
				name === 'postalCode' ||
				name === 'city' ||
				name === 'country'
			) {
				const generateMapUrl = () => {
					const query = `${updatedData.street || ''} ${updatedData.postalCode || ''} ${updatedData.city || ''} ${updatedData.country || ''}`
					const encodedQuery = encodeURIComponent(query.trim())
					const url = `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`
					console.log(url)
					return url
				}

				updatedData.location = generateMapUrl()
			}

			return updatedData
		})
	}

	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design & Customizations',
			icon: designicon,
			content: (
				<div>
					<p className="fs-5 text-gray-700 mb-10">
						Customize your business page with the following options.
					</p>
					<p className="fw-semibold fs-4 mb-4">Colors</p>
					<div className="d-flex flex-wrap row-gap-5">
						{[
							'#de3121',
							'#ef8000',
							'#198639',
							'#229ce0',
							'#2a5bd7',
							'#6b52d1',
							'#d84280',
						].map((color, idx) => (
							<div
								key={idx}
								className="p-1 cursor-pointer rounded-1 on-hvr-border bdr-qr-btn me-5"
							>
								<div
									onClick={() => {
										setFormData((prevData) => ({
											...prevData,
											primaryColor: color,
										}))
									}}
									className="p-3 rounded-1 w-40px h-40px"
									style={{ backgroundColor: color }}
								></div>
							</div>
						))}
					</div>

					<div className="d-flex flex-wrap gap-lg-10 gap-5 mt-10">
						<div>
							<p className="fs-4 fw-semibold">Primary</p>
							<div className="color-picker-wrapper">
								<Input
									className="color-picker rounded-0"
									type="color"
									name="primaryColor"
									value={formData?.primaryColor || '#000000'}
									onChange={handleChange}
								/>
								<Input
									className="hex-input form-control rounded-0"
									type="text"
									name="primaryColor"
									placeholder="#000000"
									value={formData?.primaryColor}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div>
							<p className="fs-4 fw-semibold">Button</p>
							<div className="color-picker-wrapper">
								<Input
									className="color-picker rounded-0"
									type="color"
									name="ctaColor"
									value={formData?.ctaColor}
									onChange={handleChange}
								/>
								<Input
									className="hex-input form-control rounded-0"
									type="text"
									name="ctaColor"
									placeholder="#5c47e"
									value={formData?.ctaColor}
									onChange={handleChange}
								/>
							</div>
						</div>
					</div>

					<div className="mt-10">
						<div>
							<p className="fs-4 fw-semibold">Image:</p>
						</div>
						<div className="mt-3 pe-8">
							<div
								className="image-input image-input-outline"
								style={{
									backgroundImage: `url(${formData?.image || businesspagebanner})`,
								}}
							>
								<div
									className="image-input-wrapper w-125px h-125px shadow-none border border-dark border-opacity-10"
									style={{
										backgroundImage: `url(${formData?.image || businesspagebanner})`,
									}}
								></div>
								<Label
									className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body position-absolute border border-dark border-opacity-10"
									style={{ right: '3px', top: '3px' }}
								>
									<i className="fas fa-pencil fs-6"></i>
									<Input
										className="d-none"
										type="file"
										name="image"
										accept=".png, .jpg, .jpeg"
										onChange={(e) =>
											setFormData({
												...formData,
												image: URL.createObjectURL(e.target.files[0]),
												file: e.target.files[0],
											})
										}
									/>
								</Label>
							</div>
						</div>
					</div>
				</div>
			),
		},
		{
			id: '2',
			title: 'Basic Information',
			icon: infoicon,
			content: (
				<Form>
					<p className="fs-5 mb-5">
						Introduce your business or organization in a few words. Optionally,
						add a button to a website of your choice. Fields marked with a * are
						mandatory.
					</p>

					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="company">
							Company
						</Label>
						<Input
							type="text"
							className="h-50px"
							id="company"
							name="company"
							placeholder="Joy's Cafe"
							value={formData?.company}
							onChange={handleChange}
						/>
					</FormGroup>

					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="headline">
							Headline*
						</Label>
						<Input
							type="text"
							className="h-50px"
							id="headline"
							name="headline"
							placeholder="Add a headline or slogan"
							value={formData?.headline}
							onChange={handleChange}
							required
						/>
						{errors?.headline && (
							<p style={{ color: 'red' }}>{errors.headline}</p>
						)}
					</FormGroup>

					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="summary">
							Summary
						</Label>
						<Input
							type="textarea"
							id="summary"
							name="summary"
							rows="3"
							maxLength={200}
							value={formData?.summary}
							onChange={handleChange}
						/>
						<small className="form-text text-muted text-right">64 / 200</small>
					</FormGroup>

					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="button">
							Button
						</Label>
						<Row>
							<Col md={6}>
								<Input
									type="text"
									className="h-50px"
									name="buttonText"
									placeholder="Button Text"
									value={formData?.buttonText}
									onChange={handleChange}
								/>
							</Col>
							<Col md={6}>
								<Input
									type="url"
									className="h-50px"
									name="buttonUrl"
									placeholder="http://www."
									value={formData?.buttonUrl}
									onChange={handleChange}
								/>
							</Col>
						</Row>
					</FormGroup>
				</Form>
			),
		},
		{
			id: '3',
			title: 'Opening Hours',
			icon: clockicon,
			content: (
				<Form>
					<p className="fs-5 mb-5">Provide your opening hours if applicable.</p>
					<Row className="mw-700px">
						{[
							'Monday',
							'Tuesday',
							'Wednesday',
							'Thursday',
							'Friday',
							'Saturday',
							'Sunday',
						].map((day, idx) => (
							<FormGroup key={idx} className="row align-items-center">
								<Col md={1}>
									<div className="form-check form-check-sm form-check-custom form-check-info form-check-solid not-checked-white rounded-1">
										<Input
											type="checkbox"
											id={day.toLowerCase()}
											className="form-check-input cursor-pointer"
											name={`${day.toLowerCase()}Open`}
											checked={formData?.[`${day.toLowerCase()}Open`]}
											onChange={(e) =>
												setFormData((prevData) => ({
													...prevData,
													[`${day.toLowerCase()}Open`]: e.target.checked,
												}))
											}
										/>
									</div>
								</Col>
								<Label for={day.toLowerCase()} className="col-md-2 fw-bold">
									{day}
								</Label>
								<Col md={3}>
									<Input
										type="time"
										name={`${day.toLowerCase()}Start`}
										value={formData?.[`${day.toLowerCase()}Start`]}
										onChange={handleChange}
									/>
								</Col>
								<span className="col-md-1 text-center">-</span>
								<Col md={3}>
									<Input
										type="time"
										name={`${day.toLowerCase()}End`}
										value={formData?.[`${day.toLowerCase()}End`]}
										onChange={handleChange}
									/>
								</Col>
								<Col />
							</FormGroup>
						))}
					</Row>
				</Form>
			),
		},
		{
			id: '4',
			title: 'Address & Location',
			icon: locationicon,
			content: (
				<Form>
					<FormGroup className="mb-7">
						<Label className="fs-5 fw-bold" for="street">
							Street
						</Label>
						<Input
							type="text"
							className="h-50px"
							name="street"
							id="street"
							value={formData?.street}
							onChange={handleChange}
						/>
					</FormGroup>
					<Row>
						<Col md={6}>
							<FormGroup className="mb-7">
								<Label className="fs-5 fw-bold" for="postalCode">
									Postal Code
								</Label>
								<Input
									type="text"
									className="h-50px"
									name="postalCode"
									id="postalCode"
									value={formData?.postalCode}
									onChange={handleChange}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="mb-7">
								<Label className="fs-5 fw-bold" for="city">
									City
								</Label>
								<Input
									type="text"
									className="h-50px"
									name="city"
									id="city"
									value={formData?.city}
									onChange={handleChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<FormGroup className="mb-7">
						<Label className="fs-5 fw-bold" for="country">
							Country
						</Label>
						<Input
							type="text"
							className="h-50px"
							name="country"
							id="country"
							value={formData?.country}
							onChange={handleChange}
						/>
					</FormGroup>
				</Form>
			),
		},
		{
			id: '5',
			title: 'About & Contact Information',
			icon: infocontacticon,
			content: (
				<Form>
					<p className="fs-5 mb-5">
						Add more detailed information about your business and provide
						contact data.
					</p>
					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="about">
							About
						</Label>
						<Input
							type="textarea"
							id="about"
							name="about"
							placeholder="about"
							value={formData?.about}
							onChange={handleChange}
						/>
					</FormGroup>
					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="aboutName">
							Name
						</Label>
						<Input
							type="text"
							className="h-50px"
							id="aboutName"
							name="aboutName"
							placeholder="Joy"
							value={formData?.aboutName}
							onChange={handleChange}
						/>
					</FormGroup>
					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="aboutPhone">
							Phone
						</Label>
						<PhoneInput
							name="aboutPhone"
							value={formData.aboutPhone}
							onChange={(phone) => {
								setFormData({ ...formData, aboutPhone: phone })
							}}
							className="h-50px form-control contact-form"
							country={'in'}
							enableSearch={true}
						/>
						{errors?.aboutPhone && (
							<p style={{ color: 'red' }}>{errors.aboutPhone}</p>
						)}
					</FormGroup>
					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="aboutEmail">
							Email
						</Label>
						<Input
							type="text"
							className="h-50px"
							id="aboutEmail"
							name="aboutEmail"
							placeholder="hello@joysbar.com"
							value={formData?.aboutEmail}
							onChange={handleChange}
						/>
					</FormGroup>
					<FormGroup className="mb-7">
						<Label className="fw-bold fs-5" for="aboutWebsite">
							Website
						</Label>
						<Input
							type="text"
							className="h-50px"
							id="aboutWebsite"
							name="aboutWebsite"
							placeholder="https://www."
							value={formData?.aboutWebsite}
							onChange={handleChange}
						/>
					</FormGroup>
				</Form>
			),
		},
		{
			id: '6',
			title: 'Welcome Screen',
			icon: contenticon,
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>
						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => {
											setShowPreview(false)
										},
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '7',
			title: 'Password',
			icon: configicon,
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<div className="container px-0 mt-8 mb-5">
			<Accordion
				className="qr-accodian-form"
				open={open}
				toggle={toggleAccordion}
			>
				{accordionItems.map((item) => (
					<AccordionItem key={item.id}>
						<AccordionHeader targetId={item.id}>
							<img src={item.icon} className="w-30px me-4" alt={item.title} />
							{item.title}
						</AccordionHeader>
						<AccordionBody accordionId={item.id}>{item.content}</AccordionBody>
					</AccordionItem>
				))}
			</Accordion>
		</div>
	)
}

export default BusinessPageQRForm
