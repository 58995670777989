export const getAnimationStyle = (animationDirection, duration) => {
	switch (animationDirection) {
		case 'top-to-bottom':
			return { animation: `slideInTop ${duration}s forwards` }
		case 'left-to-right':
			return { animation: `slideInLeft ${duration}s forwards` }
		case 'right-to-left':
			return { animation: `slideInRight ${duration}s forwards` }
		case 'fade':
			return { animation: `fadeIn ${duration}s forwards` }
		case 'bottom-to-top':
		default:
			return { animation: `slideInBottom ${duration}s forwards` }
	}
}
