import React, { useState, useEffect } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Button,
} from 'reactstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
// FileUpload & RichTextEditor from your components
import FileUpload from './components/fileUpload'
import RichTextEditor from './components/RichTextEditor'
// Icons
import linkicon from '../../../../assets/qr-icons/link.png'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import configicon from '../../../../assets/qr-icons/config.png'
// The same QrLink component used in landingpageQr.jsx
import QrLink from '../QrLink'

/* --------------------------
   ImagePreview Component
   Converts a File object (or string URL) into a browser-usable URL
-------------------------- */
const ImagePreview = ({ file }) => {
	const [previewUrl, setPreviewUrl] = useState(null)

	useEffect(() => {
		if (file) {
			const url = typeof file === 'string' ? file : URL.createObjectURL(file)
			setPreviewUrl(url)
			return () => {
				if (typeof file !== 'string') URL.revokeObjectURL(url)
			}
		} else {
			setPreviewUrl(null)
		}
	}, [file])

	if (!previewUrl) return null
	return (
		<div className="mt-4">
			<img
				src={previewUrl}
				alt="Preview"
				style={{ maxWidth: '200px', borderRadius: '8px' }}
			/>
		</div>
	)
}

/* --------------------------
   Short Link Section (id: 0)
-------------------------- */
const ShortLinkSection = ({ formData, setFormData, isEdit, qrType }) => {
	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<h3 className="text-md font-semibold mb-2">Short Link</h3>
			<p className="text-sm text-gray-600 mb-3">Customize your short URL.</p>
			<QrLink
				formData={formData}
				setFormData={setFormData}
				isEdit={isEdit}
				qrType={qrType}
			/>
		</div>
	)
}

/* --------------------------
   Design Customization Section (id: 1)
   Uses top-level keys: primaryColor & ctaColor
-------------------------- */
const DesignCustomization = ({ formData, setFormData }) => {
	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<div className="d-flex gap-10 align-items-center">
				{/* Primary Color Picker */}
				<FormGroup>
					<Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
					<div className="color-picker-wrapper d-flex gap-2">
						<Input
							type="color"
							className="color-picker rounded-0 cursor-pointer"
							value={formData.primaryColor}
							onChange={(e) =>
								setFormData((prev) => ({
									...prev,
									primaryColor: e.target.value,
								}))
							}
						/>
						<Input
							type="text"
							className="hex-input form-control rounded-0"
							maxLength="7"
							value={formData.primaryColor}
							onChange={(e) =>
								setFormData((prev) => ({
									...prev,
									primaryColor: e.target.value,
								}))
							}
							placeholder="#000000"
						/>
					</div>
				</FormGroup>
				{/* Button Color Picker */}
				<FormGroup>
					<Label className="fs-4 fw-semibold mb-4">Button Color</Label>
					<div className="color-picker-wrapper d-flex gap-2">
						<Input
							type="color"
							className="color-picker rounded-0 cursor-pointer"
							value={formData.ctaColor}
							onChange={(e) =>
								setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
							}
						/>
						<Input
							type="text"
							className="hex-input form-control rounded-0"
							maxLength="7"
							value={formData.ctaColor}
							onChange={(e) =>
								setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
							}
							placeholder="#000000"
						/>
					</div>
				</FormGroup>
			</div>
		</div>
	)
}

/* --------------------------
   Basic Information Section (id: 2)
-------------------------- */
const BasicInformation = ({ formData, setFormData }) => {
	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<h3 className="text-md font-semibold mb-2">Basic Information</h3>
			<p className="text-sm text-gray-600 mb-3">
				Add essential product information.
			</p>
			{/* Product Title */}
			<input
				type="text"
				placeholder="Product Title"
				value={formData.basicInfo.title || ''}
				onChange={(e) =>
					setFormData((prev) => ({
						...prev,
						basicInfo: { ...prev.basicInfo, title: e.target.value },
					}))
				}
				className="w-full px-4 py-2 border rounded-lg"
			/>
			{/* Product Image Upload */}
			<div className="mt-4">
				<label className="text-sm text-gray-600 block mb-2">
					Product Image
				</label>
				<FileUpload
					setFormData={setFormData}
					formData={formData}
					fileUpdater={() => {}}
					qrType="productImage"
				/>
			</div>
			{/* Image Preview */}
			<ImagePreview file={formData.basicInfo.image} />
			{/* Additional Fields */}
			<div className="mt-4">
				<FormGroup>
					<Label>Product Header</Label>
					<Input
						type="text"
						placeholder="Header"
						value={formData.basicInfo.header || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, header: e.target.value },
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Type of Wine</Label>
					<Input
						type="text"
						placeholder="e.g., Red"
						value={formData.basicInfo.typeOfWine || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, typeOfWine: e.target.value },
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Varieties</Label>
					<Input
						type="text"
						placeholder="e.g., Syrah, Grenache"
						value={formData.basicInfo.varieties || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, varieties: e.target.value },
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Add</Label>
					<Input
						type="text"
						placeholder="e.g., 2023"
						value={formData.basicInfo.add || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, add: e.target.value },
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Region</Label>
					<Input
						type="text"
						placeholder="Region"
						value={formData.basicInfo.region || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, region: e.target.value },
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Country of Origin</Label>
					<Input
						type="text"
						placeholder="Country"
						value={formData.basicInfo.countryOfOrigin || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: {
									...prev.basicInfo,
									countryOfOrigin: e.target.value,
								},
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Store</Label>
					<Input
						type="text"
						placeholder="Store"
						value={formData.basicInfo.store || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, store: e.target.value },
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Geographical Indication</Label>
					<Input
						type="text"
						placeholder="Geographical Indication"
						value={formData.basicInfo.geographicalIndication || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: {
									...prev.basicInfo,
									geographicalIndication: e.target.value,
								},
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Alcohol</Label>
					<Input
						type="text"
						placeholder="Alcohol"
						value={formData.basicInfo.alcohol || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, alcohol: e.target.value },
							}))
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Amount</Label>
					<Input
						type="text"
						placeholder="Amount"
						value={formData.basicInfo.amount || ''}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								basicInfo: { ...prev.basicInfo, amount: e.target.value },
							}))
						}
					/>
				</FormGroup>
				{/* Dynamic Categories */}
				<div className="mt-4">
					<h5>Categories</h5>
					{formData.basicInfo.categories.map((cat, index) => (
						<div key={index} className="d-flex align-items-center mb-2">
							<Input
								type="text"
								placeholder="Category"
								value={cat.category || ''}
								onChange={(e) => {
									const value = e.target.value
									setFormData((prev) => {
										const newCats = [...prev.basicInfo.categories]
										newCats[index].category = value
										return {
											...prev,
											basicInfo: { ...prev.basicInfo, categories: newCats },
										}
									})
								}}
								className="me-2"
							/>
							<Input
								type="text"
								placeholder="Value"
								value={cat.categoryValue || ''}
								onChange={(e) => {
									const value = e.target.value
									setFormData((prev) => {
										const newCats = [...prev.basicInfo.categories]
										newCats[index].categoryValue = value
										return {
											...prev,
											basicInfo: { ...prev.basicInfo, categories: newCats },
										}
									})
								}}
								className="me-2"
							/>
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => {
									setFormData((prev) => {
										const newCats = [...prev.basicInfo.categories]
										newCats.splice(index, 1)
										return {
											...prev,
											basicInfo: { ...prev.basicInfo, categories: newCats },
										}
									})
								}}
							>
								Delete
							</button>
						</div>
					))}
					<button
						type="button"
						className="btn btn-primary"
						onClick={() =>
							setFormData((prev) => ({
								...prev,
								basicInfo: {
									...prev.basicInfo,
									categories: [
										...prev.basicInfo.categories,
										{ category: '', categoryValue: '' },
									],
								},
							}))
						}
					>
						+ Add category
					</button>
				</div>
			</div>
		</div>
	)
}

/* --------------------------
   Content Section (id: 3)
-------------------------- */
const ContentSection = ({ formData, setFormData }) => {
	// Ingredients Handlers
	const handleAddIngredient = () => {
		setFormData((prev) => ({
			...prev,
			content: {
				...prev.content,
				ingredients: [...prev.content.ingredients, { name: '' }],
			},
		}))
	}
	const handleIngredientChange = (e, index) => {
		const { value } = e.target
		setFormData((prev) => {
			const newIngredients = [...prev.content.ingredients]
			newIngredients[index].name = value
			return {
				...prev,
				content: { ...prev.content, ingredients: newIngredients },
			}
		})
	}
	const handleRemoveIngredient = (index) => {
		setFormData((prev) => {
			const newIngredients = [...prev.content.ingredients]
			newIngredients.splice(index, 1)
			return {
				...prev,
				content: { ...prev.content, ingredients: newIngredients },
			}
		})
	}

	// Nutritional Info Handlers
	const handleQualificationChange = (e) => {
		setFormData((prev) => ({
			...prev,
			content: {
				...prev.content,
				nutritionalInfo: {
					...prev.content.nutritionalInfo,
					qualification: e.target.value,
				},
			},
		}))
	}
	const handleAddNutrient = () => {
		setFormData((prev) => ({
			...prev,
			content: {
				...prev.content,
				nutritionalInfo: {
					...prev.content.nutritionalInfo,
					nutrients: [
						...prev.content.nutritionalInfo.nutrients,
						{ nutrient: '', value: '' },
					],
				},
			},
		}))
	}
	const handleNutrientChange = (e, index, field) => {
		const { value } = e.target
		setFormData((prev) => {
			const newNutrients = [...prev.content.nutritionalInfo.nutrients]
			newNutrients[index][field] = value
			return {
				...prev,
				content: {
					...prev.content,
					nutritionalInfo: {
						...prev.content.nutritionalInfo,
						nutrients: newNutrients,
					},
				},
			}
		})
	}
	const handleRemoveNutrient = (index) => {
		setFormData((prev) => {
			const newNutrients = [...prev.content.nutritionalInfo.nutrients]
			newNutrients.splice(index, 1)
			return {
				...prev,
				content: {
					...prev.content,
					nutritionalInfo: {
						...prev.content.nutritionalInfo,
						nutrients: newNutrients,
					},
				},
			}
		})
	}

	// More Information Handler
	const handleMoreDetailsChange = (e) => {
		setFormData((prev) => ({
			...prev,
			content: {
				...prev.content,
				moreInformation: {
					...prev.content.moreInformation,
					additionalDetails: e.target.value,
				},
			},
		}))
	}

	// Welcome Screen Handlers
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			setFormData((prev) => ({
				...prev,
				content: {
					...prev.content,
					welcomeScreen: { ...prev.content.welcomeScreen, image: file },
				},
			}))
		}
	}
	const handleWelcomeTimeChange = (e) => {
		setFormData((prev) => ({
			...prev,
			content: {
				...prev.content,
				welcomeScreen: { ...prev.content.welcomeScreen, time: e.target.value },
			},
		}))
	}

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
			<h3 className="text-md font-semibold mb-2">Content</h3>

			{/* Ingredients */}
			<div className="mb-4">
				<h4>Ingredients</h4>
				{formData.content.ingredients.map((ing, index) => (
					<div key={index} className="mb-2">
						<Input
							type="text"
							placeholder="Ingredient"
							value={ing.name}
							onChange={(e) => handleIngredientChange(e, index)}
						/>
						<button
							type="button"
							className="btn btn-danger mt-1"
							onClick={() => handleRemoveIngredient(index)}
						>
							Delete
						</button>
					</div>
				))}
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleAddIngredient}
				>
					+ Add Ingredient
				</button>
			</div>

			{/* Nutritional Information */}
			<div className="mb-4">
				<h4>Nutritional Information</h4>
				<FormGroup>
					<Label>Qualification</Label>
					<Input
						type="text"
						placeholder="Qualification"
						value={formData.content.nutritionalInfo.qualification}
						onChange={handleQualificationChange}
					/>
				</FormGroup>
				{formData.content.nutritionalInfo.nutrients.map((nut, index) => (
					<div key={index} className="mb-2">
						<Input
							type="text"
							placeholder="Nutrient"
							value={nut.nutrient}
							onChange={(e) => handleNutrientChange(e, index, 'nutrient')}
							className="mb-1"
						/>
						<Input
							type="text"
							placeholder="Value"
							value={nut.value}
							onChange={(e) => handleNutrientChange(e, index, 'value')}
							className="mb-1"
						/>
						<button
							type="button"
							className="btn btn-danger"
							onClick={() => handleRemoveNutrient(index)}
						>
							Delete
						</button>
					</div>
				))}
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleAddNutrient}
				>
					+ Add Nutrient
				</button>
			</div>

			{/* More Information */}
			<div className="mb-4">
				<h4>More Information</h4>
				<FormGroup>
					<Label>Additional Details</Label>
					<Input
						type="textarea"
						rows="3"
						placeholder="Enter additional details"
						value={formData.content.moreInformation.additionalDetails}
						onChange={handleMoreDetailsChange}
					/>
				</FormGroup>
			</div>

			{/* Welcome Screen */}
			<div className="mb-4">
				<h4>Welcome Screen</h4>
				<FormGroup>
					<Label>Image</Label>
					<Input type="file" onChange={handleWelcomeImageChange} />
					{formData.content.welcomeScreen.image && (
						<p>Selected file: {formData.content.welcomeScreen.image.name}</p>
					)}
				</FormGroup>
				<FormGroup>
					<Label>Time (seconds)</Label>
					<Input
						type="number"
						step="0.5"
						min="2.5"
						max="10"
						value={formData.content.welcomeScreen.time}
						onChange={handleWelcomeTimeChange}
					/>
					<p>{formData.content.welcomeScreen.time} sec</p>
				</FormGroup>
			</div>
		</div>
	)
}

/* --------------------------
   Configuration Section (id: 4)
-------------------------- */
const ConfigurationSection = ({ formData, setFormData }) => {
	const [showPassword, setShowPassword] = useState(false)
	const togglePassword = () => setShowPassword((prev) => !prev)

	return (
		<div className="p-4 border rounded-lg shadow-md w-full max-w-lg mt-3">
			<h3 className="text-md font-semibold mb-2">Configuration</h3>
			<p className="text-sm text-gray-600 mb-3">
				Password protect your product QR.
			</p>
			<FormGroup check className="mb-3">
				<Input
					type="checkbox"
					checked={formData.configuration.passwordActive}
					onChange={(e) =>
						setFormData((prev) => ({
							...prev,
							configuration: {
								...prev.configuration,
								passwordActive: e.target.checked,
							},
						}))
					}
				/>
				<Label check className="ms-2">
					Activate password to access the QR code
				</Label>
			</FormGroup>
			{formData.configuration.passwordActive && (
				<div style={{ marginLeft: '1.5rem' }}>
					<Label>Password</Label>
					<div className="position-relative">
						<Input
							type={showPassword ? 'text' : 'password'}
							placeholder="Enter password"
							value={formData.configuration.password}
							onChange={(e) =>
								setFormData((prev) => ({
									...prev,
									configuration: {
										...prev.configuration,
										password: e.target.value,
									},
								}))
							}
							required
						/>
						<span
							style={{
								position: 'absolute',
								right: '10px',
								top: '50%',
								transform: 'translateY(-50%)',
								cursor: 'pointer',
							}}
							onClick={togglePassword}
						>
							{showPassword ? <FaEyeSlash /> : <FaEye />}
						</span>
					</div>
				</div>
			)}
		</div>
	)
}

/* ---------------------------------------
   Custom style for the Accordion Headers
---------------------------------------- */
const accordionHeaderStyle = {
	fontSize: '16px',
	fontWeight: 600,
	color: '#000000',
	margin: 0,
}

/* --------------------------
   Main Product Form Component
-------------------------- */
const ProductForm = ({ formData, setFormData, isEdit, qrType }) => {
	const [open, setOpen] = useState('0')
	const toggle = (id) => {
		setOpen(open === id ? '' : id)
	}

	// Accordions with IDs and titles matching landingpageQr.jsx
	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<ShortLinkSection
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<DesignCustomization formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '2',
			title: 'Basic Information',
			icon: infoicon,
			content: (
				<BasicInformation formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '3',
			title: 'Content',
			icon: contenticon,
			content: <ContentSection formData={formData} setFormData={setFormData} />,
		},
		{
			id: '4',
			title: 'Configuration',
			icon: configicon,
			content: (
				<ConfigurationSection formData={formData} setFormData={setFormData} />
			),
		},
	]

	return (
		<Accordion open={open} toggle={toggle} className="mt-4">
			{accordionItems.map((item) => (
				<AccordionItem key={item.id}>
					<AccordionHeader targetId={item.id}>
						<div
							style={accordionHeaderStyle}
							className="d-flex align-items-center"
						>
							<img
								src={item.icon}
								alt={item.title}
								style={{ width: '20px', marginRight: '8px' }}
							/>
							{item.title}
						</div>
					</AccordionHeader>
					<AccordionBody accordionId={item.id}>{item.content}</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

/* --------------------------
   Main Product QR Component
-------------------------- */
const ProductQr = ({ isEdit = false, qrType = 'product' }) => {
	const [formData, setFormData] = useState({
		// Design keys (top-level, as per landingpageQr.jsx)
		primaryColor: '#ffffff',
		ctaColor: '#348ce5',
		// Basic Information keys (product-specific)
		basicInfo: {
			image: null,
			title: '',
			description: '',
			header: '',
			typeOfWine: '',
			varieties: '',
			add: '',
			region: '',
			countryOfOrigin: '',
			store: '',
			geographicalIndication: '',
			alcohol: '',
			amount: '',
			categories: [],
		},
		// Content keys (product-specific)
		content: {
			ingredients: [{ name: 'Grapes' }, { name: 'Sulfites' }],
			nutritionalInfo: {
				qualification: '',
				nutrients: [],
			},
			moreInformation: {
				additionalDetails: '',
				// If not used, these can be omitted or set as empty arrays:
				allergens: [],
				certificates: [],
				organic: [],
				recyclingStamps: [],
			},
			welcomeScreen: {
				image: null,
				time: 2.5,
			},
		},
		// Configuration keys
		configuration: {
			passwordActive: false,
			password: '',
		},
	})

	return (
		<div style={{ padding: '20px' }}>
			<ProductForm
				formData={formData}
				setFormData={setFormData}
				isEdit={isEdit}
				qrType={qrType}
			/>
		</div>
	)
}

export default ProductQr
