import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const EventsPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	const getImageURL = (fileOrUrl) => {
		if (!fileOrUrl) return null
		return typeof fileOrUrl === 'string'
			? fileOrUrl
			: URL.createObjectURL(fileOrUrl)
	}

	const backgroundColor = formData.primaryColor || '#fff'
	const textColor = formData.ctaColor || '#000'
	const buttonColor = formData.ctaColor || '#348ce5'

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div
			style={{
				backgroundColor,
				color: textColor,
				padding: '20px',
				minHeight: '100vh',
			}}
		>
			{/* Header */}
			<header style={{ marginBottom: '20px', textAlign: 'center' }}>
				{formData.coverImage && (
					<img
						src={getImageURL(formData.coverImage)}
						alt="Cover Image"
						style={{
							width: '200px',
							height: '150px',
							objectFit: 'cover',
							marginBottom: '10px',
							borderRadius: '5px',
						}}
					/>
				)}
				<h1 style={{ margin: 0 }}>{formData.title || 'Your Event Title'}</h1>
				{formData.description && (
					<p style={{ margin: 0, fontStyle: 'italic' }}>
						{formData.description}
					</p>
				)}
				{formData.button && formData.button.text && formData.button.url && (
					<a
						href={formData.button.url}
						target="_blank"
						rel="noopener noreferrer"
						style={{
							display: 'inline-block',
							marginTop: '10px',
							padding: '8px 16px',
							backgroundColor: buttonColor,
							color: '#fff',
							borderRadius: '5px',
							textDecoration: 'none',
						}}
					>
						{formData.button.text}
					</a>
				)}
			</header>

			{/* Organizer Information */}
			{formData.organizerInfo && (
				<section style={{ marginBottom: '20px' }}>
					<h3 style={{ marginBottom: '10px' }}>Organizer Information</h3>
					{formData.organizerInfo.firstName && (
						<p>
							<strong>First Name:</strong> {formData.organizerInfo.firstName}
						</p>
					)}
					{formData.organizerInfo.website && (
						<p>
							<strong>Website:</strong>{' '}
							<a
								href={formData.organizerInfo.website}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: buttonColor }}
							>
								{formData.organizerInfo.website}
							</a>
						</p>
					)}
					{formData.organizerInfo.phones?.length > 0 && (
						<p>
							<strong>Phones:</strong>{' '}
							{formData.organizerInfo.phones.join(', ')}
						</p>
					)}
					{formData.organizerInfo.emails?.length > 0 && (
						<p>
							<strong>Emails:</strong>{' '}
							{formData.organizerInfo.emails.join(', ')}
						</p>
					)}
				</section>
			)}

			{/* Event Date */}
			{formData.eventDate && (
				<section style={{ marginBottom: '20px' }}>
					<h3 style={{ marginBottom: '10px' }}>Event Date</h3>
					<p>
						<strong>Time Format:</strong> {formData.eventDate.timeFormat}
					</p>
					{formData.eventDate.since && (
						<p>
							<strong>Since:</strong> {formData.eventDate.since}
						</p>
					)}
					{formData.eventDate.until && (
						<p>
							<strong>Until:</strong> {formData.eventDate.until}
						</p>
					)}
					{formData.eventDate.timezone && (
						<p>
							<strong>Timezone:</strong> {formData.eventDate.timezone}
						</p>
					)}
					{formData.eventDate.allDay && (
						<p>
							<strong>All Day:</strong> Yes
						</p>
					)}
				</section>
			)}

			{/* Location */}
			{formData.location && (
				<section style={{ marginBottom: '20px' }}>
					<h3 style={{ marginBottom: '10px' }}>Location</h3>
					{formData.location.search && (
						<p>Search: {formData.location.search}</p>
					)}
					{formData.location.street && (
						<p>
							{formData.streetNumberFirst
								? `${formData.location.number} ${formData.location.street}`
								: `${formData.location.street} ${formData.location.number}`}
						</p>
					)}
					{formData.location.postalCode && (
						<p>Postal Code: {formData.location.postalCode}</p>
					)}
					{formData.location.city && <p>City: {formData.location.city}</p>}
					{formData.location.stateProvince && (
						<p>State/Province: {formData.location.stateProvince}</p>
					)}
					{formData.location.country && (
						<p>Country: {formData.location.country}</p>
					)}
				</section>
			)}

			{/* Facilities */}
			{formData.facilities?.length > 0 && (
				<section style={{ marginBottom: '20px' }}>
					<h3 style={{ marginBottom: '10px' }}>Facilities</h3>
					<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
						{formData.facilities.map((facility) => (
							<span
								key={facility}
								style={{
									padding: '5px 10px',
									backgroundColor: buttonColor,
									color: '#fff',
									borderRadius: '5px',
								}}
							>
								{facility.charAt(0).toUpperCase() + facility.slice(1)}
							</span>
						))}
					</div>
				</section>
			)}

			{/* About Us */}
			{formData.aboutUs && (
				<section style={{ marginBottom: '20px' }}>
					<h3 style={{ marginBottom: '10px' }}>About Us</h3>
					<p>{formData.aboutUs}</p>
				</section>
			)}

			{/* Custom Buttons */}
			{formData.customButtons && (
				<section style={{ marginBottom: '20px' }}>
					<h3 style={{ marginBottom: '10px' }}>Custom Buttons</h3>
					<div>
						{formData.customButtons.addToCalendar && (
							<a
								href="#"
								style={{
									display: 'inline-block',
									marginTop: '10px',
									padding: '8px 16px',
									backgroundColor: buttonColor,
									color: '#fff',
									borderRadius: '5px',
									textDecoration: 'none',
								}}
							>
								Add to calendar
							</a>
						)}
						{formData.customButtons.shareButton && (
							<button
								style={{
									display: 'inline-block',
									marginTop: '10px',
									marginLeft: '10px',
									padding: '8px 16px',
									backgroundColor: buttonColor,
									color: '#fff',
									borderRadius: '5px',
									border: 'none',
									cursor: 'pointer',
								}}
							>
								Share
							</button>
						)}
					</div>
				</section>
			)}

			{/* Welcome Message */}
			{formData.welcomeMessage && (
				<section style={{ marginBottom: '20px' }}>
					<h3 style={{ marginBottom: '10px' }}>Welcome Message</h3>
					<p>{formData.welcomeMessage}</p>
				</section>
			)}
		</div>
	)
}

export default EventsPreview
