import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
	Button,
} from 'reactstrap'

import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import socialicon from '../../../../assets/qr-icons/social-media.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import uploadicon from '../../../../assets/qr-icons/upload.png'
import configicon from '../../../../assets/qr-icons/config.png'
import QrLink from '../QrLink'
import AdYoutubeVideos from './components/adYoutubeLinks'

// --------------------------------------------------
// Sub-Accordion for the "Content" item:
// 7 sections: Opening Hours, Location, Evaluations,
// Contact Info, Social Networks, Additional Info, Welcome Screen
// --------------------------------------------------
function ContentSubAccordion() {
	const [openSub, setOpenSub] = useState('')
	const toggleSub = (id) => {
		setOpenSub((prev) => (prev === id ? '' : id))
	}

	// 1) Opening Hours
	const [timeFormat, setTimeFormat] = useState('am/pm')
	const daysOfWeek = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	]
	const [openingHours, setOpeningHours] = useState(
		daysOfWeek.map((day) => ({
			day,
			intervals: [{ start: '08:00 am', end: '08:00 am' }],
		}))
	)

	const handleFormatChange = (format) => {
		setTimeFormat(format)
	}

	const handleTimeChange = (dayIndex, intervalIndex, field, value) => {
		setOpeningHours((prev) => {
			const updated = [...prev]
			const dayData = { ...updated[dayIndex] }
			const intervalsCopy = [...dayData.intervals]
			intervalsCopy[intervalIndex] = {
				...intervalsCopy[intervalIndex],
				[field]: value,
			}
			dayData.intervals = intervalsCopy
			updated[dayIndex] = dayData
			return updated
		})
	}

	const handleAddInterval = (dayIndex) => {
		setOpeningHours((prev) => {
			const updated = [...prev]
			const dayData = { ...updated[dayIndex] }
			dayData.intervals = [
				...dayData.intervals,
				{ start: '08:00 am', end: '08:00 am' },
			]
			updated[dayIndex] = dayData
			return updated
		})
	}

	const handleRemoveInterval = (dayIndex, intervalIndex) => {
		setOpeningHours((prev) => {
			const updated = [...prev]
			const dayData = { ...updated[dayIndex] }
			dayData.intervals = dayData.intervals.filter(
				(_, idx) => idx !== intervalIndex
			)
			updated[dayIndex] = dayData
			return updated
		})
	}

	// 2) Location
	const [searchAddress, setSearchAddress] = useState('')
	const [streetNumberFirst, setStreetNumberFirst] = useState(false)
	const [street, setStreet] = useState('')
	const [number, setNumber] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const [city, setCity] = useState('')
	const [stateProvince, setStateProvince] = useState('')
	const [country, setCountry] = useState('')
	const handleDeleteAddress = () => {
		setSearchAddress('')
		setStreetNumberFirst(false)
		setStreet('')
		setNumber('')
		setPostalCode('')
		setCity('')
		setStateProvince('')
		setCountry('')
	}

	// 3) Evaluations
	const [allowReviews, setAllowReviews] = useState(false)
	const [onlyPositive, setOnlyPositive] = useState(false)
	const [showRatings, setShowRatings] = useState(false)
	const [sortByTopRated, setSortByTopRated] = useState(false)

	// 4) Contact Info
	const [telephone, setTelephone] = useState('')
	const [email, setEmail] = useState('')
	const [website, setWebsite] = useState('')

	// 5) Social Networks
	const [socialTitle, setSocialTitle] = useState('')

	// 6) Additional Info
	const [additionalInfo, setAdditionalInfo] = useState('')

	// 7) Welcome Screen
	const [welcomeImage, setWelcomeImage] = useState(null)
	const [welcomeTime, setWelcomeTime] = useState(2.5)

	const handleImageChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			setWelcomeImage(e.target.files[0])
		}
	}

	const handlePreview = () => {
		alert('Previewing welcome screen...')
	}

	return (
		<Accordion open={openSub} toggle={toggleSub} className="mt-3">
			{/* 1) Opening Hours */}
			<AccordionItem>
				<AccordionHeader targetId="sub1">Opening hours</AccordionHeader>
				<AccordionBody accordionId="sub1">
					<div style={{ marginBottom: '1rem' }}>
						<Label style={{ marginRight: '1rem' }}>
							<Input
								type="radio"
								name="timeFormat"
								value="am/pm"
								checked={timeFormat === 'am/pm'}
								onChange={() => handleFormatChange('am/pm')}
							/>
							&nbsp;am/pm
						</Label>
						<Label>
							<Input
								type="radio"
								name="timeFormat"
								value="24hrs"
								checked={timeFormat === '24hrs'}
								onChange={() => handleFormatChange('24hrs')}
							/>
							&nbsp;24 hrs
						</Label>
					</div>

					{openingHours.map((dayData, dayIndex) => (
						<div key={dayData.day} style={{ marginBottom: '1rem' }}>
							<strong>{dayData.day}</strong>
							{dayData.intervals.map((interval, intervalIndex) => (
								<div
									key={intervalIndex}
									style={{
										display: 'flex',
										gap: '0.5rem',
										marginTop: '0.5rem',
									}}
								>
									<Input
										type="text"
										value={interval.start}
										onChange={(e) =>
											handleTimeChange(
												dayIndex,
												intervalIndex,
												'start',
												e.target.value
											)
										}
										style={{ width: '100px' }}
									/>
									<span>to</span>
									<Input
										type="text"
										value={interval.end}
										onChange={(e) =>
											handleTimeChange(
												dayIndex,
												intervalIndex,
												'end',
												e.target.value
											)
										}
										style={{ width: '100px' }}
									/>
									{dayData.intervals.length > 1 && (
										<Button
											color="danger"
											size="sm"
											onClick={() =>
												handleRemoveInterval(dayIndex, intervalIndex)
											}
										>
											-
										</Button>
									)}
								</div>
							))}
							<Button
								color="primary"
								size="sm"
								style={{ marginTop: '0.5rem' }}
								onClick={() => handleAddInterval(dayIndex)}
							>
								+
							</Button>
						</div>
					))}
				</AccordionBody>
			</AccordionItem>

			{/* 2) Location */}
			<AccordionItem>
				<AccordionHeader targetId="sub2">Location</AccordionHeader>
				<AccordionBody accordionId="sub2">
					<div className="mb-3">
						<Label>Search address</Label>
						<Input
							type="text"
							placeholder="Enter or search address"
							value={searchAddress}
							onChange={(e) => setSearchAddress(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<Label check>
							<Input
								type="checkbox"
								checked={streetNumberFirst}
								onChange={() => setStreetNumberFirst(!streetNumberFirst)}
							/>
							&nbsp;Street number first
						</Label>
					</div>
					<Button
						color="danger"
						outline
						className="mb-3"
						onClick={handleDeleteAddress}
					>
						Delete address
					</Button>
					<div className="row">
						<div className="col-md-3 mb-3">
							<Label>Street</Label>
							<Input
								type="text"
								value={street}
								onChange={(e) => setStreet(e.target.value)}
							/>
						</div>
						<div className="col-md-3 mb-3">
							<Label>Number</Label>
							<Input
								type="text"
								value={number}
								onChange={(e) => setNumber(e.target.value)}
							/>
						</div>
						<div className="col-md-3 mb-3">
							<Label>Postal Code</Label>
							<Input
								type="text"
								value={postalCode}
								onChange={(e) => setPostalCode(e.target.value)}
							/>
						</div>
						<div className="col-md-3 mb-3">
							<Label>City</Label>
							<Input
								type="text"
								value={city}
								onChange={(e) => setCity(e.target.value)}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 mb-3">
							<Label>State / Province</Label>
							<Input
								type="text"
								value={stateProvince}
								onChange={(e) => setStateProvince(e.target.value)}
							/>
						</div>
						<div className="col-md-4 mb-3">
							<Label>Country</Label>
							<Input
								type="text"
								value={country}
								onChange={(e) => setCountry(e.target.value)}
							/>
						</div>
					</div>
				</AccordionBody>
			</AccordionItem>

			{/* 3) Evaluations */}
			<AccordionItem>
				<AccordionHeader targetId="sub3">Evaluations</AccordionHeader>
				<AccordionBody accordionId="sub3">
					<div className="mb-3">
						<Label check>
							<Input
								type="checkbox"
								checked={allowReviews}
								onChange={() => setAllowReviews(!allowReviews)}
							/>
							&nbsp;Allow reviews
						</Label>
					</div>
					<div className="mb-3">
						<Label check>
							<Input
								type="checkbox"
								checked={onlyPositive}
								onChange={() => setOnlyPositive(!onlyPositive)}
							/>
							&nbsp;Only positive reviews
						</Label>
					</div>
					<div className="mb-3">
						<Label check>
							<Input
								type="checkbox"
								checked={showRatings}
								onChange={() => setShowRatings(!showRatings)}
							/>
							&nbsp;Show ratings in the Menu
						</Label>
					</div>
					<div className="mb-3">
						<Label check>
							<Input
								type="checkbox"
								checked={sortByTopRated}
								onChange={() => setSortByTopRated(!sortByTopRated)}
							/>
							&nbsp;Sort by top rated
						</Label>
					</div>
				</AccordionBody>
			</AccordionItem>

			{/* 4) Contact Information */}
			<AccordionItem>
				<AccordionHeader targetId="sub4">Contact information</AccordionHeader>
				<AccordionBody accordionId="sub4">
					<div className="row">
						<div className="col-md-6 mb-3">
							<Label>Telephone</Label>
							<Input
								type="text"
								placeholder="E.g. 00000 000000"
								value={telephone}
								onChange={(e) => setTelephone(e.target.value)}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Label>Email</Label>
							<Input
								type="email"
								placeholder="E.g. name@email.com"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
					<div className="mb-3">
						<Label>Website</Label>
						<Input
							type="text"
							placeholder="https://..."
							value={website}
							onChange={(e) => setWebsite(e.target.value)}
						/>
					</div>
				</AccordionBody>
			</AccordionItem>

			{/* 5) Social Networks */}
			<AccordionItem>
				<AccordionHeader targetId="sub5">Social Networks</AccordionHeader>
				<AccordionBody accordionId="sub5">
					<Label>Title</Label>
					<Input
						type="text"
						placeholder="Add a heading for your social icons (optional)"
						className="mb-3"
						value={socialTitle}
						onChange={(e) => setSocialTitle(e.target.value)}
					/>
					<p>Select or add social networks:</p>
					<div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
						<Button size="sm">Facebook</Button>
						<Button size="sm">Instagram</Button>
						<Button size="sm">Twitter</Button>
						<Button size="sm">LinkedIn</Button>
						<Button size="sm">YouTube</Button>
					</div>
				</AccordionBody>
			</AccordionItem>

			{/* 6) Additional Information */}
			<AccordionItem>
				<AccordionHeader targetId="sub6">
					Additional Information
				</AccordionHeader>
				<AccordionBody accordionId="sub6">
					<Label>Enter a text...</Label>
					<Input
						type="textarea"
						rows="5"
						placeholder="Enter a text..."
						className="mt-2"
						value={additionalInfo}
						onChange={(e) => setAdditionalInfo(e.target.value)}
					/>
				</AccordionBody>
			</AccordionItem>

			{/* 7) Welcome Screen */}
			<AccordionItem>
				<AccordionHeader targetId="sub7">Welcome screen</AccordionHeader>
				<AccordionBody accordionId="sub7">
					<div className="mb-3">
						<Label>Image</Label>
						<Input type="file" accept="image/*" onChange={handleImageChange} />
					</div>
					<Button color="secondary" className="mb-3" onClick={handlePreview}>
						Preview
					</Button>
					<div>
						<Label>Time (seconds)</Label>
						<Input
							type="range"
							min="2.5"
							max="10"
							step="0.5"
							value={welcomeTime}
							onChange={(e) => setWelcomeTime(e.target.value)}
							style={{ width: '300px' }}
						/>
						<p>{welcomeTime} sec</p>
					</div>
				</AccordionBody>
			</AccordionItem>
		</Accordion>
	)
}

// --------------------------------------------------
// 1) Time Scheduling (Tailwind CSS)
// --------------------------------------------------
function TimeScheduling() {
	// Toggle for enabling/disabling time ranges
	const [enableRanges, setEnableRanges] = useState(false)

	// Form fields for creating a single time range
	const [rangeName, setRangeName] = useState('')
	const [days, setDays] = useState('')
	const [startTime, setStartTime] = useState('08:00 am')
	const [endTime, setEndTime] = useState('08:00 pm')

	// Array of created time ranges
	const [timeRanges, setTimeRanges] = useState([])

	// Create a new range and add it to the list
	const handleCreateRange = () => {
		if (!rangeName.trim()) {
			alert('Please enter a valid name for the time range.')
			return
		}
		const newRange = {
			id: Date.now(),
			name: rangeName,
			days,
			start: startTime,
			end: endTime,
		}
		setTimeRanges((prev) => [...prev, newRange])
		// Reset fields
		setRangeName('')
		setDays('')
		setStartTime('08:00 am')
		setEndTime('08:00 pm')
	}

	return (
		<div className="max-w-xl mx-auto p-4">
			<h2 className="text-2xl font-semibold mb-2">Time Scheduling</h2>
			<p className="text-gray-600 mb-4">
				Create the time ranges for your business and then apply them to your
				products.
			</p>

			{/* Enable time ranges toggle */}
			<div className="mb-4">
				<label className="inline-flex items-center space-x-2 font-medium">
					<input
						type="checkbox"
						className="form-checkbox h-5 w-5 text-blue-600"
						checked={enableRanges}
						onChange={() => setEnableRanges(!enableRanges)}
					/>
					<span>Enable time ranges</span>
				</label>
			</div>

			{/* Time range creation form */}
			{enableRanges && (
				<div className="bg-gray-50 p-4 rounded-md mb-4 space-y-4">
					{/* Name */}
					<div>
						<label className="block font-medium mb-1">Name</label>
						<input
							type="text"
							className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
							placeholder="Write here the name of the time range (e.g. Morning...)"
							value={rangeName}
							onChange={(e) => setRangeName(e.target.value)}
						/>
					</div>

					{/* Days */}
					<div>
						<label className="block font-medium mb-1">Days</label>
						<select
							className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
							value={days}
							onChange={(e) => setDays(e.target.value)}
						>
							<option value="">Select</option>
							<option value="monday">Monday</option>
							<option value="tuesday">Tuesday</option>
							<option value="wednesday">Wednesday</option>
							<option value="thursday">Thursday</option>
							<option value="friday">Friday</option>
							<option value="saturday">Saturday</option>
							<option value="sunday">Sunday</option>
						</select>
					</div>

					{/* Start / End times */}
					<div className="flex space-x-4">
						<div className="flex-1">
							<label className="block font-medium mb-1">Start</label>
							<input
								type="text"
								className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
								value={startTime}
								onChange={(e) => setStartTime(e.target.value)}
							/>
						</div>
						<div className="flex-1">
							<label className="block font-medium mb-1">End</label>
							<input
								type="text"
								className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
								value={endTime}
								onChange={(e) => setEndTime(e.target.value)}
							/>
						</div>
					</div>

					{/* Create button */}
					<button
						onClick={handleCreateRange}
						className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
					>
						Create
					</button>
				</div>
			)}

			{/* Warning if no ranges exist */}
			{enableRanges && timeRanges.length === 0 && (
				<p className="text-red-500">Must have at least 1 range</p>
			)}

			{/* Display created ranges (optional) */}
			{enableRanges && timeRanges.length > 0 && (
				<div>
					<h4 className="text-lg font-semibold mb-2">Created Ranges:</h4>
					<ul className="list-disc list-inside space-y-1">
						{timeRanges.map((range) => (
							<li key={range.id}>
								<strong>{range.name}</strong> | Days: {range.days || 'N/A'} |{' '}
								{range.start} - {range.end}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	)
}

// --------------------------------------------------
// Subcomponent: BasicInformationSection
// --------------------------------------------------
function BasicInformationSection() {
	// LANGUAGES
	const [languages, setLanguages] = useState(['ARAB']) // example default
	const [newLanguage, setNewLanguage] = useState('')

	const addLanguage = () => {
		if (newLanguage && !languages.includes(newLanguage)) {
			setLanguages((prev) => [...prev, newLanguage])
			setNewLanguage('')
		}
	}
	const removeLanguage = (lang) => {
		setLanguages((prev) => prev.filter((l) => l !== lang))
	}

	// MENU INFO
	const [establishmentName, setEstablishmentName] = useState('')
	const [menuDescription, setMenuDescription] = useState('')
	const [deleteTitle, setDeleteTitle] = useState(false)
	const [hideAllergen, setHideAllergen] = useState(false)

	// DYNAMIC SECTIONS
	const [sections, setSections] = useState([
		{
			id: 1,
			name: '',
			description: '',
			products: [
				{
					id: 1,
					showItem: true,
					image: null,
					name: '',
					translatedName: '',
					description: '',
					multiplesPrices: false,
					price: '',
					allergens: [],
				},
			],
		},
	])

	const addSection = () => {
		setSections((prev) => [
			...prev,
			{
				id: Date.now(),
				name: '',
				description: '',
				products: [
					{
						id: Date.now(),
						showItem: true,
						image: null,
						name: '',
						translatedName: '',
						description: '',
						multiplesPrices: false,
						price: '',
						allergens: [],
					},
				],
			},
		])
	}

	const removeSection = (sectionId) => {
		setSections((prev) => prev.filter((s) => s.id !== sectionId))
	}

	const handleSectionChange = (sectionId, field, value) => {
		setSections((prev) =>
			prev.map((section) =>
				section.id === sectionId ? { ...section, [field]: value } : section
			)
		)
	}

	// PRODUCTS
	const addProduct = (sectionId) => {
		setSections((prev) =>
			prev.map((section) => {
				if (section.id === sectionId) {
					const newProduct = {
						id: Date.now(),
						showItem: true,
						image: null,
						name: '',
						translatedName: '',
						description: '',
						multiplesPrices: false,
						price: '',
						allergens: [],
					}
					return { ...section, products: [...section.products, newProduct] }
				}
				return section
			})
		)
	}

	const removeProduct = (sectionId, productId) => {
		setSections((prev) =>
			prev.map((section) => {
				if (section.id === sectionId) {
					const updatedProducts = section.products.filter(
						(p) => p.id !== productId
					)
					return { ...section, products: updatedProducts }
				}
				return section
			})
		)
	}

	const handleProductChange = (sectionId, productId, field, value) => {
		setSections((prev) =>
			prev.map((section) => {
				if (section.id === sectionId) {
					const updatedProducts = section.products.map((p) =>
						p.id === productId ? { ...p, [field]: value } : p
					)
					return { ...section, products: updatedProducts }
				}
				return section
			})
		)
	}

	const handleProductImage = (sectionId, productId, file) => {
		setSections((prev) =>
			prev.map((section) => {
				if (section.id === sectionId) {
					const updatedProducts = section.products.map((p) =>
						p.id === productId ? { ...p, image: file } : p
					)
					return { ...section, products: updatedProducts }
				}
				return section
			})
		)
	}

	// IMAGES OF THE MENU
	const [companyLogo, setCompanyLogo] = useState(null)
	const [coverImage, setCoverImage] = useState(null)

	const handleSave = () => {
		const finalData = {
			languages,
			establishmentName,
			menuDescription,
			deleteTitle,
			hideAllergen,
			sections,
			companyLogo,
			coverImage,
		}
		console.log('Basic Info final data:', finalData)
		alert('Check console for final Basic Info data.')
	}

	return (
		<div style={{ padding: '1rem', border: '1px solid #ccc' }}>
			{/* <h4>Basic Information</h4> */}
			<p>Please choose the language(s) your menu will be displayed in.</p>

			{/* Languages */}
			<div style={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem' }}>
				{languages.map((lang) => (
					<Button
						key={lang}
						color="success"
						onClick={() => removeLanguage(lang)}
					>
						{lang} &times;
					</Button>
				))}
				<Input
					type="text"
					placeholder="Add language"
					style={{ width: '120px' }}
					value={newLanguage}
					onChange={(e) => setNewLanguage(e.target.value)}
				/>
				<Button onClick={addLanguage}>+ add language</Button>
			</div>
			<p className="text-muted">Complete your menu in all added languages.</p>

			{/* Menu Information */}
			<h5>Menu information</h5>
			<div className="mb-3">
				<Label>Name of establishment</Label>
				<Input
					type="text"
					placeholder="E.g. Ristorante Tavolo Grande"
					value={establishmentName}
					onChange={(e) => setEstablishmentName(e.target.value)}
				/>
			</div>
			<div className="mb-3">
				<Label>Description</Label>
				<Input
					type="textarea"
					placeholder="E.g. Italian Trattoria"
					value={menuDescription}
					onChange={(e) => setMenuDescription(e.target.value)}
				/>
			</div>

			<div className="mb-3">
				<Label check>
					<Input
						type="checkbox"
						checked={deleteTitle}
						onChange={() => setDeleteTitle(!deleteTitle)}
					/>
					&nbsp;Delete title
				</Label>
			</div>
			<div className="mb-3">
				<Label check>
					<Input
						type="checkbox"
						checked={hideAllergen}
						onChange={() => setHideAllergen(!hideAllergen)}
					/>
					&nbsp;Hide allergen selector
				</Label>
			</div>

			<h5>Menu</h5>
			{sections.map((section) => (
				<div
					key={section.id}
					style={{
						border: '1px solid #ddd',
						padding: '1rem',
						marginBottom: '1rem',
					}}
				>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h6>Section {section.id}</h6>
						<Button
							color="danger"
							size="sm"
							onClick={() => removeSection(section.id)}
						>
							Delete section
						</Button>
					</div>
					<div className="mb-3">
						<Label>Name *</Label>
						<Input
							type="text"
							placeholder="E.g. Desserts"
							required
							value={section.name}
							onChange={(e) =>
								handleSectionChange(section.id, 'name', e.target.value)
							}
						/>
					</div>
					<div className="mb-3">
						<Label>Description</Label>
						<Input
							type="textarea"
							placeholder="E.g. Selection of homemade desserts"
							value={section.description}
							onChange={(e) =>
								handleSectionChange(section.id, 'description', e.target.value)
							}
						/>
					</div>
					<div className="mb-3">
						<Label>Time Scheduling</Label>
						<Button size="sm" color="primary" style={{ marginLeft: '1rem' }}>
							Assign range
						</Button>
					</div>

					{/* Products */}
					{section.products.map((product) => (
						<div
							key={product.id}
							style={{
								border: '1px solid #ccc',
								padding: '1rem',
								marginBottom: '1rem',
							}}
						>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h6>Product {product.id}</h6>
								<div>
									<Label check style={{ marginRight: '1rem' }}>
										<Input
											type="checkbox"
											checked={product.showItem}
											onChange={(e) =>
												handleProductChange(
													section.id,
													product.id,
													'showItem',
													e.target.checked
												)
											}
										/>
										&nbsp;Show item
									</Label>
									<Button
										color="danger"
										size="sm"
										onClick={() => removeProduct(section.id, product.id)}
									>
										Delete product
									</Button>
								</div>
							</div>

							<div className="mb-3">
								<Label>Image</Label>
								<Input
									type="file"
									accept="image/*"
									onChange={(e) => {
										if (e.target.files?.[0]) {
											handleProductImage(
												section.id,
												product.id,
												e.target.files[0]
											)
										}
									}}
								/>
							</div>
							<div className="mb-3">
								<Label>Name *</Label>
								<Input
									type="text"
									placeholder="E.g. Rice Pudding"
									required
									value={product.name}
									onChange={(e) =>
										handleProductChange(
											section.id,
											product.id,
											'name',
											e.target.value
										)
									}
								/>
							</div>
							<div className="mb-3">
								<Label>Translated name (optional)</Label>
								<Input
									type="text"
									placeholder="E.g. Arroz con leche"
									value={product.translatedName}
									onChange={(e) =>
										handleProductChange(
											section.id,
											product.id,
											'translatedName',
											e.target.value
										)
									}
								/>
							</div>
							<div className="mb-3">
								<Label>Description</Label>
								<Input
									type="textarea"
									placeholder="E.g. As good as your grandmother's"
									value={product.description}
									onChange={(e) =>
										handleProductChange(
											section.id,
											product.id,
											'description',
											e.target.value
										)
									}
								/>
							</div>
							<div className="mb-3">
								<Label check>
									<Input
										type="checkbox"
										checked={product.multiplesPrices}
										onChange={(e) =>
											handleProductChange(
												section.id,
												product.id,
												'multiplesPrices',
												e.target.checked
											)
										}
									/>
									&nbsp;Multiples prices
								</Label>
							</div>
							<div className="mb-3">
								<Label>Price (optional)</Label>
								<Input
									type="text"
									placeholder="E.g. £4.50"
									value={product.price}
									onChange={(e) =>
										handleProductChange(
											section.id,
											product.id,
											'price',
											e.target.value
										)
									}
								/>
							</div>
							{/* Allergen icons (placeholder) */}
							<div className="mb-3">
								<Label>Allergens</Label>
								<div
									style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}
								>
									{/* Example icons, no real logic */}
									<img
										src="path/to/allergen1.png"
										alt="Allergen 1"
										style={{ width: '40px', height: '40px' }}
									/>
									<img
										src="path/to/allergen2.png"
										alt="Allergen 2"
										style={{ width: '40px', height: '40px' }}
									/>
								</div>
							</div>
						</div>
					))}

					<Button
						color="primary"
						size="sm"
						onClick={() => addProduct(section.id)}
					>
						+ Add product
					</Button>
				</div>
			))}
			<Button color="primary" className="mb-3" onClick={addSection}>
				+ Add section
			</Button>

			<h5>Images of the menu</h5>
			<div className="mb-3">
				<Label>Company logo</Label>
				<Input
					type="file"
					accept="image/*"
					onChange={(e) => {
						if (e.target.files?.[0]) setCompanyLogo(e.target.files[0])
					}}
				/>
			</div>
			<div className="mb-3">
				<Label>Cover image</Label>
				<Input
					type="file"
					accept="image/*"
					onChange={(e) => {
						if (e.target.files?.[0]) setCoverImage(e.target.files[0])
					}}
				/>
			</div>

			<Button color="success" onClick={handleSave}>
				Save Basic Info
			</Button>
		</div>
	)
}

// --------------------------------------------------
// "Configuration" Sub-Accordion
// --------------------------------------------------
function ConfigurationSection() {
	const [openConfig, setOpenConfig] = useState('')
	const toggleConfig = (id) => {
		setOpenConfig((prev) => (prev === id ? '' : id))
	}

	// Password logic
	const [activatePassword, setActivatePassword] = useState(false)
	const [qrPassword, setQrPassword] = useState('')

	return (
		<Accordion open={openConfig} toggle={toggleConfig} className="mt-3">
			{/* Folder */}
			{/* <AccordionItem>
          <AccordionHeader targetId="cfg1">Folder</AccordionHeader>
          <AccordionBody accordionId="cfg1">
            <p>Placeholder for folder configuration.</p>
          </AccordionBody>
        </AccordionItem> */}

			{/* Domain */}
			{/* <AccordionItem>
          <AccordionHeader targetId="cfg2">Domain</AccordionHeader>
          <AccordionBody accordionId="cfg2">
            <p>Placeholder for domain configuration.</p>
          </AccordionBody>
        </AccordionItem> */}

			{/* Password */}
			<AccordionItem>
				<AccordionHeader targetId="cfg3">Password</AccordionHeader>
				<AccordionBody accordionId="cfg3">
					<p>
						Add an access control to display the content and a password will be
						requested each time the QR is scanned.
					</p>
					<div className="mb-3">
						<Label check>
							<Input
								type="checkbox"
								checked={activatePassword}
								onChange={() => setActivatePassword(!activatePassword)}
							/>
							&nbsp;Activate password to access the QR code
						</Label>
					</div>
					{activatePassword && (
						<div style={{ marginLeft: '1.5rem' }}>
							<Label>Password</Label>
							<Input
								type="password"
								placeholder="Enter password"
								value={qrPassword}
								onChange={(e) => setQrPassword(e.target.value)}
							/>
						</div>
					)}
				</AccordionBody>
			</AccordionItem>
		</Accordion>
	)
}

// --------------------------------------------------
// Main MenuForm
// --------------------------------------------------
function MenuForm({ formData, setFormData, isEdit, qrType }) {
	const [open, setOpen] = useState('0')

	// Time Scheduling (existing states)
	const [enableTimeRanges, setEnableTimeRanges] = useState(false)
	const [timeRangeName, setTimeRangeName] = useState('')
	const [selectedDays, setSelectedDays] = useState([])
	const [startTime, setStartTime] = useState('08:00')
	const [endTime, setEndTime] = useState('20:00')

	const handleDaysChange = (event) => {
		const options = event.target.options
		const values = []
		for (let i = 0; i < options.length; i++) {
			if (options[i].selected) {
				values.push(options[i].value)
			}
		}
		setSelectedDays(values)
	}

	const toggle = (id) => {
		setOpen((prev) => (prev === id ? '' : id))
	}

	// For other global form data
	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}))
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center">
					{/* Primary Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.primaryColor}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
					{/* Button Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
							/>
							<Input
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '2',
			icon: uploadicon,
			title: 'Time Scheduling',
			content: <TimeScheduling />,
		},
		{
			id: '3',
			icon: infoicon,
			title: 'Basic Information',
			content: <BasicInformationSection />,
		},
		{
			id: '4',
			icon: socialicon,
			title: 'Content',
			// 7 sub-sections: Opening Hours, Location, etc.
			content: <ContentSubAccordion />,
		},
		{
			id: '5',
			icon: configicon,
			title: 'Configuration',
			content: <ConfigurationSection />,
		},
	]

	return (
		<>
			<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
				{accordionItems.map((item) => (
					<AccordionItem key={item.id}>
						<AccordionHeader targetId={item.id}>
							<img src={item.icon} alt={item.title} className="w-30px me-4" />
							{item.title}
						</AccordionHeader>
						<AccordionBody accordionId={item.id}>
							{item.content}
							{item.fields && (
								<Form>
									<div className="row">
										{item.fields.map((field) => (
											<div
												key={field.id}
												className={`col-md-${field.colSize || 12} mb-3`}
											>
												<FormGroup>
													<Label className="fs-5 fw-bold" for={field.id}>
														{field.label}
													</Label>
													<Input
														type={field.type}
														className={field.className}
														maxLength={field.maxLength}
														id={field.id}
														placeholder={field.placeholder}
														value={
															field.type !== 'file' && formData[field?.id]
																? formData[field?.id]
																: ''
														}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
										))}
									</div>
								</Form>
							)}
						</AccordionBody>
					</AccordionItem>
				))}
			</Accordion>
		</>
	)
}

export default MenuForm
