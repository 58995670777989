import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const SocialmediaPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])
	// Helper function to get a URL for file objects or return string URLs directly
	const getImageURL = (fileOrUrl) => {
		if (!fileOrUrl) return null
		return typeof fileOrUrl === 'string'
			? fileOrUrl
			: URL.createObjectURL(fileOrUrl)
	}

	// Styling
	const backgroundColor = formData.primaryColor || '#fff'
	const textColor = '#000' // or use formData.ctaColor if you want text to match CTA color
	const buttonColor = formData.ctaColor || '#348ce5'

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div
			style={{
				backgroundColor: backgroundColor,
				color: textColor,
				padding: '20px',
				minHeight: '100vh',
			}}
		>
			{/* Header: Logo and Title */}
			<header style={{ marginBottom: '20px', textAlign: 'center' }}>
				{formData.logo && (
					<img
						src={getImageURL(formData.logo)}
						alt="Logo"
						style={{
							width: '60px',
							height: '60px',
							objectFit: 'contain',
							marginBottom: '10px',
						}}
					/>
				)}
				<h1 style={{ margin: 0 }}>
					{formData.title || 'Your Social Media Profile'}
				</h1>
				{formData.description && (
					<p style={{ margin: 0, fontStyle: 'italic' }}>
						{formData.description}
					</p>
				)}
			</header>

			{/* Social Networks */}
			<section style={{ marginBottom: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Social Networks</h3>
				{formData.socialNetworks && formData.socialNetworks.length > 0 && (
					<ul style={{ paddingLeft: '20px' }}>
						{formData.socialNetworks.map((social, index) => (
							<li key={index} style={{ marginBottom: '10px' }}>
								{social.platform && (
									<strong>
										{social.platform.charAt(0).toUpperCase() +
											social.platform.slice(1)}
										:
									</strong>
								)}{' '}
								<a
									href={social.url}
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: buttonColor }}
								>
									{social.url || 'No URL'}
								</a>
								{social.text && (
									<span style={{ marginLeft: '10px' }}> - {social.text}</span>
								)}
							</li>
						))}
					</ul>
				)}
			</section>

			{/* Location */}
			<section style={{ marginBottom: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Location</h3>
				{formData.location && (
					<div>
						{formData.location.search && (
							<p>Search: {formData.location.search}</p>
						)}
						{formData.location.street && (
							<p>
								{formData.streetNumberFirst
									? `${formData.location.number} ${formData.location.street}`
									: `${formData.location.street} ${formData.location.number}`}
							</p>
						)}
						{formData.location.postalCode && (
							<p>Postal Code: {formData.location.postalCode}</p>
						)}
						{formData.location.city && <p>City: {formData.location.city}</p>}
						{formData.location.stateProvince && (
							<p>State/Province: {formData.location.stateProvince}</p>
						)}
						{formData.location.country && (
							<p>Country: {formData.location.country}</p>
						)}
					</div>
				)}
			</section>

			{/* Images */}
			<section style={{ marginBottom: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Images</h3>
				{formData.images && formData.images.length > 0 && (
					<div
						style={{
							display: formData.viewType === 'horizontal' ? 'flex' : 'block',
						}}
					>
						{formData.images.map((image, index) => (
							<img
								key={index}
								src={getImageURL(image)}
								alt={`Image ${index + 1}`}
								style={{
									width: formData.viewType.startsWith('grid')
										? '100px'
										: '150px',
									height: formData.viewType.startsWith('grid')
										? '100px'
										: '150px',
									objectFit: 'contain',
									margin: formData.viewType.startsWith('grid') ? '5px' : '10px',
									display: [
										'grid1',
										'grid2',
										'grid3',
										'grid4',
										'grid5',
									].includes(formData.viewType)
										? 'inline-block'
										: 'block',
								}}
							/>
						))}
					</div>
				)}
			</section>

			{/* Contact Info */}
			<section style={{ marginBottom: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Contact Info</h3>
				{formData.telephone && (
					<p>
						<strong>Telephone:</strong> {formData.telephone}
					</p>
				)}
				{formData.email && (
					<p>
						<strong>Email:</strong> {formData.email}
					</p>
				)}
				{formData.website && (
					<p>
						<strong>Website:</strong>{' '}
						<a
							href={formData.website}
							target="_blank"
							rel="noopener noreferrer"
							style={{ color: buttonColor }}
						>
							{formData.website}
						</a>
					</p>
				)}
			</section>
		</div>
	)
}

export default SocialmediaPreview
