import React, { useCallback, useContext, useRef, useState } from 'react'
import { Col, Row, Input, FormGroup, Label } from 'reactstrap'
import { AuthContxt } from '../../../../store/authContxt'
import titleFetcher from '../../../../utils/titleFetcher'
import { PLAN } from '../../../../utils/enum'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

const WebsiteForm = ({ activeId, formData, setFormData, isEdit }) => {
	const { quota } = useContext(AuthContxt)
	const BASE_URL = process.env.REACT_APP_QR_BASE_URL
	const timeoutRef = useRef(null)
	const [showPreview, setShowPreview] = useState(false) // Added for preview functionality
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	) // Added for AnimationSettings

	const handleTitle = async (url) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current)
		}
		timeoutRef.current = setTimeout(async () => {
			try {
				const title = await titleFetcher(url)
				setFormData((prev) => ({
					...prev,
					title: title?.title || '',
					metaDescription: title?.metaDescription || '',
				}))
			} catch (e) {
				console.log('Error while fetching title: ', e.message)
			}
		}, 500)
	}

	const destinationUrlHandler = async (e) => {
		const url = e.target.value
		handleTitle(url)
		setFormData((prev) => ({ ...prev, destinationUrl: url }))
	}

	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const isPlanStarter = isEdit && quota?.plan === PLAN.STARTER
	const isPlanStandard = quota?.plan === PLAN.STANDARD

	return (
		<div className="px-0 mw-1000px">
			<Row className="">
				<Col sm="12">
					<div className="rounded-1">
						<div className="mb-lg-8 mb-5">
							<h3 className="fs-2">Enter Your Destination URL</h3>
						</div>
						<div>
							<FormGroup>
								<div>
									<Label className="fw-bold fs-5 mb-2">Destination URL</Label>
									{isPlanStarter ||
									(isPlanStandard && isEdit && quota?.linkEdit === 0) ? (
										<i className="fas ms-2 fa-lock"></i>
									) : null}
								</div>
								<div className="position-relative">
									<Input
										type="destinationUrl"
										disabled={
											isPlanStarter || (isPlanStandard && quota?.linkEdit === 0)
										}
										placeholder="https://www.example.com/enter-long-url"
										value={formData?.destinationUrl}
										onChange={(e) => {
											if (!isPlanStarter) {
												destinationUrlHandler(e)
											}
										}}
										className="w-100 h-50px"
									/>
									{formData?.destinationUrl && (
										<i
											className={`fa ${
												!formData?.title ? 'fa-times' : 'fa-check'
											}`}
											style={{
												position: 'absolute',
												right: '10px',
												top: '50%',
												transform: 'translateY(-50%)',
												fontSize: '16px',
												color: !formData?.title ? 'red' : 'green',
											}}
										></i>
									)}
								</div>
								<p className="text-gray-400 mt-2">
									{'Example ( https://www.example.com/enter-long-url )'}
								</p>
								{isPlanStandard ? (
									<div className="d-flex justify-content-end mb-3 link-info">
										{`Remaining edit limit for destination url is ${quota?.linkEdit}`}
									</div>
								) : null}
							</FormGroup>
						</div>
					</div>

					{/* Welcome Screen Section */}
					<div className="rounded-1 mt-5">
						<div className="mb-lg-8 mb-5">
							<h3 className="fs-2">Welcome Screen</h3>
						</div>
						<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
							<div className="mb-5">
								<div className="mb-3">
									<Label>Image</Label>
									<div
										style={{
											width: '100px',
											height: '100px',
											border: '1px dashed #ccc',
											borderRadius: '5px',
											position: 'relative',
											overflow: 'hidden',
										}}
									>
										<div
											style={{
												position: 'absolute',
												top: '50%',
												left: '50%',
												transform: 'translate(-50%, -50%)',
												fontSize: '20px',
												color: '#999',
												pointerEvents: 'none',
												display: formData?.contentWelcomeImage
													? 'none'
													: 'block',
											}}
										>
											+
										</div>
										<img
											src={
												formData?.contentWelcomeImage ||
												'/path/to/default-logo.png'
											}
											alt="Welcome Image Preview"
											style={{
												width: '100%',
												height: '100%',
												objectFit: 'cover',
												display: formData?.contentWelcomeImage
													? 'block'
													: 'none',
											}}
										/>
										<Input
											type="file"
											style={{
												opacity: '0',
												width: '100%',
												height: '100%',
												cursor: 'pointer',
												position: 'absolute',
												top: 0,
												left: 0,
											}}
											onChange={handleWelcomeImageChange}
										/>
									</div>
								</div>
								<AnimationSettings
									formData={formData}
									setFormData={setFormData}
									setShowPreview={setShowPreview}
									setAnimationDirection={setAnimationDirection}
									setContentWelcomeTime={setContentWelcomeTime}
									animationDirection={animationDirection}
									contentWelcomeTime={contentWelcomeTime}
								/>
								<button
									className="btn btn-primary mt-3"
									onClick={() => {
										setShowPreview(false)
										setTimeout(() => {
											setShowPreview(true)
											setTimeout(
												() => {
													setShowPreview(false)
												},
												(formData?.contentWelcomeTime || 3) * 1000
											)
										}, 50)
									}}
								>
									Preview Animation
								</button>
							</div>
						</div>
					</div>

					{/* Password Section */}
					<div className="rounded-1 mt-5">
						<div className="mb-lg-8 mb-5">
							<h3 className="fs-2">Password Protection</h3>
						</div>
						<PasswordSection setFormData={setFormData} />
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default WebsiteForm
