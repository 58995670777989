import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const FeedbackPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	// Helper function to get a URL for file objects or return string URLs directly
	const getImageURL = (fileOrUrl) => {
		if (!fileOrUrl) return null
		return typeof fileOrUrl === 'string'
			? fileOrUrl
			: URL.createObjectURL(fileOrUrl)
	}

	// Styling
	const backgroundColor = formData.primaryColor || '#fff'
	const textColor = '#000' // or use formData.ctaColor if you want text to match CTA color
	const buttonColor = formData.ctaColor || '#348ce5'

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div
			style={{
				backgroundColor: backgroundColor,
				color: textColor,
				padding: '20px',
				minHeight: '100vh',
			}}
		>
			{/* Header: Name */}
			<header style={{ marginBottom: '20px' }}>
				<h1 style={{ margin: 0 }}>{formData.name || 'Your Feedback Form'}</h1>
				{formData.title && (
					<p style={{ margin: 0, fontStyle: 'italic' }}>{formData.title}</p>
				)}
			</header>

			{/* Description */}
			<section style={{ marginBottom: '20px' }}>
				<p>{formData.description || 'Your description goes here...'}</p>
			</section>

			{/* Assessment Section */}
			<section style={{ marginBottom: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Assessment</h3>
				<p>
					{formData.assessment === 'rating'
						? 'Please provide a rating.'
						: 'Please answer Yes or No.'}
				</p>
				{formData.enableComments && (
					<div style={{ marginTop: '10px' }}>
						<label>Comments:</label>
						<textarea
							placeholder="Add your comments here..."
							style={{ width: '100%', minHeight: '100px', marginTop: '5px' }}
							disabled
						/>
					</div>
				)}
			</section>

			{/* Categories and Subcategories */}
			<section style={{ marginBottom: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Categories</h3>
				{formData.categories &&
					formData.categories.map((category, index) => (
						<div key={index} style={{ marginBottom: '15px' }}>
							<h4 style={{ margin: '0 0 5px 0' }}>
								{category.icon && (
									<span style={{ marginRight: '10px' }}>{category.icon}</span>
								)}
								{category.name || `Category ${index + 1}`}
							</h4>
							<p style={{ margin: '0 0 10px 0' }}>
								{category.description ||
									'Please select a subcategory to evaluate it.'}
							</p>
							{category.subcategories && category.subcategories.length > 0 && (
								<ul style={{ paddingLeft: '20px' }}>
									{category.subcategories.map((subcategory, subIndex) => (
										<li key={subIndex}>
											{subcategory.name || `Subcategory ${subIndex + 1}`}
										</li>
									))}
								</ul>
							)}
						</div>
					))}
			</section>

			{/* Contact Information */}
			<section style={{ marginBottom: '20px' }}>
				<h3 style={{ marginBottom: '10px' }}>Contact Information</h3>
				{formData.enableEmailReviews && (
					<div>
						<p>
							<strong>Email:</strong>{' '}
							{formData.reviewEmail || 'E.g. name@email.com'}
						</p>
					</div>
				)}
				{formData.website && (
					<p>
						<strong>Website:</strong>{' '}
						<a
							href={formData.website}
							target="_blank"
							rel="noopener noreferrer"
							style={{ color: buttonColor }}
						>
							{formData.website}
						</a>
					</p>
				)}
			</section>

			{/* Submission Form Note */}
			<section>
				<p style={{ fontSize: '12px', color: '#666' }}>
					{formData.mandatoryEmail && 'Email is mandatory. '}
					{formData.mandatoryPhone && 'Phone is mandatory. '}
					Please fill out the required fields to submit feedback.
				</p>
			</section>
		</div>
	)
}

export default FeedbackPreview
