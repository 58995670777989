import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Row, TabContent } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createQrCodeValidation } from '../../../utils/validations/creatQrCodeValidation'
import CreateQr from './createQr'
import BusinessCardForm from './qr-code-forms/businessCardForm'
import BusinessPage from './qr-code-forms/businessPage'
import PdfForm from './qr-code-forms/PdfForm'
import AudioQr from './qr-code-forms/AudioQr'
import ListoflinksQr from './qr-code-forms/ListoflinksQr'
import LandingPageQr from './qr-code-forms/LandingpageQr'
import ProductQr from './qr-code-forms/ProductQr'
import AppQr from './qr-code-forms/AppQr'
import CouponQr from './qr-code-forms/CouponQr'
import FeedbackQr from './qr-code-forms/FeedbackQr'
import SocialmediaQr from './qr-code-forms/SocialmediaQr'
import EventsQr from './qr-code-forms/EventsQr'
import TextQr from './qr-code-forms/TextQr'
import UrlQr from './qr-code-forms/UrlQr'
import WifiQr from './qr-code-forms/WifiQr'
import SmsQr from './qr-code-forms/SmsQr'
import WhatsappQr from './qr-code-forms/WhatsappQr'
import EmailQr from './qr-code-forms/EmailQr'
import FacebookQr from './qr-code-forms/FacebookQr'
import InstagramQr from './qr-code-forms/InstagramQr'
import MenuQr from './qr-code-forms/MenuQr'
import VideoQr from './qr-code-forms/VideoQr'
import ImageQr from './qr-code-forms/ImagesQr'
import Barcode from './createBarcodeQr'
import CustomizationQr from './customizationQr'
import Tabs from './tabs'
import FinelResultedQr from './finelResultedQr'
import CreateQrHeader from './header'
import QrCodesAPI from '../../../services/http/qrCodes'
import { fetchTitleAndFavicon } from '../../../utils'
import { useNavigate, useLocation } from 'react-router-dom'
//import { AuthContxt } from "../../../store/authContxt";
import convertToURLFriendly from '../../../utils/convertToURLFriendly'

const CreateQRCode = ({ edittedData = null, isEdit = false }) => {
	const auth_token = localStorage.getItem('auth_token')
	//const { quota } = useContext(AuthContxt);
	const navigate = useNavigate()
	const [tab, setTab] = useState(1)
	const location = useLocation()
	const [loading, setLoading] = useState(false)
	const [qrLoading, setQrLoading] = useState(false)
	const [businessCard, setBusinessCard] = useState(null)
	const [message, setMessage] = useState('')
	const messageHandler = (msg) => {
		setMessage(msg)
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const tabsHandler = (newTab) => {
		if (newTab !== tab) setTab(newTab)
	}
	const BASE_URL = process.env.REACT_APP_QR_BASE_URL
	const form = useForm({
		resolver: yupResolver(createQrCodeValidation),
		defaultValues: {
			title: '',
			qr: '',
			qrLogoId: process.env.REACT_APP_QR_LOGO_ID || '',
			destinationUrl: '',
			slashTag: '',
			brandedDomain: BASE_URL,
			preset: '#000000',
			color: '#000000',
			bgColor: '#ffffff',
			isBgTransparent: false,
			pattern: 'classy',
			corner: 'square',
			logo: '',
			edit: 0,
			frame: null,
			primary: '',
			secondary: '',
			textColor: '',
			text: 'Scan Me',
			businessCard: businessCard,
		},
	})

	const { reset, handleSubmit, setError } = form

	const editQrCode = async (data) => {
		QrCodesAPI.updateQrCodeCustomization(auth_token, edittedData?.id, data)
			.then((response) => {
				navigate('/qr-codes')
			})
			.catch((error) => {
				messageHandler(
					error?.response?.data?.data?.message ||
						error?.response?.data?.message ||
						'Something went wrong'
				)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const onError = () => {
		setTab(1)
	}

	const onSubmit = async (data) => {
		if (data) {
			setLoading(true)
			const titleAndIcon = await fetchTitleAndFavicon(data?.destinationUrl)
			let title = data?.title || titleAndIcon?.pageTitle || ''

			if (isEdit) {
				await editQrCode({
					...data,
					frame: data?.frame?.type,
					bgColor: data?.isBgTransparent ? '#ffffff' : data?.bgColor,
					title,
					brandedDomain: edittedData?.brandedDomain,
					slashTag: edittedData?.slashTag,
				})
				return
			}

			let newExpirationDate = data.expirationDate || ''
			if (newExpirationDate === '') {
				const currentDate = new Date()
				const futureDate = new Date(currentDate)
				futureDate.setDate(currentDate.getDate() + 90)
				newExpirationDate = futureDate
			}
			if (data.slashTag === '') {
				setError('slashTag', {
					type: 'custom',
					message: 'Custom slug is required.',
				})
			}

			QrCodesAPI.createQrCode(auth_token, {
				...data,
				frame: data?.frame?.type || '',
				slashTag: convertToURLFriendly(data?.slashTag),
				bgColor: data?.isBgTransparent ? '#ffffff' : data?.bgColor,
				faviconUrl: titleAndIcon?.icon || '',
				title,
				expirationDate: newExpirationDate,
			})
				.then((response) => {
					navigate('/qr-codes')
					reset()
				})
				.catch((error) => {
					messageHandler(
						error?.response?.data?.data?.message ||
							error?.response?.data?.message ||
							'Something went wrong'
					)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		if (isEdit && edittedData) {
			reset({
				edit: edittedData?.edit,
				title: edittedData?.title,
				destinationUrl: edittedData?.destinationUrl,
				slashTag: edittedData?.slashTag,
				brandedDomain: edittedData?.brandedDomain,
				preset: edittedData?.preset,
				color: edittedData?.color,
				bgColor: edittedData?.bgColor,
				pattern: edittedData?.pattern,
				corner: edittedData?.corner,
				logo: edittedData?.logo,
				qr: edittedData?.qr,
				frame: edittedData?.frame,
				primary: edittedData?.primary,
				secondary: edittedData?.secondary,
				textColor: edittedData?.textColor,
				text: edittedData?.text,
				qrLogoId: edittedData?.qrLogoId,
			})
		}
	}, [edittedData, isEdit])

	// useEffect(() => {
	//   quota();
	// }, []);

	const componentMapping = {
		'/create-qr-code-website': (
			<CreateQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-business-page': (
			<BusinessCardForm
				activeId={1}
				form={form}
				isEdit={isEdit}
				businessCard={businessCard}
				setBusinessCard={setBusinessCard}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-vcard': (
			<BusinessPage
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-2dbarcode': (
			<Barcode
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-pdf': (
			<PdfForm
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-video': (
			<VideoQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-socialmedia': (
			<SocialmediaQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-events': (
			<EventsQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-feedback': (
			<FeedbackQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-coupon': (
			<CouponQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-app': (
			<AppQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-audio': (
			<AudioQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-images': (
			<ImageQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-products': (
			<ProductQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-menu': (
			<MenuQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-landingpage': (
			<LandingPageQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-instagram': (
			<InstagramQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-facebook': (
			<FacebookQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-email': (
			<EmailQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-whatsapp': (
			<WhatsappQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-sms': (
			<SmsQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-wifi': (
			<WifiQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-url': (
			<UrlQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-text': (
			<TextQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
		'/create-qr-code-listoflinks': (
			<ListoflinksQr
				activeId={1}
				form={form}
				isEdit={isEdit}
				setQrLoading={setQrLoading}
			/>
		),
	}

	const activeComponent = componentMapping[location.pathname] || (
		<div>No component found for this path</div>
	)

	return (
		<form className="h-100" onSubmit={handleSubmit(onSubmit, onError)}>
			<Card className="bg-white pe-lg-9 ps-lg-10 px-6 rounded-lg-3">
				<CreateQrHeader loading={loading} isEdit={isEdit} />
				<div className="d-flex justify-content-end mb-3">
					{message ? (
						<span className="error-text text-danger">{message}</span>
					) : null}
				</div>
				<div className="">
					<Row>
						<Col md={7} className="p-lg-5 px-3 pt-0 pt-lg-0 col-12">
							<div className="card card-flush mw-1000px border-0 ps-0 pe-lg-8 h-xl-100">
								<Tabs handler={tabsHandler} tab={tab} />
								<TabContent activeTab={tab}>
									{activeComponent}
									<CustomizationQr activeId={2} form={form} isEdit={isEdit} />
								</TabContent>
							</div>
						</Col>
						<FinelResultedQr
							form={form}
							qrLoading={qrLoading}
							tabsHandler={tabsHandler}
						/>
					</Row>
				</div>
			</Card>
		</form>
	)
}

export default CreateQRCode
