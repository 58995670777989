import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../wrapper/pageWrapper'
import CreateQrHeader from './header'
import FinelResultedQr from './finelResultedQr'
import { Card, CardBody, Col, Row, TabContent } from 'react-bootstrap'
import AccordionComponent from './qr-code-forms/businessCardForm'
import QrShower from './qrShower'
import CustomQr from './customQr'
import Tabs from './tabs'
import QrLink from './QrLink'
import QrCodesAPI from '../../../services/http/qrCodes'
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import { useNavigate } from 'react-router-dom'
import Vcard from './qr-code-forms/businessCardForm'
import { generateRandomString } from '../../../utils/generateRandomString'
import BusinessPageQRForm from './qr-code-forms/businessPage'
import BarcodeForm from './qr-code-forms/barcodeForm'
import WebsiteForm from './qr-code-forms/websiteForm'
import { fileUpload } from '../../../services/http/fileUpload'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap'
import PdfForm from './qr-code-forms/PdfForm'
import ImagesQr from './qr-code-forms/ImagesQr'
import AudioQr from './qr-code-forms/AudioQr'
import ListoflinksQr from './qr-code-forms/ListoflinksQr'
import LandingPageQr from './qr-code-forms/LandingpageQr'
import ProductQr from './qr-code-forms/ProductQr'
import AppQr from './qr-code-forms/AppQr'
import CouponQr from './qr-code-forms/CouponQr'
import FeedbackQr from './qr-code-forms/FeedbackQr'
import SocialmediaQr from './qr-code-forms/SocialmediaQr'
import EventsQr from './qr-code-forms/EventsQr'
import TextQr from './qr-code-forms/TextQr'
import UrlQr from './qr-code-forms/UrlQr'
import WifiQr from './qr-code-forms/WifiQr'
import SmsQr from './qr-code-forms/SmsQr'
import WhatsappQr from './qr-code-forms/WhatsappQr'
import EmailQr from './qr-code-forms/EmailQr'
import FacebookQr from './qr-code-forms/FacebookQr'
import InstagramQr from './qr-code-forms/InstagramQr'
import MenuQr from './qr-code-forms/MenuQr'
import VideoQr from './qr-code-forms/VideoQr'
import { AuthContxt } from '../../../store/authContxt'

const QrGeneration = ({ qrType, isEdit = false, editData }) => {
	const [showPreview, setShowPreview] = useState(false)
	const [loading, setLoading] = useState(false)
	const { errors, setErrors } = useContext(AuthContxt)
	const [error, setError] = useState('')
	const [isInvalid, setIsInvalid] = useState(false)
	const [message, setMessage] = useState('')
	const [tab, setTab] = useState(1)
	const [open, setOpen] = useState('0')
	const navigate = useNavigate()
	const BASE_URL = process.env.REACT_APP_QR_BASE_URL

	const tabsHandler = (newTab) => {
		if (newTab !== tab) setTab(newTab)
	}

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		videos: [],
		imageList: [],
		bio: 'doe',
		mobile: '',
		phone: '',
		fax: '',
		email: '',
		qrType: qrType || 'website',
		company: '',
		job: '',
		address: '',
		website: '',
		summary: '',
		businessType: '',
		facebook: '',
		instagram: '',
		google: '',
		linkedin: '',
		welcomeImage: '',
		profilePic: '',
		title: 'Preview',
		qr: '',
		qrLogoId: process.env.REACT_APP_QR_LOGO_ID || '',
		destinationUrl: 'https://divsly.com',
		slashTag: '',
		brandedDomain: BASE_URL,
		preset: '#000000',
		color: '#000000',
		bgColor: '#ffffff',
		isBgTransparent: false,
		pattern: 'classy',
		corner: 'square',
		logo: '',
		edit: 0,
		frame: null,
		gradientColor: '',
		primaryColor: '#f1416c',
		audioPrimary: '#000000',
		primary: '',
		secondary: '',
		textColor: '',
		text: 'Scan Me',
		about: '',
		aboutName: '',
		aboutPhone: '',
		aboutEmail: '',
		aboutWebsite: '',
		mondayOpen: 'monday',
		tuesdayOpen: 'tuesday',
		wednesdayOpen: 'wednesday',
		thursdayOpen: 'thursday',
		fridayOpen: 'friday',
		saturdayOpen: 'saturday',
		sundayOpen: 'sunday',
		mondayStart: '09:00',
		tuesdayStart: '09:00',
		wednesdayStart: '09:00',
		thursdayStart: '09:00',
		fridayStart: '09:00',
		saturdayStart: '09:00',
		sundayStart: '09:00',
		mondayEnd: '20:00',
		tuesdayEnd: '20:00',
		wednesdayEnd: '20:00',
		thursdayEnd: '20:00',
		fridayEnd: '20:00',
		saturdayEnd: '20:00',
		sundayEnd: '20:00',
		ctaColor: '#e9e7e7',
		buttonText: 'View',
		buttonLink: 'https://www.divsly.com',
		audioTitle: '',
		bannerImage: '',
		pdfPassword: '',
		password: '',
		passwordProtectionEnabled: false,
	})

	const editQrCode = async (template) => {
		const auth_token = localStorage.getItem('auth_token')
		QrCodesAPI.updateQrCodeCustomization(auth_token, formData?.id, template)
			.then((response) => {
				navigate('/qr-codes')
			})
			.catch((error) => {
				messageHandler(
					error?.response?.data?.data?.message ||
						error?.response?.data?.message ||
						'Something went wrong'
				)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleSubmit = async () => {
		setLoading(true)

		const auth_token = localStorage.getItem('auth_token')
		let finalTemp = {
			qrType: qrType || '',
		}
		let fileUrl
		if (qrType === 'pdf' && !(formData?.file || formData?.pdf)) {
			// setError({pdf: "Need a PDF file"});
			messageHandler('Need a PDF file')
			setTimeout(() => {
				setLoading(false)
			}, 1000)
			return
		}
		if (qrType === 'business' && !formData?.headline) {
			setErrors({ headline: 'Please add headline' })
			setOpen('2')
			setTimeout(() => {
				setLoading(false)
				setOpen(false)
			}, 1000)
			return
		}

		if (qrType === 'business' && !formData?.title) {
			setErrors({ title: 'Please add title' })
			setTimeout(() => {
				setLoading(false)
			}, 1000)
			return
		}

		if (formData?.file) {
			fileUrl = await fileUpdater(formData.file, auth_token)
		}
		let banner = ''
		if (formData?.profilefile) {
			banner = await fileUpdater(formData.profilefile, auth_token)
		}

		let data
		if (qrType === 'business') {
			data = {
				primaryColor: formData?.primaryColor || '',
				gradientColor: formData?.gradientColor || '',
				company: formData?.company || '',
				headline: formData?.headline || '',
				summary: formData?.summary || '',
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				buttonColor: formData?.buttonColor || '',
				street: formData?.street || '',
				image: fileUrl || formData?.image,
				address: formData?.city || '',
				location: encodeURIComponent(formData?.location) || '',
				postalCode: formData?.postalCode || '',
				country: formData?.country || '',
				city: formData?.city || '',
				title: formData?.title || '',
				about: formData?.about || '',
				aboutName: formData?.aboutName || '',
				aboutPhone: formData?.aboutPhone || '',
				aboutEmail: formData?.aboutEmail || '',
				aboutWebsite: formData?.aboutWebsite || '',
				contentWelcomeImage: formData?.contentWelcomeImage || '',
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5,
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
			;[
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday',
			]?.forEach((e) => {
				data[`${e.toLowerCase()}Open`] = formData?.[`${e.toLowerCase()}Open`]
				data[`${e.toLowerCase()}Start`] = formData?.[`${e.toLowerCase()}Start`]
				data[`${e.toLowerCase()}End`] = formData?.[`${e.toLowerCase()}End`]
			})
		} else if (qrType === 'vcard') {
			data = {
				primaryColor: formData?.primaryColor || '',
				gradientColor: formData?.gradientColor || '',
				firstName: formData?.firstName || '',
				lastName: formData?.lastName || '',
				mobile: formData?.mobile || '',
				phone: formData?.phone || '',
				fax: formData?.fax || '',
				email: formData?.email || '',
				company: formData?.company || '',
				job: formData?.job || '',
				address: formData?.address || '',
				website: formData?.website || '',
				summary: formData?.summary || '',
				businessType: formData?.businessType || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				welcomeImage: formData?.welcomeImage || '', // Optional, kept for backward compatibility
				image: fileUrl || formData?.image || '',
				ctaColor: formData?.ctaColor || '',
				location: encodeURIComponent(formData?.location) || '',
				vcard: createVCardData(),
				contentWelcomeImage: formData?.contentWelcomeImage || '', // Welcome screen image
				animationDirection: formData?.animationDirection || 'bottom-to-top', // Default matches businesscardform.jsx
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5, // Default matches businesscardform.jsx
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'barcode') {
			data = {
				globalTradeItemNumber: formData?.globalTradeItemNumber || '',
				destinationUrl: formData?.destinationUrl || '',
				// Welcome Screen fields
				contentWelcomeImage: formData?.contentWelcomeImage || '',
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5,
				animationDirection: formData?.animationDirection || 'bottom-to-top',
				// Password Protection fields
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				password: formData?.password || '',
			}
		} else if (qrType === 'pdf') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				pdf: fileUrl || formData?.pdf || '',
				// Welcome Screen fields
				contentWelcomeImage: formData?.contentWelcomeImage || '',
				contentWelcomeTime: formData?.contentWelcomeTime || 3,
				// Password field (if applicable)
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'audio') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				audio: fileUrl || formData?.audio || '',
				banner: banner || formData?.banner || '',
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				audioTitle: formData?.audioTitle,
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'listoflinks') {
			data = {
				// Appearance & Colors
				backgroundColor: formData?.backgroundColor || '',
				backgroundLinkColor: formData?.backgroundLinkColor || '',
				linkTextColor: formData?.linkTextColor || '',

				// Font & Typography Settings
				fontTitle: formData?.fontTitle || '',
				fontSeparator: formData?.fontSeparator || '',
				fontTexts: formData?.fontTexts || '',
				titleColor: formData?.titleColor || '',
				separatorColor: formData?.separatorColor || '',
				textsColor: formData?.textsColor || '',
				titleSize: formData?.titleSize || '',
				descriptionSize: formData?.descriptionSize || '',
				separatorSize: formData?.separatorSize || '',
				textAlign: formData?.textAlign || '',
				linksSize: formData?.linksSize || '',
				socialMediaSize: formData?.socialMediaSize || '',

				// Navbar Settings
				navbarColor: formData?.navbarColor || '',

				// Basic Information
				basicLogo: formData?.basicLogo || '', // Logo image (updated key)
				basicTitle: formData?.basicTitle || '',
				basicDescription: formData?.basicDescription || '',

				// Content Sections
				links: formData?.links || [], // Array of { linkImage, linkText, linkUrl }
				separators: formData?.separators || [], // Array of { separatorName, separatorText }
				contentImages: formData?.contentImages || [], // Gallery images (updated key)

				// Social Networks
				contentSocialTitle: formData?.contentSocialTitle || '',
				selectedSocialNetworks: formData?.selectedSocialNetworks || [],

				// Welcome Screen
				contentWelcomeImage: formData?.contentWelcomeImage || '', // Welcome screen image (updated key)
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5,

				// Configuration (Password Protection)
				activatePassword: formData?.activatePassword || false,
				qrPassword: formData?.qrPassword || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'products') {
			data = {
				// ========= APPEARANCE =========
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '', // Updated: button (CTA) color
				navbarColor: formData?.navbarColor || '',
				// If you need fonts or sizes and they are still in state, add them here:
				titleFont: formData?.titleFont || '',
				textFont: formData?.textFont || '',
				titleColor: formData?.titleColor || '',
				textColor: formData?.textColor || '',
				titleSize: formData?.titleSize || '',
				descriptionSize: formData?.descriptionSize || '',

				// ========= BASIC INFORMATION =========
				productImage: formData?.basicInfo?.image || '',
				productTitle: formData?.basicInfo?.title || '',
				productDescription: formData?.basicInfo?.description || '',
				productHeader: formData?.basicInfo?.header || '',
				typeOfWine: formData?.basicInfo?.typeOfWine || '',
				varieties: formData?.basicInfo?.varieties || '',
				add: formData?.basicInfo?.add || '',
				region: formData?.basicInfo?.region || '',
				countryOfOrigin: formData?.basicInfo?.countryOfOrigin || '',
				store: formData?.basicInfo?.store || '',
				geographicalIndication:
					formData?.basicInfo?.geographicalIndication || '',
				alcohol: formData?.basicInfo?.alcohol || '',
				amount: formData?.basicInfo?.amount || '',
				categories: formData?.basicInfo?.categories || [],

				// ========= CONTENT =========
				// 1) Ingredients
				ingredients: formData?.content?.ingredients || [],
				// 2) Nutritional Info
				nutritionalQualification:
					formData?.content?.nutritionalInfo?.qualification || '',
				nutritionalNutrients:
					formData?.content?.nutritionalInfo?.nutrients || [],
				// 3) More Information
				moreInfoAdditionalDetails:
					formData?.content?.moreInformation?.additionalDetails || '',
				moreInfoAllergens: formData?.content?.moreInformation?.allergens || [],
				moreInfoCertificates:
					formData?.content?.moreInformation?.certificates || [],
				moreInfoOrganic: formData?.content?.moreInformation?.organic || [],
				moreInfoRecyclingStamps:
					formData?.content?.moreInformation?.recyclingStamps || [],
				// 4) Welcome Screen
				welcomeImage: formData?.content?.welcomeScreen?.image || '',
				welcomeTime: formData?.content?.welcomeScreen?.time || 2.5,

				// ========= CONFIGURATION =========
				passwordActive: formData?.configuration?.passwordActive || false,
				password: formData?.configuration?.password || '',
			}
		} else if (qrType === 'video') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				audio: fileUrl || '',
				image: formData?.profilePic,
				videos: formData?.videos,
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				pageTitle: formData?.pageTitle,
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'menu') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				audio: fileUrl || '',
				image: formData?.profilePic,
				videos: formData?.videos,
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				pageTitle: formData?.pageTitle,
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'landingpage') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				title: formData?.title || '',
				backgroundImage: formData?.backgroundImage || '',
				description: formData?.description || '',
				// Welcome screen fields (from the Content component)
				contentWelcomeImage: formData?.contentWelcomeImage || '',
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5,
				// Additional fields from QrLink or other components (if applicable)
				company: formData?.company || '',
				website: formData?.website || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				// If you have an audio file URL from another handler, you can include it
				audio: fileUrl || '',
				// If your profile picture is stored in "profilePic", otherwise you can use "backgroundImage"
				image: formData?.profilePic || '',
				videos: formData?.videos || '',
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				pageTitle: formData?.pageTitle || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		}
		if (qrType === 'website') {
			data = {
				// Existing fields
				destinationUrl: formData?.destinationUrl || '',
				title: formData?.title || '',
				metaDescription: formData?.metaDescription || '',
				brandedDomain: formData?.brandedDomain || '',

				// Welcome Screen fields
				contentWelcomeImage: formData?.contentWelcomeImage || '',
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5,
				// animationDirection: formData?.animationDirection || 'bottom-to-top',

				// Password Protection fields
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'sms') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				phoneNumber: formData?.phoneNumber || '',
				message: formData?.message || '',
				// Optional: Configuration (Password Protection)
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				password: formData?.password || '',
			}
		} else if (qrType === 'instagram') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				username: formData?.username || '',
				// Optional: Configuration (Password Protection)
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				password: formData?.password || '',
			}
		} else if (qrType === 'email') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				email: formData?.email || '',
				subject: formData?.subject || '',
				message: formData?.message || '',
				// Optional: Configuration (Password Protection)
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				password: formData?.password || '',
			}
		} else if (qrType === 'facebook') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				facebookUrl: formData?.facebookUrl || '',
				hideLikes: formData?.hideLikes || false,
				name: formData?.name || '',
				title: formData?.title || '',
				website: formData?.website || '',
				// Optional: Configuration (Password Protection)
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				password: formData?.password || '',
			}
		} else if (qrType === 'whatsapp') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				number: formData?.number || '',
				message: formData?.message || '',
				// Optional: Configuration (Password Protection)
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				password: formData?.password || '',
			}
		} else if (qrType === 'wifi') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				networkName: formData?.networkName || '',
				networkPassword: formData?.networkPassword || '',
				encryptionType: formData?.encryptionType || 'WEP',
				hiddenNetwork: formData?.hiddenNetwork || false,
				// Optional: Configuration (Password Protection)
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				password: formData?.password || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'feedback') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				name: formData?.name || '',
				title: formData?.title || '',
				description: formData?.description || '',
				assessment: formData?.assessment || 'rating', // Default to 'rating'
				enableComments: formData?.enableComments || false,
				categories: formData?.categories || [],
				// Content fields for submission settings
				mandatoryEmail: formData?.mandatoryEmail || false,
				mandatoryPhone: formData?.mandatoryPhone || false,
				enableEmailReviews: formData?.enableEmailReviews || false,
				reviewEmail: formData?.reviewEmail || '',
				website: formData?.website || '',
			}
		} else if (qrType === 'coupon') {
			data = {
				// Design Customization fields
				primaryColor: formData?.primaryColor || '#ffffff',
				ctaColor: formData?.ctaColor || '#348ce5',

				// Basic Information fields
				title: formData?.title || '',
				coverImage: formData?.coverImage || null, // Changed from offerImage to coverImage
				description: formData?.description || '',
				size: formData?.size || 100, // Size percentage from the slider
				backgroundColor: formData?.backgroundColor || '#FFFFFF', // Custom background color
				company: formData?.company || '',
				salesBadge: formData?.salesBadge || '',
				buttonText: formData?.buttonText || '',

				// Welcome screen fields (from the Content component)
				contentWelcomeImage: formData?.contentWelcomeImage || null,
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5,

				// Coupon Information fields
				useBarcode: formData?.useBarcode || false,
				couponCode: formData?.couponCode || '',
				validUntil: formData?.validUntil || '',
				termsAndConditions: formData?.termsAndConditions || '',
				barcodeImage: formData?.barcodeImage || null,
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
				// Remove buttonLink since it's not in formData
				// buttonLink: formData?.buttonLink || '', // This field does not exist in formData
			}
		} else if (qrType === 'app') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				appName: formData?.appName || '',
				developerCompany: formData?.developerCompany || '',
				appLogo: formData?.appLogo || '',
				description: formData?.description || '',
				website: formData?.website || '',
				// Download links for different app stores
				googlePlayLink: formData?.googlePlayLink || '',
				appleStoreLink: formData?.appleStoreLink || '',
				amazonLink: formData?.amazonLink || '',
				// Content fields for customizing button texts
				googlePlayText: formData?.googlePlayText || '',
				appleStoreText: formData?.appleStoreText || '',
				amazonText: formData?.amazonText || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'product') {
			data = {
				// Appearance Settings
				backgroundColor: formData?.backgroundColor || '',
				backgroundLinkColor: formData?.backgroundLinkColor || '',
				linkTextColor: formData?.linkTextColor || '',
				fontTitle: formData?.fontTitle || 'Lato',
				fontSeparator: formData?.fontSeparator || 'Lato',
				fontTexts: formData?.fontTexts || 'Lato',
				titleColor: formData?.titleColor || '',
				separatorColor: formData?.separatorColor || '',
				textsColor: formData?.textsColor || '',
				titleSize: formData?.titleSize || '',
				descriptionSize: formData?.descriptionSize || '',
				separatorSize: formData?.separatorSize || '',
				textAlign: formData?.textAlign || 'Left',
				linksSize: formData?.linksSize || '',
				socialMediaSize: formData?.socialMediaSize || '',
				navbarColor: formData?.navbarColor || '',
				// Basic Information Fields
				basicLogo: formData?.basicLogo || '',
				basicTitle: formData?.basicTitle || '',
				basicDescription: formData?.basicDescription || '',
				// Content Fields
				links: formData?.links || [],
				separators: formData?.separators || [],
				contentImages: formData?.contentImages || [],
				contentSocialTitle: formData?.contentSocialTitle || '',
				selectedSocialNetworks: formData?.selectedSocialNetworks || [],
				contentWelcomeImage: formData?.contentWelcomeImage || '',
				contentWelcomeTime: formData?.contentWelcomeTime || 2.5,
				// Configuration
				activatePassword: formData?.activatePassword || false,
				qrPassword: formData?.qrPassword || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'socialmedia') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				logo: formData?.logo || null,
				title: formData?.title || '',
				description: formData?.description || '',
				// Content (Social Networks)
				socialNetworks: formData?.socialNetworks || [],
				// Location
				locationType: formData?.locationType || 'complete',
				location: formData?.location || { search: '' },
				streetNumberFirst: formData?.streetNumberFirst || false,
				// Images
				images: formData?.images || [],
				viewType: formData?.viewType || 'horizontal',
				// Contact Info
				telephone: formData?.telephone || '',
				email: formData?.email || '',
				website: formData?.website || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'events') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				coverImage: formData?.coverImage || null,
				title: formData?.title || '',
				description: formData?.description || '',
				button: formData?.button || null,
				organizerInfo: formData?.organizerInfo || {
					firstName: '',
					website: '',
					phones: [],
					emails: [],
				},
				eventDate: formData?.eventDate || {
					since: '',
					until: '',
					timezone: '',
					timeFormat: '24 hrs',
					allDay: false,
				},
				locationType: formData?.locationType || 'complete',
				location: formData?.location || {
					search: '',
					street: '',
					number: '',
					postalCode: '',
					city: '',
					stateProvince: '',
					country: '',
				},
				streetNumberFirst: formData?.streetNumberFirst || false,
				facilities: formData?.facilities || [],
				aboutUs: formData?.aboutUs || '',
				customButtons: formData?.customButtons || {
					addToCalendar: false,
					shareButton: false,
				},
				welcomeMessage: formData?.welcomeMessage || '',
				activatePassword: formData?.activatePassword || false,
				qrPassword: formData?.qrPassword || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'text') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				message: formData?.message || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'url') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// Basic Information fields
				websiteUrl: formData?.websiteUrl || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		} else if (qrType === 'images') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				imageList: formData?.imageList,
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				password: formData?.password || '',
				passwordProtectionEnabled: formData?.passwordProtectionEnabled || false,
			}
		}
		finalTemp = {
			...finalTemp,
			...formData,
			fieldData: JSON.stringify(data),
		}

		if (qrType !== 'website' && qrType !== 'barcode') {
			finalTemp.destinationUrl = ''
		}
		if (isEdit) {
			await editQrCode({
				...finalTemp,
				frame: finalTemp?.frame?.type,
				bgColor: finalTemp?.isBgTransparent ? '#ffffff' : finalTemp?.bgColor,
				brandedDomain: finalTemp?.brandedDomain,
				slashTag: finalTemp?.slashTag,
			})
			return
		}
		let newExpirationDate = finalTemp.expirationDate || ''
		if (newExpirationDate === '') {
			const currentDate = new Date()
			const futureDate = new Date(currentDate)
			futureDate.setDate(currentDate.getDate() + 90)
			newExpirationDate = futureDate
		}
		if (finalTemp.slashTag === '') {
			setError('slashTag', {
				type: 'custom',
				message: 'Custom slug is required.',
			})
		}
		QrCodesAPI.createQrCode(auth_token, {
			...formData,
			...finalTemp,
			frame: finalTemp?.frame?.type || '',
			slashTag: convertToURLFriendly(formData?.slashTag),
			bgColor: finalTemp?.isBgTransparent ? '#ffffff' : finalTemp?.bgColor,
			expirationDate: newExpirationDate,
		})
			.then((response) => {
				navigate('/qr-codes')
			})
			.catch((error) => {
				messageHandler(
					error?.response?.data?.data?.message ||
						error?.response?.data?.message ||
						'Something went wrong'
				)
			})
			.finally(() => {
				setLoading(false)
				setOpen('0')
			})
	}

	// Function to create vCard data from formData
	const createVCardData = () => {
		const vCardData = `
        BEGIN:VCARD
        VERSION:3.0
        FN:${formData?.firstName || ''} ${formData?.lastName || ''}
        N:${formData?.lastName || ''};${formData?.firstName || ''};;
        EMAIL:${formData?.email || ''}
        TEL;TYPE=CELL:${formData?.mobile || ''}
        TEL;TYPE=WORK,VOICE:${formData?.phone || ''}
        ADR;TYPE=WORK:;;${formData?.address || ''}
        ORG:${formData?.company || ''}
        TITLE:${formData?.job || ''}
        URL:${formData?.website || ''}
        NOTE:${formData?.summary || ''}
        X-SOCIALPROFILE;type=facebook:${formData?.facebook || ''}
        X-SOCIALPROFILE;type=instagram:${formData?.instagram || ''}
        X-SOCIALPROFILE;type=google:${formData?.google || ''}
        X-SOCIALPROFILE;type=linkedin:${formData?.linkedin || ''}
        END:VCARD
      `.trim() // Trim to remove excess spaces or new lines

		return vCardData
	}

	const messageHandler = (msg) => {
		setMessage(msg)
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}
	const fileUpdater = async (file, auth_token) => {
		let fData = new FormData()
		fData.append('file', file)

		try {
			const resp = await fileUpload('/qr-code/file-upload', fData, auth_token)
			console.log(resp)
			return resp.data.url
		} catch (error) {
			console.error('Error uploading file:', error)
		}
	}
	const multiFileUpdater = async (files, auth_token) => {
		const currentUploadedFileSize = Array.from(files)?.reduce(
			(acc, currentFile) => acc + currentFile?.size,
			0
		)
		if (currentUploadedFileSize / (1024 * 1024) > 20) {
			//Convert Bytes to MB
			// console.warn(currentUploadedFileSize,"The uploaded file size cannot exceed 20MB")
			return { error: { message: 'The uploaded file size cannot exceed 20MB' } }
		}
		let fData = new FormData()
		Array.from(files)?.forEach((file, index) => {
			fData?.append('files', file)
			// let count =0
			// setUploadPercentage&& setInterval(() => {
			//   setUploadPercentage((prev)=>(prev+ index* files.length))
			// }, 1000);
		})
		console.log('fData: ', fData)
		try {
			const resp = await fileUpload('/qr-code/files-upload', fData, auth_token)
			console.log('multiFile api res: ', resp)
			return resp.data.files || []
		} catch (error) {
			console.error('Error uploading files:', error)
		}
	}

	useEffect(() => {
		if (isEdit && editData?.qrType) {
			if (editData?.qrType !== 'website') {
				const fieldData = JSON.parse(editData?.fieldData || '') || {}
				setFormData((prev) => ({
					...prev,
					...editData,
					...fieldData,
					location: decodeURIComponent(fieldData?.location),
				}))
			} else {
				setFormData((prev) => ({ ...prev, ...editData }))
			}
		}
	}, [editData])
	useEffect(() => {
		const tag = Math.random().toString(36).substr(2, 5)
		setFormData((prev) => ({ ...prev, slashTag: tag }))
	}, [])
	return (
		<PageWrapper>
			<div className="h-100">
				<Card className="bg-white border-0 rounded-lg-3">
					<CreateQrHeader
						loader={loading}
						isEdit={isEdit}
						handleSubmit={handleSubmit}
						isInvalid={isInvalid}
					/>
					<div className="d-flex justify-content-end">
						{message ? (
							<p className="error-text text-danger mb-3">{message}</p>
						) : null}
					</div>
					<CardBody className="card-body px-6 px-lg-8 pt-0 overflow-x-auto">
						<Row>
							<Col md={7} className="p-lg-5 pe-lg-2 px-3 pt-0 pt-lg-0 col-12">
								<div className="card card-flush mw-1000px border-0 ps-0 pe-lg-8 h-xl-100">
									<Tabs handler={tabsHandler} tab={tab} />
									{tab === 1 ? (
										<div>
											{(qrType === 'website' || qrType === 'barcode') && (
												<div style={{ marginTop: '18px' }}>
													<QrLink
														formData={formData}
														setFormData={setFormData}
														isEdit={isEdit}
														qrType={qrType}
													/>
												</div>
											)}
											{qrType === 'business' ? (
												<BusinessPageQRForm
													formData={formData}
													tab={open}
													isEdit={isEdit}
													qrType={qrType}
													setFormData={setFormData}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'barcode' ? (
												<BarcodeForm
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
													setIsInvalid={setIsInvalid}
												/>
											) : qrType === 'website' ? (
												<WebsiteForm
													formData={formData}
													isEdit={isEdit}
													setFormData={setFormData}
												/>
											) : qrType === 'pdf' ? (
												<PdfForm
													formData={formData}
													qrType={qrType}
													setFormData={setFormData}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'images' ? (
												<ImagesQr
													fileUpdater={multiFileUpdater}
													formData={formData}
													setFormData={setFormData}
													setShowPreview={setShowPreview}
													setIsInvalid={setIsInvalid}
												/>
											) : qrType === 'audio' ? (
												<AudioQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'video' ? (
												<VideoQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
													setIsInvalid={setIsInvalid}
												/>
											) : qrType === 'landingpage' ? (
												<LandingPageQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'instagram' ? (
												<InstagramQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'facebook' ? (
												<FacebookQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'whatsapp' ? (
												<WhatsappQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'sms' ? (
												<SmsQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'wifi' ? (
												<WifiQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'text' ? (
												<TextQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'url' ? (
												<UrlQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'socialmedia' ? (
												<SocialmediaQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'events' ? (
												<EventsQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'feedback' ? (
												<FeedbackQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'coupon' ? (
												<CouponQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'app' ? (
												<AppQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'email' ? (
												<EmailQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'listoflinks' ? (
												<ListoflinksQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
													setShowPreview={setShowPreview}
												/>
											) : qrType === 'products' ? (
												<ProductQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
												/>
											) : qrType === 'menu' ? (
												<MenuQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
												/>
											) : (
												qrType === 'vcard' && (
													<AccordionComponent
														formData={formData}
														isEdit={isEdit}
														qrType={qrType}
														setFormData={setFormData}
														setIsInvalid={setIsInvalid}
														setShowPreview={setShowPreview}
													/>
												)
											)}
										</div>
									) : (
										<CustomQr formData={formData} setFormData={setFormData} />
									)}
								</div>
							</Col>
							<QrShower
								qrType={qrType}
								formData={formData}
								tabsHandler={tabsHandler}
								setFormData={setFormData}
								setShowPreview={setShowPreview}
								showPreview={showPreview}
							/>
						</Row>
					</CardBody>
				</Card>
			</div>
		</PageWrapper>
	)
}

export default QrGeneration
