// @ts-nocheck
import React, { useState, useEffect } from 'react'
import {
	Button,
	Table,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	Container,
	Row,
	Col,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	ModalFooter,
} from 'reactstrap'
import OAuthAppRegistration from './oAuthAppRegistration'

const APISettings = () => {
	const [showCanvas, setShowCanvas] = useState(false)
	const [showTokenModal, setShowTokenModal] = useState(false)
	const [showRegistrationModal, setShowRegistrationModal] = useState(false)
	const [registeredApps, setRegisteredApps] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [revealedTokens, setRevealedTokens] = useState({})
	const [isGeneratingToken, setIsGeneratingToken] = useState(false)
	const [isDeletingApp, setIsDeletingApp] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [appToDelete, setAppToDelete] = useState(null)
	const [deleteConfirmation, setDeleteConfirmation] = useState('')

	const toggleCanvas = () => setShowCanvas(!showCanvas)
	const toggleTokenModal = () => setShowTokenModal(!showTokenModal)

	const toggleTokenVisibility = (appId) => {
		setRevealedTokens((prev) => ({
			...prev,
			[appId]: !prev[appId],
		}))
	}

	// Fetch registered apps
	const fetchRegisteredApps = async () => {
		try {
			const token = localStorage?.getItem('auth_token')
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/app-registrations`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (!response.ok)
				throw new Error(`HTTP error! Status: ${response.status}`)
			const data = await response.json()
			setRegisteredApps(data?.registrations || [])
		} catch (error) {
			console.error('Error fetching apps:', error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchRegisteredApps()
	}, [])

	const initiateDelete = (app) => {
		setAppToDelete(app)
		setShowDeleteModal(true)
		setDeleteConfirmation('')
	}

	const deleteApp = async (appId) => {
		setIsDeletingApp(true)
		try {
			const token = localStorage.getItem('auth_token')
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/app-registrations/${appId}`,
				{
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			await fetchRegisteredApps()
			setShowDeleteModal(false)
			setAppToDelete(null)
			setDeleteConfirmation('')
		} catch (error) {
			console.error('Error deleting app:', error)
			alert('Failed to delete application. Please try again.')
		} finally {
			setIsDeletingApp(false)
		}
	}

	const generateToken = async (selectedApps) => {
		if (!selectedApps || selectedApps.length === 0) {
			alert('Please select at least one app')
			return
		}

		setIsGeneratingToken(true)
		try {
			const token = localStorage.getItem('auth_token')
			const promises = selectedApps.map((registrationId) =>
				fetch(`${process.env.REACT_APP_API_URL}/create-token`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						registrationId,
						email: JSON.parse(localStorage.user_details).email,
					}),
				}).then(async (response) => {
					if (!response.ok) {
						const errorData = await response.json().catch(() => ({}))
						throw new Error(
							errorData.message || `HTTP error! Status: ${response.status}`
						)
					}
					return response.json()
				})
			)

			const results = await Promise.all(promises)
			console.log('Generated tokens:', results)
			toggleTokenModal()
			fetchRegisteredApps()
		} catch (error) {
			console.error('Error generating tokens:', error)
			alert('Failed to generate tokens. Please try again.')
		} finally {
			setIsGeneratingToken(false)
		}
	}

	// Function to handle successful registration
	const handleRegistrationSuccess = async () => {
		await fetchRegisteredApps()
	}

	const toggleRegistrationModal = () => {
		setShowRegistrationModal(!showRegistrationModal)
	}

	return (
		<>
			{isDeletingApp && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 9999,
					}}
				>
					<div className="text-center text-white">
						<i className="fas fa-spinner fa-spin fa-3x mb-3"></i>
						<p className="mt-2">Deleting application...</p>
					</div>
				</div>
			)}
			{isGeneratingToken && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 9999,
					}}
				>
					<div className="text-center text-white">
						<i className="fas fa-spinner fa-spin fa-3x mb-3"></i>
						<p className="mt-2">Generating token...</p>
					</div>
				</div>
			)}
			<Container className="py-4">
				<Row>
					<Col>
						<h4>API</h4>

						<div className="mb-3">
							<p>
								For documentation on using the Divsly API visit the{' '}
								<a href="#" target="_blank" rel="noopener noreferrer">
									API Documentation
								</a>
								.
							</p>
							<h5>Access token</h5>
							<p>
								Some integrations from other apps will ask you for an access
								token. You'll also need one when connecting to Divsly through
								our API.
							</p>
							<Button
								color="secondary"
								onClick={toggleTokenModal}
								disabled={!registeredApps.length}
								title={
									!registeredApps.length
										? 'Register an app first to generate tokens'
										: 'Generate new token'
								}
							>
								Generate token
							</Button>
						</div>

						<h5>Registered OAuth applications</h5>
						<p>
							For added security, we'll send an email with a registration link
							to your primary email address.
						</p>

						{isLoading ? (
							<div className="border rounded p-4">
								<div className="text-center">
									<i className="fas fa-spinner fa-spin fa-2x mb-3"></i>
									<p className="mt-2">Loading registered applications...</p>
								</div>
							</div>
						) : registeredApps.length === 0 ? (
							<div className="border rounded p-4">
								<div className="text-center">
									<p>No applications registered yet.</p>
								</div>
							</div>
						) : (
							<Table responsive bordered>
								<thead>
									<tr>
										<th>App</th>
										<th>App link</th>
										<th>Registration ID</th>
										<th>Client ID</th>
										<th>Client secret</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{registeredApps.map((app) => (
										<tr key={app.id}>
											<td>{app?.application?.name}</td>
											<td>{app?.application?.appLink}</td>
											<td>{app?.id}</td>
											<td>{app?.clientId}</td>
											<td>
												<div className="d-flex align-items-center">
													{app?.token?.accessToken ? (
														<>
															<div
																className="d-flex align-items-center flex-grow-1"
																style={{ minWidth: 0 }}
															>
																<div
																	className="text-truncate"
																	style={{ maxWidth: '150px' }}
																>
																	{revealedTokens[app.id]
																		? app.token.accessToken
																		: '••••••••••••••••'}
																</div>
																<Button
																	color="link"
																	className="ms-2 p-0"
																	onClick={() => toggleTokenVisibility(app.id)}
																>
																	{revealedTokens[app.id] ? 'Hide' : 'Show'}
																</Button>
																{revealedTokens[app.id] && (
																	<Button
																		color="link"
																		className="ms-2 p-0"
																		onClick={() => {
																			navigator.clipboard.writeText(
																				app.token.accessToken
																			)
																			alert('Token copied to clipboard!')
																		}}
																		title="Copy to clipboard"
																	>
																		<i className="fas fa-copy" />
																	</Button>
																)}
															</div>
														</>
													) : (
														<Button
															color="link"
															className="p-0"
															onClick={toggleTokenModal}
														>
															Generate Token
														</Button>
													)}
												</div>
											</td>
											<td>
												<Button
													color="danger"
													size="sm"
													onClick={() => initiateDelete(app)}
												>
													Delete
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						)}

						<Button
							color="primary"
							onClick={toggleRegistrationModal}
							className="mb-3"
						>
							Register New Application
						</Button>
					</Col>
				</Row>

				<Offcanvas isOpen={showCanvas} toggle={toggleCanvas} direction="end">
					<OffcanvasHeader toggle={toggleCanvas}>
						Register OAuth Application
					</OffcanvasHeader>
					<OffcanvasBody>
						<OAuthAppRegistration />
					</OffcanvasBody>
				</Offcanvas>
				{/* Add Token Generation Modal */}
				<Modal isOpen={showTokenModal} toggle={toggleTokenModal}>
					<ModalHeader toggle={toggleTokenModal}>
						Generate Access Token
					</ModalHeader>
					<ModalBody>
						<TokenGenerationForm
							apps={registeredApps}
							onSubmit={generateToken}
							onCancel={toggleTokenModal}
						/>
					</ModalBody>
				</Modal>
				{/* Registration Modal */}
				<Modal
					isOpen={showRegistrationModal}
					toggle={toggleRegistrationModal}
					size="lg"
				>
					<ModalHeader toggle={toggleRegistrationModal}>
						Register OAuth Application
					</ModalHeader>
					<ModalBody>
						<OAuthAppRegistration
							onClose={toggleRegistrationModal}
							onSuccess={handleRegistrationSuccess}
						/>
					</ModalBody>
				</Modal>
				{/* Delete Confirmation Modal */}
				<Modal
					isOpen={showDeleteModal}
					toggle={() => setShowDeleteModal(false)}
				>
					<ModalHeader toggle={() => setShowDeleteModal(false)}>
						Delete Application Registration
					</ModalHeader>
					<ModalBody>
						<div className="mb-4">
							<p className="text-danger fw-bold">
								Warning: This action cannot be undone.
							</p>
							<p>You are about to delete the application registration for:</p>
							<p className="fw-bold">{appToDelete?.application?.name}</p>
						</div>
						<FormGroup>
							<Label>
								To confirm, type{' '}
								<strong>{appToDelete?.application?.name}</strong> below:
							</Label>
							<Input
								type="text"
								value={deleteConfirmation}
								onChange={(e) => setDeleteConfirmation(e.target.value)}
								placeholder="Type the application name to confirm"
							/>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button
							color="secondary"
							onClick={() => {
								setShowDeleteModal(false)
								setAppToDelete(null)
								setDeleteConfirmation('')
							}}
						>
							Cancel
						</Button>
						<Button
							color="danger"
							disabled={deleteConfirmation !== appToDelete?.application?.name}
							onClick={() => deleteApp(appToDelete?.id)}
						>
							Delete Application
						</Button>
					</ModalFooter>
				</Modal>
			</Container>
		</>
	)
}

const TokenGenerationForm = ({ apps, onSubmit, onCancel }) => {
	const [selectedApps, setSelectedApps] = useState([])

	return (
		<Form>
			<FormGroup>
				<Label>Select Applications</Label>
				{apps.map((app) => (
					<FormGroup check key={app.id}>
						<Input
							type="checkbox"
							id={`app-${app?.application?.id}`}
							onChange={(e) => {
								setSelectedApps((prev) =>
									e.target.checked
										? [...prev, app.id]
										: prev.filter((id) => id !== app.id)
								)
							}}
						/>
						<Label check for={`app-${app?.application?.id}`}>
							{app?.application?.name}
						</Label>
					</FormGroup>
				))}
			</FormGroup>
			<div className="d-flex justify-content-end gap-2">
				<Button color="secondary" onClick={onCancel}>
					Cancel
				</Button>
				<Button
					color="primary"
					onClick={() => onSubmit(selectedApps)}
					disabled={selectedApps.length === 0}
				>
					Generate Token
				</Button>
			</div>
		</Form>
	)
}

export default APISettings
