import React, { useEffect, useRef, useState } from 'react'
import { isColorDark } from './color-checker/isColorDark'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const ImageGallery = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3
	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	const { imageList } = formData
	const images = [
		'https://loremflickr.com/200/200?random=1',
		'https://loremflickr.com/200/200?random=2',
		'https://loremflickr.com/200/200?random=3',
		'https://loremflickr.com/200/300?random=4',
		'https://loremflickr.com/300/200?random=5',
		'https://loremflickr.com/200/200?random=6',
		'https://loremflickr.com/300/200?random=7',
		'https://loremflickr.com/200/300?random=8',
		'https://loremflickr.com/200/300?random=9',
		'https://loremflickr.com/200/200?random=10',
	]

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}

	return (
		<div className="position-relative onhvr-scroll-y">
			<div
				className="w-100 mx-auto pb-10 scroll-content"
				style={{
					height: '575px',
					maxWidth: ' 900px',
				}}
			>
				<div
					style={{
						background: formData?.primaryColor || 'black',
					}}
					className=" py-10 px-5 pt-15"
				>
					<div className="text-center text-light">
						<h4 className="fw-bold fs-1 text-light">
							{formData?.pageTitle || 'Page Title'}
						</h4>
						<p className="fs-5 mb-0">
							{formData?.description ||
								'We specialize in delivering top-notch solutions tailored to meet your unique needs. Let us help you succeed in your business goals.'}
						</p>

						<a
							target="_blank"
							style={{
								background: formData?.ctaColor,
							}}
							href={formData?.buttonLink}
							referrerPolicy="no-referrer"
							className={`text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'} btn btn-light mt-5 px-10`}
						>
							{' '}
							{formData?.buttonText || 'View'}
						</a>
					</div>
				</div>
				<div className="preview-qr-image-main-container bg-light">
					<div className="preview-qr-image-container">
						{imageList?.length > 0
							? imageList?.map((src, index) => (
									<img
										key={index}
										src={src}
										alt={`Random from LoremFlickr ${index + 1}`}
									/>
								))
							: images?.map((src, index) => (
									<img
										key={index}
										src={src}
										alt={`Random from LoremFlickr ${index + 1}`}
									/>
								))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageGallery
