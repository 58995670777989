import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import socialicon from '../../../../assets/qr-icons/social-media.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import uploadicon from '../../../../assets/qr-icons/upload.png'
import configicon from '../../../../assets/qr-icons/config.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import QrLink from '../QrLink'
import FileUpload from './components/fileUpload'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'
import { domainPatterns } from '../../SocialMediaPatterns'

const PdfForm = ({ formData, setFormData, isEdit, qrType, setShowPreview }) => {
	const [open, setOpen] = useState('0')
	const [errors, setErrors] = useState({})
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)

	const toggle = (id) => {
		open === id ? setOpen('') : setOpen(id)
	}

	// Handle input changes
	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}))
		if (domainPatterns[id] && value && !domainPatterns[id].test(value)) {
			setErrors((prev) => ({
				...prev,
				[id]: `Please enter a valid ${id} URL.`,
			}))
		} else {
			setErrors({ ...errors, [id]: '' })
		}
	}

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center">
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.primaryColor}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.primaryColor}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
							/>
							<Input
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '3',
			icon: uploadicon,
			title: 'Upload PDF',
			content: (
				<FileUpload
					qrType={'pdf'}
					formData={formData}
					setFormData={setFormData}
				/>
			),
		},
		{
			id: '4',
			icon: infoicon,
			title: 'Basic Information',
			content: <></>,
			fields: [
				{
					id: 'company',
					label: 'Company Name',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'title',
					label: 'Title',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'description',
					label: 'Description',
					type: 'textarea',
					colSize: 12,
					className: 'h-100px',
				},
				{
					id: 'website',
					label: 'Website',
					type: 'text',
					colSize: 12,
					className: 'h-50px',
				},
			],
		},
		{
			id: '5',
			icon: socialicon,
			title: 'Social Media',
			fields: [
				{
					id: 'facebook',
					label: 'Facebook URL',
					placeholder: 'https://facebook.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'instagram',
					label: 'Instagram URL',
					placeholder: 'https://instagram.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'google',
					label: 'Google URL',
					placeholder: 'https://google.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'linkedin',
					label: 'LinkedIn URL',
					placeholder: 'https://linkedin.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
			],
		},
		{
			id: '6',
			icon: contenticon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							// setShowPreview={setShowPreview}
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '7',
			title: 'Password',
			icon: configicon,
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
			{accordionItems.map((item) => (
				<AccordionItem key={item.id}>
					<AccordionHeader targetId={item.id}>
						<img src={item.icon} alt={item.title} className="w-30px me-4" />
						{item.title}
					</AccordionHeader>
					<AccordionBody accordionId={item.id}>
						{item.content}
						{item.fields && (
							<Form>
								<div className="row">
									{item.fields.map((field) => (
										<div
											key={field.id}
											className={`col-md-${field.colSize || 12} mb-3`}
										>
											<FormGroup>
												<Label className="fs-5 fw-bold" for={field.id}>
													{field.label}
												</Label>
												<Input
													type={field.type}
													className={field.className}
													maxLength={field.maxLength}
													id={field.id}
													placeholder={field.placeholder}
													value={
														(field.type !== 'file' && formData[field?.id]) || ''
													}
													onChange={handleInputChange}
												/>
											</FormGroup>
											{errors[field.id] && (
												<div className="custom-error">{errors[field.id]}</div>
											)}
										</div>
									))}
								</div>
							</Form>
						)}
					</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

export default PdfForm
