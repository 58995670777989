import React, { useEffect, useRef, useState } from 'react'
import { getAnimationStyle } from '../../../../utils/animationStyles'
import '../../../../styles/animations.css'

const CouponPreview = ({
	formData,
	showPreview: propShowPreview,
	animationDirection: propAnimationDirection,
	contentWelcomeTime: propContentWelcomeTime,
}) => {
	const [showWelcome, setShowWelcome] = useState(false)
	// Use local state if props are not provided
	const [localShowPreview, setLocalShowPreview] = useState(false)
	const [localAnimationDirection, setLocalAnimationDirection] =
		useState('bottom-to-top')
	const [localContentWelcomeTime, setLocalContentWelcomeTime] = useState(3)

	// Use props if provided, otherwise fall back to local state
	const showPreview =
		typeof propShowPreview !== 'undefined' ? propShowPreview : localShowPreview
	const animationDirection = propAnimationDirection || localAnimationDirection
	const contentWelcomeTime =
		propContentWelcomeTime ||
		localContentWelcomeTime ||
		formData?.contentWelcomeTime ||
		3

	useEffect(() => {
		if (showPreview) {
			setShowWelcome(true)
			const timer = setTimeout(
				() => setShowWelcome(false),
				(contentWelcomeTime || 3) * 1000
			)
			return () => clearTimeout(timer)
		} else {
			setShowWelcome(false)
		}
	}, [showPreview, contentWelcomeTime])

	// Helper function to get a URL for file objects or return string URLs directly
	const getImageURL = (fileOrUrl) => {
		if (!fileOrUrl) return null
		return typeof fileOrUrl === 'string'
			? fileOrUrl
			: URL.createObjectURL(fileOrUrl)
	}

	const coverImageURL = getImageURL(formData.coverImage) // Changed from offerImage to coverImage
	// const welcomeImageURL = getImageURL(formData.contentWelcomeImage)
	const barcodeImageURL = getImageURL(formData.barcodeImage)

	// Apply size percentage to the preview container
	const previewSizeStyle = {
		transform: `scale(${formData.size ? formData.size / 100 : 1})`,
		transformOrigin: 'top left',
		width: '100%',
	}

	if (showWelcome && formData?.contentWelcomeImage) {
		return (
			<div
				style={{
					...getAnimationStyle(animationDirection, contentWelcomeTime),
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<img
					src={formData.contentWelcomeImage}
					alt="Welcome"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				/>
			</div>
		)
	}
	return (
		<div
			style={{
				backgroundColor: formData.backgroundColor || '#FFFFFF',
				backgroundImage: coverImageURL ? `url(${coverImageURL})` : 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				padding: '20px',
				minHeight: '100vh',
				color: formData.ctaColor || '#000',
				...previewSizeStyle, // Apply the size scaling
			}}
		>
			{/* Coupon Header */}
			<header>
				<h1>{formData.title || 'Coupon Title'}</h1>
				{formData.company && <p>Company: {formData.company}</p>}
			</header>

			{/* Description rendered as HTML */}
			<section>
				<div
					dangerouslySetInnerHTML={{
						__html:
							formData.description || 'Your coupon description goes here...',
					}}
				/>
			</section>

			{/* Sales Badge */}
			{formData.salesBadge && (
				<section style={{ marginTop: '20px' }}>
					<div
						style={{
							backgroundColor: formData.ctaColor || '#348ce5',
							color: '#fff',
							padding: '5px 10px',
							display: 'inline-block',
							borderRadius: '5px',
						}}
					>
						{formData.salesBadge}
					</div>
				</section>
			)}

			{/* Coupon Code or Barcode */}
			<section style={{ marginTop: '20px' }}>
				{formData.useBarcode && barcodeImageURL ? (
					<div>
						<p>Barcode:</p>
						<img
							src={barcodeImageURL}
							alt="Barcode"
							style={{ maxWidth: '100%', borderRadius: '10px' }}
						/>
					</div>
				) : (
					<p>Coupon Code: {formData.couponCode || 'No Code'}</p>
				)}
			</section>

			{/* Valid Until and Terms */}
			<section style={{ marginTop: '20px' }}>
				<p>Valid Until: {formData.validUntil || 'No Date'}</p>
				<p>Terms: {formData.termsAndConditions || 'No Terms'}</p>
			</section>

			{/* Welcome Screen Section
			<section style={{ marginTop: '20px' }}>
				{welcomeImageURL && (
					<img
						src={welcomeImageURL}
						alt="Welcome Screen"
						style={{ maxWidth: '100%', borderRadius: '10px' }}
					/>
				)}
				<p>
					Welcome screen display time: {formData.contentWelcomeTime || 2.5} sec
				</p>
			</section> */}

			{/* Button to See the Code */}
			{formData.buttonText && (
				<section style={{ marginTop: '20px' }}>
					<a
						href="#" // Removed buttonLink since it's not in formData
						style={{
							backgroundColor: formData.ctaColor || '#348ce5',
							color: '#fff',
							padding: '10px 20px',
							textDecoration: 'none',
							borderRadius: '5px',
							display: 'inline-block',
						}}
					>
						{formData.buttonText}
					</a>
				</section>
			)}
		</div>
	)
}

export default CouponPreview
