import React, { useContext, useEffect, useRef, useState } from 'react'
import {
	Col,
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Spinner,
} from 'reactstrap'
import Frames from './frames-svg'
import QrCodesAPI from '../../../services/http/qrCodes'
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import BusinessPreview from './qr-code-previews/businesspreview'
import Vcard from './qr-code-forms/businessPage'
import BcardPreview from './qr-code-previews/bcardpreview'
import BarcodePreview from './qr-code-previews/barcodepreview'
import { QrCodeContxt } from '../qrCodeContxt'
import { AuthContxt } from '../../../store/authContxt'
import WebPreview from './qr-code-previews/webpreview'
import imageDownloadHandler from '../../../utils/imageDownLoadHandler'
import isValidHttpUrl from '../../../utils/isValidHttpUrl'
import html2canvas from 'html2canvas'
import QrLoader from '../../common/qrLoader'
import PdfQrPreview from './qr-code-previews/pdfpreview'
import VideoPreview from './qr-code-previews/videopreview'
import ImageGallery from './qr-code-previews/imagespreview'
import AudioPreview from './qr-code-previews/audiopreview'
import LandingpagePreview from './qr-code-previews/landingpagepreview'
import Listoflinkspreview from './qr-code-previews/listoflinkspreview'
import Productpreview from './qr-code-previews/productpreview'
import AppPreview from './qr-code-previews/apppreview'
import CouponPreview from './qr-code-previews/couponpreview'
import FeedbackPreview from './qr-code-previews/feedbackpreview'
import SocialmediaPreview from './qr-code-previews/socialmediapreview'
import EventsPreview from './qr-code-previews/eventspreview'
import TextPreview from './qr-code-previews/textpreview'
import UrlPreview from './qr-code-previews/urlpreview'
import WifiPreview from './qr-code-previews/wifipreview'
import SmsPreview from './qr-code-previews/smspreview'
import WhatsappPreview from './qr-code-previews/whatsapppreview'
import EmailPreview from './qr-code-previews/emailpreview'
import FacebookPreview from './qr-code-previews/facebookpreview'
import InstagramPreview from './qr-code-previews/instagrampreview'

const BASE_URL = process.env.REACT_APP_QR_BASE_URL

const Copy = ({ copyData, handler = null, includeProtocol = true }) => {
	const [isCopied, setIsCopied] = useState(false)

	const copyHandler = async () => {
		try {
			setIsCopied(true)
			let data = copyData
			if (handler) {
				data = handler()
			}
			if (!data.includes('https://') && includeProtocol) {
				data = `https://${data}`
			}
			await navigator.clipboard.writeText(data)
			setTimeout(() => {
				setIsCopied(false)
			}, 1000)
		} catch (error) {
			console.error('Unable to copy to clipboard', error)
		}
	}

	return (
		<>
			{isCopied ? (
				<i class="fa-solid fa-check fs-2 ms-5 text-green-800 cursor-pointer"></i>
			) : (
				<i
					className="fa-regular fa-clone fs-2 ms-5 text-gray-700 cursor-pointer"
					title="Copy"
					onClick={copyHandler}
				></i>
			)}
		</>
	)
}

export const options = {
	data: BASE_URL,
	config: {
		erf1: [],
		erf2: ['fh'],
		erf3: ['fv'],
		brf1: [],
		brf2: ['fh'],
		brf3: ['fv'],
		gradientType: 'radial',
		gradientOnEyes: false,
		logo: '',
		eye: 'frame0',
		eyeBall: 'ball0',
		// pattern
		body: 'round',
		// Color
		bodyColor: '#000000',
		// Background color
		bgColor: '#FFFFFF',
	},
	size: 300,
	download: 'imageUrl',
	file: 'png',
}

const patternMapper = {
	classy: 'round',
	rounded: 'square',
	'extra-rounded': 'circle-zebra',
	'classy-rounded': 'circular',
	square: 'mosaic',
	dots: 'circle',
}

const cornerMapper = {
	square: {
		eye: 'frame0',
		eyeBall: 'ball0',
	},
	'extra-rounded': {
		eye: 'frame13',
		eyeBall: 'ball15',
	},
	none: {
		eye: 'frame10',
		eyeBall: 'ball15',
	},
	8: {
		eye: 'frame12',
		eyeBall: 'ball14',
	},
	4: {
		eye: 'frame2',
		eyeBall: 'ball2',
		erf1: ['fh'],
		brf1: ['fh'],
	},
	5: {
		eye: 'frame1',
		eyeBall: 'ball1',
		erf1: ['fh'],
		erf3: ['fh'],
		brf1: ['fh'],
		brf3: ['fh'],
	},
	dot: {
		eye: 'frame1',
		eyeBall: 'ball1',
		erf1: ['fv'],
		erf2: ['fv'],
		brf1: ['fv'],
		brf2: ['fv'],
	},
	9: {
		eye: 'frame0',
		eyeBall: 'ball18',
	},
}
const QrShower = ({
	formData,
	setFormData,
	qrType,
	tabsHandler,
	setShowPreview,
	showPreview,
	animationDirection,
	contentWelcomeTime,
}) => {
	const [isPreview, setIsPreview] = useState(false)
	const [qrImg, setQrImg] = useState(null) // Set default state to null
	const svgRef = useRef(null)
	const { render, setDisable, setLoading, loading, setRender } =
		useContext(AuthContxt)

	const togglePreview = (previewState) => setIsPreview(previewState)

	useEffect(() => {
		setLoading(true)
		const timeoutId = setTimeout(() => {
			generateQrCode()
		}, 300)
		return () => clearTimeout(timeoutId)
	}, [
		formData.slashTag,
		formData.color,
		formData.bgColor,
		formData.corner,
		formData.pattern,
		formData.qrLogoId,
	]) // Add other relevant fields

	const generateQrCode = async () => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			const data = `https://${formData?.brandedDomain}/${convertToURLFriendly(formData?.slashTag)}?r=qr`

			const response = await QrCodesAPI.generateQrCode(auth_token, {
				payload: {
					...options,
					config: {
						...options.config,
						bodyColor: formData?.color,
						bgColor: formData?.isBgTransparent
							? 'transparent'
							: formData?.bgColor,
						eye1Color: formData?.color,
						eye2Color: formData?.color,
						eye3Color: formData?.color,
						eyeBall1Color: formData?.color,
						eyeBall2Color: formData?.color,
						eyeBall3Color: formData?.color,
						body: patternMapper[formData?.pattern],
						...cornerMapper[formData?.corner],
						logo: formData?.qrLogoId || process.env.REACT_APP_QR_LOGO_ID,
					},
					data,
				},
			})

			setQrImg(response.data.data)
			setFormData((prev) => ({ ...prev, qr: response.data.data }))

			setTimeout(() => {
				setLoading(false)
			}, 300)
		} catch (error) {
			console.log('Error while creating QR code: ', error)
			setLoading(false)
		}
	}

	const handleDownload = async (format) => {
		imageDownloadHandler(formData, format)
	}

	useEffect(() => {
		console.log('QrShower - Received formData from CustomQr:', formData)
	}, [formData])

	return (
		<Col
			md={5}
			className="mt-9 d-flex flex-column pe-4 ps-10 position-relative mobile-hide-f"
			style={{ borderLeft: '1px solid rgb(232, 228, 255)' }}
		>
			<div
				className="py-10 rounded-1 position-sticky"
				style={{
					border: '1px solid #e9e6f1',
					top: '30px',
					backgroundColor: '#e8e3f7',
				}}
			>
				<div
					className="d-flex py-3 w-75 mx-auto justify-content-between align-items-center position-sticky mb-5"
					style={{ top: '0px' }}
				>
					<div className="d-flex justify-content-center align-items-center w-100 mb-5">
						<button
							type="button"
							className={`btn ${
								!isPreview
									? 'text-info bg-light-info border-info'
									: 'text-gray-800 border-gray-400 border-end-0'
							} border fs-4 fw-bold rounded-1 rounded-end-0`}
							onClick={() => togglePreview(false)}
						>
							Preview
						</button>
						<button
							type="button"
							className={`btn ${
								isPreview
									? 'text-info bg-light-info border-info'
									: 'text-gray-800 border-gray-400 border-start-0'
							} border fs-4 fw-bold rounded-1 rounded-start-0`}
							onClick={() => togglePreview(true)}
						>
							QR Code
						</button>
					</div>
				</div>
				<div className="w-75 mx-auto">
					{isPreview ? (
						<>
							{
								// loading ? <div className="w-75" style={{height:"100%", display:"grid",placeItems:"center"}}>
								//   <Spinner  />
								// </div> :

								<div
									className="d-flex justify-content-center"
									style={{ position: 'relative' }}
									ref={svgRef}
								>
									{/* <QrCode values={watch()} valueHandler={setValue} /> */}
									{qrImg ? (
										formData?.frame ? (
											<Frames
												type={formData?.frame.type}
												minFontSize={formData?.frame.minFontSize}
												maxFontSize={formData?.frame.maxFontSize}
												width="400"
												height={400}
												url={qrImg}
												primary={formData?.primary}
												textColor={formData?.textColor}
												secondary={formData?.secondary}
												text={formData?.text}
											/>
										) : (
											<img src={qrImg} alt="Qr code" />
										)
									) : (
										<img src="/default-qr.svg" alt="Qr code" />
									)}
								</div>
							}
							<div className="pt-5">
								<p className="fs-4 fw-bold mt-3 mb-8 text-info text-center">
									{`https://${formData?.brandedDomain}/${convertToURLFriendly(
										formData?.slashTag
									)}`}
									<span className="ms-1 cursor-pointer">
										<Copy
											copyData={`https://${
												formData?.brandedDomain
											}/${convertToURLFriendly(formData?.slashTag)}`}
										/>
									</span>
								</p>
							</div>
							<div className="d-flex gap-3 w-75 mx-auto justify-content-center">
								<UncontrolledDropdown
									className="dropdown-header flex-grow-1"
									direction="bottom"
								>
									<DropdownToggle
										className="rounded-1 btn btn-danger w-100 fw-bold"
										style={{
											backgroundColor: '#ff3d00 !important',
											border: '1px solid #ff3d00',
										}}
									>
										<i className="fa-solid fa-download me-3"></i>Download
										<i className="fa-solid fa-chevron-down ms-3"></i>
									</DropdownToggle>
									<DropdownMenu className="w-100">
										<DropdownItem
											className="d-flex justify-content-center align-items-center fw-bold"
											onClick={() => handleDownload('png')}
										>
											<i className="fa-regular fa-file-image me-3 fs-4"></i>{' '}
											Download PNG
										</DropdownItem>
										<DropdownItem
											className="d-flex justify-content-center align-items-center fw-bold"
											onClick={() => handleDownload('jpg')}
										>
											<i className="fa-solid fa-file-image me-3 fs-4"></i>{' '}
											Download JPG
										</DropdownItem>
										<DropdownItem
											className="d-flex justify-content-center align-items-center fw-bold"
											onClick={() => handleDownload('svg')}
										>
											<i class="fa-solid fa-bezier-curve me-3 fs-4"></i>{' '}
											Download SVG
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<Button
									type="button"
									onClick={() => {
										tabsHandler(2)
										// var custcategory = document.getElementById("custcategory");
										// var qrcategory = document.getElementById("qrcategory");
										// qrcategory.classList.remove("activeqrtab");
										// custcategory.classList.add("activeqrtab");
									}}
									className="btn btn-light-info rounded-1 border border-info border-opacity-25 d-flex align-items-center flex-grow-1 bg-transparent fw-bold"
								>
									<i className="fa-regular fa-palette me-3"></i> Customize
								</Button>
							</div>
						</>
					) : (
						<div className="d-flex justify-content-center">
							{/* Placeholder for preview mode content */}
							<div className="mobile-wrapper">
								<div className="volume-button-up"></div>
								<div className="volume-button-down"></div>
								<div className="power-button"></div>
								<div className="mobile-qr overflow-hidden">
									{qrType === 'business' ? (
										<BusinessPreview formData={formData} />
									) : qrType === 'vcard' ? (
										<BcardPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'website' ? (
										<WebPreview formData={formData} />
									) : qrType === 'pdf' ? (
										<PdfQrPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'video' ? (
										<VideoPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'images' ? (
										<ImageGallery
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'audio' ? (
										<AudioPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'landingpage' ? (
										<LandingpagePreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'instagram' ? (
										<InstagramPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'wifi' ? (
										<WifiPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'sms' ? (
										<SmsPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'whatsapp' ? (
										<WhatsappPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'text' ? (
										<TextPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'url' ? (
										<UrlPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'feedback' ? (
										<FeedbackPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'listoflinks' ? (
										<Listoflinkspreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'product' ? (
										<Productpreview formData={formData} />
									) : qrType === 'email' ? (
										<EmailPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'facebook' ? (
										<FacebookPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'app' ? (
										<AppPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'coupon' ? (
										<CouponPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'socialmedia' ? (
										<SocialmediaPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : qrType === 'events' ? (
										<EventsPreview
											formData={formData}
											showPreview={showPreview}
											animationDirection={animationDirection}
											contentWelcomeTime={contentWelcomeTime}
										/>
									) : (
										qrType === 'barcode' && (
											<BarcodePreview
												formData={formData}
												showPreview={showPreview}
												animationDirection={animationDirection}
												contentWelcomeTime={contentWelcomeTime}
											/>
										)
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</Col>
	)
}

export default QrShower
