import React, { useState } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'

const AnimationSettings = ({ setShowPreview }) => {
	const [animationDirection, setAnimationDirection] = useState('bottom-to-top')
	const [contentWelcomeTime, setContentWelcomeTime] = useState(3)

	return (
		<div>
			<FormGroup>
				<Label for="contentWelcomeTime">Time (seconds)</Label>
				<Input
					type="range"
					id="contentWelcomeTime"
					min="0"
					max="10"
					step="0.5"
					value={contentWelcomeTime}
					onChange={(e) => {
						setContentWelcomeTime(parseFloat(e.target.value))
						setShowPreview(false)
					}}
				/>
				<div className="text-muted mt-1">{contentWelcomeTime} seconds</div>
			</FormGroup>

			<FormGroup className="mt-3">
				<Label for="animationDirection">Animation Direction</Label>
				<Input
					type="select"
					id="animationDirection"
					value={animationDirection}
					onChange={(e) => {
						setAnimationDirection(e.target.value)
						setShowPreview(false)
					}}
				>
					<option value="bottom-to-top">Bottom to Top</option>
					<option value="top-to-bottom">Top to Bottom</option>
					<option value="left-to-right">Left to Right</option>
					<option value="right-to-left">Right to Left</option>
					<option value="fade">Fade</option>
				</Input>
			</FormGroup>
		</div>
	)
}

export default AnimationSettings
