import React, { useState, useRef, useEffect } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label, // Fixed typo: 'label' to 'Label'
	Form,
	Button,
} from 'reactstrap'
import { FaEye, FaEyeSlash, FaPlus } from 'react-icons/fa'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import contenticon from '../../../../assets/qr-icons/content.png'
import configicon from '../../../../assets/qr-icons/config.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import QrLink from '../QrLink'
import EventsPreview from '../qr-code-previews/eventspreview'
import RichTextEditor from './components/RichTextEditor'
import PasswordSection from '../qr-code-forms/components/PasswordSection'
import AnimationSettings from '../qr-code-forms/AnimationSettings'

// Basic Information Component
const BasicInformation = ({ formData, setFormData }) => {
	const [openNested, setOpenNested] = useState('')
	const toggleNested = (id) => setOpenNested((prev) => (prev === id ? '' : id))

	const EventInformation = React.memo(({ formData, setFormData }) => {
		// Local state for immediate updates
		const [localState, setLocalState] = useState({
			title: formData.title || '',
			isDirty: false,
		})
		const inputRef = useRef()
		const updateTimeoutRef = useRef(null)
		const lastFocusedElement = useRef(null)

		// Track focus state
		useEffect(() => {
			const handleFocusIn = (e) => {
				lastFocusedElement.current = e.target
			}

			document.addEventListener('focusin', handleFocusIn)
			return () => document.removeEventListener('focusin', handleFocusIn)
		}, [])

		// Auto-restore focus after updates
		useEffect(() => {
			if (lastFocusedElement.current === inputRef.current) {
				inputRef.current?.focus()
			}
		}, [formData])

		const handleLocalChange = (field, value) => {
			setLocalState((prev) => ({
				...prev,
				[field]: value,
				isDirty: true,
			}))

			if (updateTimeoutRef.current) {
				clearTimeout(updateTimeoutRef.current)
			}

			updateTimeoutRef.current = setTimeout(() => {
				setFormData((prev) => ({
					...prev,
					[field]: value,
				}))
				setLocalState((prev) => ({ ...prev, isDirty: false }))

				// Ensure focus is maintained after state update
				requestAnimationFrame(() => {
					if (lastFocusedElement.current === inputRef.current) {
						inputRef.current?.focus()
					}
				})
			}, 1000)
		}

		// Cleanup on unmount
		useEffect(() => {
			return () => {
				if (updateTimeoutRef.current) {
					clearTimeout(updateTimeoutRef.current)
				}
			}
		}, [])

		const handleFileUpload = (e) => {
			if (e.target.files?.[0]) {
				const file = e.target.files[0]
				if (file.type.startsWith('image/') && file.size < 5 * 1024 * 1024) {
					const reader = new FileReader()
					reader.onloadend = () => {
						setFormData((prev) => ({
							...prev,
							coverImage: reader.result,
						}))
					}
					reader.readAsDataURL(file)
				} else {
					alert('Please upload an image file under 5MB.')
				}
			}
		}

		const handleDeleteCoverImage = () => {
			setFormData((prev) => ({ ...prev, coverImage: null }))
		}

		return (
			<div className="w-full max-w-lg">
				<FormGroup className="mb-4">
					<Label className="fw-bold fs-5 form-label">Cover Image</Label>
					<div
						style={{
							width: '100px',
							height: '100px',
							border: '1px dashed #ccc',
							borderRadius: '5px',
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<div
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								fontSize: '20px',
								color: '#999',
								pointerEvents: 'none',
								display: formData?.coverImage ? 'none' : 'block',
							}}
						>
							+
						</div>
						{formData.coverImage && (
							<img
								src={formData.coverImage}
								alt="Cover Image"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									display: 'block',
								}}
							/>
						)}
						<Input
							type="file"
							accept="image/*"
							style={{
								opacity: 0,
								width: '100%',
								height: '100%',
								cursor: 'pointer',
								position: 'absolute',
								top: 0,
								left: 0,
							}}
							onChange={handleFileUpload}
						/>
					</div>
					{formData.coverImage && (
						<Button
							color="primary"
							onClick={handleDeleteCoverImage}
							style={{ marginTop: '10px' }}
						>
							Delete
						</Button>
					)}
				</FormGroup>

				<div className="mt-4">
					<Label className="fw-bold fs-5 form-label">Title</Label>
					<Input
						ref={inputRef}
						className="h-50px form-control"
						type="text"
						placeholder="E.g."
						value={localState.title}
						onChange={(e) => handleLocalChange('title', e.target.value)}
						onFocus={() => {
							lastFocusedElement.current = inputRef.current
						}}
						autoFocus
						// Prevent the input from losing focus
						onBlur={(e) => {
							if (lastFocusedElement.current === inputRef.current) {
								e.preventDefault()
								e.stopPropagation()
								inputRef.current?.focus()
							}
						}}
					/>
				</div>
			</div>
		)
	})

	// Prevent unnecessary re-renders
	EventInformation.displayName = 'EventInformation'

	useEffect(() => {
		// Just to log and monitor state changes
		console.log('Updated formData:', formData)
	}, [formData])

	const EventDateSection = ({ formData, setFormData }) => {
		// Helper function to format date as YYYY-MM-DD
		const formatDate = (date) => {
			const year = date.getFullYear()
			const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
			const day = String(date.getDate()).padStart(2, '0')
			return `${year}-${month}-${day}`
		}

		// Calculate default dates
		const today = new Date()
		const oneWeekFromNow = new Date(today)
		oneWeekFromNow.setDate(today.getDate() - 7) // Add 7 days

		const defaultEventDate = {
			since: formData.eventDate?.since || formatDate(oneWeekFromNow), // Default to 7 days from now
			until: formData.eventDate?.until || formatDate(today), // Default to today
			timezone: formData.eventDate?.timezone || '',
			timeFormat: formData.eventDate?.timeFormat || '24 hrs',
			allDay: formData.eventDate?.allDay || false,
		}

		const [eventDate, setEventDate] = useState(defaultEventDate)

		const handleEventDateChange = (field, value) => {
			const updatedEventDate = { ...eventDate, [field]: value }
			setEventDate(updatedEventDate)
			setFormData((prev) => ({ ...prev, eventDate: updatedEventDate }))
		}

		return (
			<div className="w-full max-w-lg mt-4">
				<div className="d-flex gap-2 mb-3">
					<Button
						color="primary"
						active={eventDate.timeFormat === '24 hrs'}
						onClick={() => handleEventDateChange('timeFormat', '24 hrs')}
					>
						24 hrs
					</Button>
					<Button
						color="secondary"
						active={eventDate.timeFormat === 'am/pm'}
						onClick={() => handleEventDateChange('timeFormat', 'am/pm')}
					>
						am/pm
					</Button>
				</div>
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Since</Label>
					<Input
						className="h-50px form-control"
						type="date"
						value={eventDate.since || ''}
						onChange={(e) => handleEventDateChange('since', e.target.value)}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Until</Label>
					<Input
						className="h-50px form-control"
						type="date"
						value={eventDate.until || ''}
						onChange={(e) => handleEventDateChange('until', e.target.value)}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Timezone</Label>
					<Input
						className="h-50px form-control"
						type="select"
						value={eventDate.timezone || ''}
						onChange={(e) => handleEventDateChange('timezone', e.target.value)}
					>
						<option value="">(GMT-12:00) International Date Line West</option>
					</Input>
				</FormGroup>
				<FormGroup check>
					<Label check>
						<Input
							type="checkbox"
							checked={eventDate.allDay || false}
							onChange={(e) =>
								handleEventDateChange('allDay', e.target.checked)
							}
						/>
						All day
					</Label>
				</FormGroup>
			</div>
		)
	}

	const LocationSection = () => {
		const [showManualEntry, setShowManualEntry] = useState(false)
		const [address, setAddress] = useState(
			formData.location || {
				search: '',
				street: '',
				number: '',
				postalCode: '',
				city: '',
				stateProvince: '',
				country: '',
			}
		)
		const [locationType, setLocationType] = useState(
			formData.locationType || 'complete'
		)
		const handleAddressChange = (field, value) => {
			const updatedAddress = { ...address, [field]: value }
			setAddress(updatedAddress)
			setFormData((prev) => ({ ...prev, location: updatedAddress }))
		}
		const handleDeleteAddress = () => {
			setAddress({
				search: '',
				street: '',
				number: '',
				postalCode: '',
				city: '',
				stateProvince: '',
				country: '',
			})
			setFormData((prev) => ({ ...prev, location: null }))
		}

		return (
			<div className="w-full max-w-lg mt-4">
				<div className="d-flex gap-2 mb-3">
					<Button
						color={locationType === 'complete' ? 'primary' : 'secondary'}
						outline={locationType !== 'complete'}
						onClick={() => {
							setLocationType('complete')
							setFormData((prev) => ({ ...prev, locationType: 'complete' }))
						}}
					>
						Complete
					</Button>
					<Button
						color={locationType === 'url' ? 'primary' : 'secondary'}
						outline={locationType !== 'url'}
						onClick={() => {
							setLocationType('url')
							setFormData((prev) => ({ ...prev, locationType: 'url' }))
						}}
					>
						Url
					</Button>
				</div>
				{locationType === 'url' && (
					<FormGroup>
						<Label className="fw-bold fs-5 form-label">Url</Label>
						<Input
							type="text"
							placeholder="https://..."
							value={address.search || ''}
							onChange={(e) => handleAddressChange('search', e.target.value)}
						/>
					</FormGroup>
				)}
				{locationType === 'complete' && (
					<>
						<FormGroup>
							<Label className="fw-bold fs-5 form-label">Search address</Label>
							<div className="d-flex gap-2">
								<Input
									type="text"
									placeholder="Search address"
									value={address.search || ''}
									onChange={(e) =>
										handleAddressChange('search', e.target.value)
									}
								/>
								<Button
									color="primary"
									onClick={() => setShowManualEntry(true)}
								>
									Manual entry
								</Button>
							</div>
						</FormGroup>
						{showManualEntry && (
							<div>
								<FormGroup check>
									<Label check>
										<Input
											type="checkbox"
											checked={formData.streetNumberFirst || false}
											onChange={(e) =>
												setFormData((prev) => ({
													...prev,
													streetNumberFirst: e.target.checked,
												}))
											}
										/>
										Street number first
									</Label>
								</FormGroup>
								<div className="d-flex gap-2 mb-2">
									<FormGroup className="flex-grow-1">
										<Label className="fw-bold fs-5 form-label">Street</Label>
										<Input
											type="text"
											value={address.street || ''}
											onChange={(e) =>
												handleAddressChange('street', e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup className="flex-grow-1">
										<Label className="fw-bold fs-5 form-label">Number</Label>
										<Input
											type="text"
											value={address.number || ''}
											onChange={(e) =>
												handleAddressChange('number', e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup className="flex-grow-1">
										<Label className="fw-bold fs-5 form-label">
											Postal Code
										</Label>
										<Input
											type="text"
											value={address.postalCode || ''}
											onChange={(e) =>
												handleAddressChange('postalCode', e.target.value)
											}
										/>
									</FormGroup>
								</div>
								<div className="d-flex gap-2 mb-2">
									<FormGroup className="flex-grow-1">
										<Label className="fw-bold fs-5 form-label">City</Label>
										<Input
											type="text"
											value={address.city || ''}
											onChange={(e) =>
												handleAddressChange('city', e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup className="flex-grow-1">
										<Label className="fw-bold fs-5 form-label">
											State / Province
										</Label>
										<Input
											type="text"
											value={address.stateProvince || ''}
											onChange={(e) =>
												handleAddressChange('stateProvince', e.target.value)
											}
										/>
									</FormGroup>
								</div>
								<FormGroup>
									<Label className="fw-bold fs-5 form-label">Country</Label>
									<Input
										type="text"
										value={address.country || ''}
										onChange={(e) =>
											handleAddressChange('country', e.target.value)
										}
									/>
								</FormGroup>
								<Button color="danger" onClick={handleDeleteAddress}>
									Delete address
								</Button>
							</div>
						)}
					</>
				)}
			</div>
		)
	}

	return (
		<Accordion open={openNested} toggle={toggleNested}>
			<AccordionItem>
				<AccordionHeader targetId="event-info">
					Event Information
				</AccordionHeader>
				<AccordionBody accordionId="event-info">
					<EventInformation formData={formData} setFormData={setFormData} />
				</AccordionBody>
			</AccordionItem>
			<AccordionItem>
				<AccordionHeader targetId="event-date">
					Date of the Event
				</AccordionHeader>
				<AccordionBody accordionId="event-date">
					<EventDateSection formData={formData} setFormData={setFormData} />
				</AccordionBody>
			</AccordionItem>
			<AccordionItem>
				<AccordionHeader targetId="location">Location</AccordionHeader>
				<AccordionBody accordionId="location">
					<LocationSection />
				</AccordionBody>
			</AccordionItem>
		</Accordion>
	)
}

// Content Component
const Content = ({ formData, setFormData }) => {
	const [openNested, setOpenNested] = useState('')
	const toggleNested = (id) => setOpenNested((prev) => (prev === id ? '' : id))

	const OrganizerInformation = () => {
		const [organizerInfo, setOrganizerInfo] = useState(
			formData.organizerInfo || {
				firstName: '',
				website: '',
				phones: [],
				emails: [],
			}
		)
		const [phoneError, setPhoneError] = useState(false)
		const [emailError, setEmailError] = useState(false)

		const handleOrganizerChange = (field, value) => {
			const updatedOrganizerInfo = { ...organizerInfo, [field]: value }
			setOrganizerInfo(updatedOrganizerInfo)
			setFormData((prev) => ({ ...prev, organizerInfo: updatedOrganizerInfo }))
		}

		const addPhone = () => {
			setOrganizerInfo((prev) => ({ ...prev, phones: [...prev.phones, ''] }))
			setFormData((prev) => ({
				...prev,
				organizerInfo: {
					...prev.organizerInfo,
					phones: [...prev.organizerInfo.phones, ''],
				},
			}))
		}

		const removePhone = (index) => {
			setOrganizerInfo((prev) => ({
				...prev,
				phones: prev.phones.filter((_, i) => i !== index),
			}))
			setFormData((prev) => ({
				...prev,
				organizerInfo: {
					...prev.organizerInfo,
					phones: prev.organizerInfo.phones.filter((_, i) => i !== index),
				},
			}))
			setPhoneError(false) // Reset error when removing
		}

		const updatePhone = (index, value) => {
			const updatedPhones = [...organizerInfo.phones]
			updatedPhones[index] = value
			setOrganizerInfo((prev) => ({ ...prev, phones: updatedPhones }))
			setFormData((prev) => ({
				...prev,
				organizerInfo: { ...organizerInfo, phones: updatedPhones },
			}))
			setPhoneError(value.trim() === '') // Set error if empty
		}

		const addEmail = () => {
			setOrganizerInfo((prev) => ({ ...prev, emails: [...prev.emails, ''] }))
			setFormData((prev) => ({
				...prev,
				organizerInfo: {
					...prev.organizerInfo,
					emails: [...prev.organizerInfo.emails, ''],
				},
			}))
		}

		const removeEmail = (index) => {
			setOrganizerInfo((prev) => ({
				...prev,
				emails: prev.emails.filter((_, i) => i !== index),
			}))
			setFormData((prev) => ({
				...prev,
				organizerInfo: {
					...prev.organizerInfo,
					emails: prev.organizerInfo.emails.filter((_, i) => i !== index),
				},
			}))
			setEmailError(false) // Reset error when removing
		}

		const updateEmail = (index, value) => {
			const updatedEmails = [...organizerInfo.emails]
			updatedEmails[index] = value
			setOrganizerInfo((prev) => ({ ...prev, emails: updatedEmails }))
			setFormData((prev) => ({
				...prev,
				organizerInfo: { ...organizerInfo, emails: updatedEmails },
			}))
			setEmailError(value.trim() === '') // Set error if empty
		}

		return (
			<div className="w-full max-w-lg">
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">First Name</Label>
					<Input
						type="text"
						placeholder=""
						value={organizerInfo.firstName || ''}
						onChange={(e) => handleOrganizerChange('firstName', e.target.value)}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Website</Label>
					<Input
						type="text"
						placeholder=""
						value={organizerInfo.website || ''}
						onChange={(e) => handleOrganizerChange('website', e.target.value)}
					/>
				</FormGroup>
				{organizerInfo.phones.map((phone, index) => (
					<div key={index} className="d-flex align-items-center gap-2 mb-2">
						<FormGroup className="flex-grow-1">
							<Label className="fw-bold fs-5 form-label">Mobile phone</Label>
							<Input
								type="text"
								placeholder="E.g. 0000000000"
								value={phone || ''}
								onChange={(e) => updatePhone(index, e.target.value)}
								className={phoneError ? 'is-invalid' : ''}
							/>
							{phoneError && <div className="text-danger">Required field</div>}
						</FormGroup>
						<Button color="danger" onClick={() => removePhone(index)}>
							×
						</Button>
					</div>
				))}
				<Button
					color="primary"
					className="rounded-pill d-flex align-items-center gap-2"
					onClick={addPhone}
					style={{
						padding: '6px 12px',
						backgroundColor: '#007bff',
						borderColor: '#007bff',
					}}
				>
					<FaPlus /> Add Phone
				</Button>
				{organizerInfo.emails.map((email, index) => (
					<div
						key={index}
						className="d-flex align-items-center gap-2 mb-2 mt-2"
					>
						<FormGroup className="flex-grow-1">
							<Label className="fw-bold fs-5 form-label">Email</Label>
							<Input
								type="email"
								placeholder="E.g. name@email.com"
								value={email || ''}
								onChange={(e) => updateEmail(index, e.target.value)}
								className={emailError ? 'is-invalid' : ''}
							/>
							{emailError && <div className="text-danger">Required field</div>}
						</FormGroup>
						<Button color="danger" onClick={() => removeEmail(index)}>
							×
						</Button>
					</div>
				))}
				<Button
					color="primary"
					className="rounded-pill d-flex align-items-center gap-2 mt-2"
					onClick={addEmail}
					style={{
						padding: '6px 12px',
						backgroundColor: '#007bff',
						borderColor: '#007bff',
					}}
				>
					<FaPlus /> Add email
				</Button>
			</div>
		)
	}

	const FacilitiesSection = () => {
		const facilitiesOptions = [
			'wifi',
			'lounge',
			'accessibility',
			'toilets',
			'baby',
			'pet',
			'parking',
			'bus',
			'taxi',
			'bed',
			'coffee',
			'drink',
			'music',
		]
		const toggleFacility = (facility) => {
			const facilitiesArray = Array.isArray(formData.facilities)
				? formData.facilities
				: []
			const updatedFacilities = facilitiesArray.includes(facility)
				? facilitiesArray.filter((f) => f !== facility)
				: [...facilitiesArray, facility]
			setFormData((prev) => ({ ...prev, facilities: updatedFacilities }))
		}

		return (
			<div className="w-full max-w-lg mt-4">
				<div className="d-flex flex-wrap gap-2">
					{facilitiesOptions.map((facility) => (
						<Button
							key={facility}
							color={
								(formData.facilities || []).includes(facility)
									? 'primary'
									: 'secondary'
							}
							onClick={() => toggleFacility(facility)}
						>
							{facility.charAt(0).toUpperCase() + facility.slice(1)}
						</Button>
					))}
				</div>
			</div>
		)
	}

	const AboutUsSection = () => {
		const quillRef = useRef(null)

		const handleAboutUsChange = (value) => {
			setFormData((prev) => ({ ...prev, aboutUs: value }))
		}

		return (
			<FormGroup className="mt-4">
				<RichTextEditor
					value={formData?.description || ''}
					onChange={(content) =>
						setFormData((prev) => ({ ...prev, description: content }))
					}
				/>
			</FormGroup>
		)
	}

	const CustomButtonsSection = () => {
		const [customButtons, setCustomButtons] = useState(
			formData.customButtons || { addToCalendar: false, shareButton: false }
		)
		const handleCustomButtonChange = (field, value) => {
			const updatedCustomButtons = { ...customButtons, [field]: value }
			setCustomButtons(updatedCustomButtons)
			setFormData((prev) => ({ ...prev, customButtons: updatedCustomButtons }))
		}

		return (
			<div className="w-full max-w-lg mt-4">
				<FormGroup>
					<Label className="fw-bold fs-5 form-label">Calendar</Label>
					<Input
						type="text"
						placeholder="Add to calendar"
						value={customButtons.addToCalendar ? 'Add to calendar' : ''}
						onChange={(e) =>
							handleCustomButtonChange(
								'addToCalendar',
								e.target.value.length > 0
							)
						}
					/>
				</FormGroup>
				<FormGroup check>
					<Label check>
						<Input
							type="checkbox"
							checked={customButtons.shareButton || false}
							onChange={(e) =>
								handleCustomButtonChange('shareButton', e.target.checked)
							}
						/>
						Share button
					</Label>
				</FormGroup>
			</div>
		)
	}

	return (
		<Accordion open={openNested} toggle={toggleNested}>
			<AccordionItem>
				<AccordionHeader targetId="organizer-info">
					Organizer Information
				</AccordionHeader>
				<AccordionBody accordionId="organizer-info">
					<OrganizerInformation />
				</AccordionBody>
			</AccordionItem>
			<AccordionItem>
				<AccordionHeader targetId="facilities">Facilities</AccordionHeader>
				<AccordionBody accordionId="facilities">
					<FacilitiesSection />
				</AccordionBody>
			</AccordionItem>
			<AccordionItem>
				<AccordionHeader targetId="about-us">About us</AccordionHeader>
				<AccordionBody accordionId="about-us">
					<AboutUsSection />
				</AccordionBody>
			</AccordionItem>
			<AccordionItem>
				<AccordionHeader targetId="custom-buttons">
					Customize your buttons
				</AccordionHeader>
				<AccordionBody accordionId="custom-buttons">
					<CustomButtonsSection />
				</AccordionBody>
			</AccordionItem>
		</Accordion>
	)
}

// Main Form Component
const EventsForm = ({
	formData,
	setFormData,
	isEdit,
	qrType,
	setShowPreview,
}) => {
	const [open, setOpen] = useState('0')
	const toggle = (id) => setOpen(open === id ? '' : id)
	const [animationDirection, setAnimationDirection] = useState(
		formData?.animationDirection || 'bottom-to-top'
	) // Added for AnimationSettings
	const [contentWelcomeTime, setContentWelcomeTime] = useState(
		formData?.contentWelcomeTime || 2.5
	)

	// Handle Welcome Image Change
	const handleWelcomeImageChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onloadend = () => {
				setFormData((prevData) => ({
					...prevData,
					contentWelcomeImage: reader.result,
				}))
				setShowPreview(false)
				setTimeout(() => setShowPreview(true), 50)
			}
			reader.readAsDataURL(file)
		}
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center flex-wrap">
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.primaryColor || '#ffffff'}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}))
								}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-2">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
							/>
							<Input
								type="text"
								maxLength="7"
								value={formData?.ctaColor || '#348ce5'}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '2',
			icon: infoicon,
			title: 'Basic Information',
			content: (
				<BasicInformation formData={formData} setFormData={setFormData} />
			),
		},
		{
			id: '3',
			icon: contenticon,
			title: 'Content',
			content: <Content formData={formData} setFormData={setFormData} />,
		},
		{
			id: '6',
			icon: contenticon,
			title: 'Welcome Screen',
			content: (
				<div className="p-4 border rounded-lg shadow-md w-full max-w-lg">
					<div className="mb-5">
						<div className="mb-3">
							<Label>Image</Label>
							<div
								style={{
									width: '100px',
									height: '100px',
									border: '1px dashed #ccc',
									borderRadius: '5px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										fontSize: '20px',
										color: '#999',
										pointerEvents: 'none',
										display: formData?.contentWelcomeImage ? 'none' : 'block',
									}}
								>
									+
								</div>
								<img
									src={
										formData?.contentWelcomeImage || '/path/to/default-logo.png'
									}
									alt="Welcome Image Preview"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										display: formData?.contentWelcomeImage ? 'block' : 'none',
									}}
								/>
								<Input
									type="file"
									style={{
										opacity: '0',
										width: '100%',
										height: '100%',
										cursor: 'pointer',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									onChange={handleWelcomeImageChange}
								/>
							</div>
						</div>
						<AnimationSettings
							// setShowPreview={setShowPreview}
							formData={formData}
							setFormData={setFormData}
							setShowPreview={setShowPreview}
							setAnimationDirection={setAnimationDirection}
							setContentWelcomeTime={setContentWelcomeTime}
							animationDirection={animationDirection}
							contentWelcomeTime={contentWelcomeTime}
						/>

						<button
							className="btn btn-primary mt-3"
							onClick={() => {
								setShowPreview(false)
								setTimeout(() => {
									setShowPreview(true)
									setTimeout(
										() => setShowPreview(false),
										(formData?.contentWelcomeTime || 3) * 1000
									)
								}, 50)
							}}
						>
							Preview Animation
						</button>
					</div>
				</div>
			),
		},
		{
			id: '4',
			icon: configicon,
			title: 'Password',
			content: <PasswordSection setFormData={setFormData} />,
		},
	]

	return (
		<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
			{accordionItems.map((item) => (
				<AccordionItem key={item.id}>
					<AccordionHeader targetId={item.id}>
						<img src={item.icon} alt={item.title} className="w-30px me-4" />
						{item.title}
					</AccordionHeader>
					<AccordionBody accordionId={item.id}>{item.content}</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

// Wrapper Component
const EventsQr = ({ isEdit = false, qrType = 'events' }) => {
	const [formData, setFormData] = useState({
		primaryColor: '#ffffff',
		ctaColor: '#348ce5',
		coverImage: null,
		title: '',
		description: '',
		button: null,
		eventDate: {
			since: '',
			until: '',
			timezone: '',
			timeFormat: '24 hrs',
			allDay: false,
		},
		locationType: 'complete',
		location: {
			search: '',
			street: '',
			number: '',
			postalCode: '',
			city: '',
			stateProvince: '',
			country: '',
		},
		streetNumberFirst: false,
		organizerInfo: { firstName: '', website: '', phones: [], emails: [] },
		facilities: [],
		aboutUs: '',
		customButtons: { addToCalendar: false, shareButton: false },
		welcomeMessage: '',
		passwordProtectionEnabled: false, // Added for PasswordSection compatibility
		password: '', // Added for PasswordSection compatibility
	})

	return (
		<div style={{ display: 'flex', gap: '20px' }}>
			<div style={{ flex: 1 }}>
				<EventsForm
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			</div>
			<div style={{ flex: 1, border: '1px solid #ccc', padding: '10px' }}>
				<EventsPreview formData={formData} />
			</div>
		</div>
	)
}

export default EventsForm
